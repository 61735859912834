import * as api from '../api'
import { fromJS } from 'immutable'
import { normalizeSettings } from '../normalizers/'

const UPDATE_LEN = 'settings/UPDATE_LEN'
const UPDATE_LEN_OK = 'settings/UPDATE_LEN_OK'
const GET_SETTINGS =  'settings/GET_SETTINGS'
const GET_SETTINGS_OK =  'settings/GET_SETTINGS_OK'
const GET_PLANS = 'plans/GET_PLANS'
const GET_PLANS_OK = 'plans/GET_PLANS_OK'
// const GET_PLANS_ERR = 'plans/GET_PLANS_ERR'

const initialState = fromJS({
    settings: {},
    plans: [],
    lenguage: localStorage.getItem('i18nextLng')
})

export function getPlans() {
    return dispatch => {
        dispatch({ type: GET_PLANS })

        return api.getPlans()
            .then(res => res.data.results)
            .then(data => dispatch({
                type: GET_PLANS_OK,
                plans: [...data]
            }))
    }
}

export function getSettings() {
    return dispatch => {
        dispatch({ type: GET_SETTINGS })

        return api.getSettings()
            .then(res => normalizeSettings(res.data))
            .then(normalized => dispatch({
                type: GET_SETTINGS_OK,
                ...normalized
            }))
    }
}

export function updateSystemLen(len) {
    return dispatch => {
        dispatch({ type: UPDATE_LEN })
        return api.postLanguage(len)
            .then(res => normalizeSettings(res.data))
            .then(normalized =>
                dispatch({
                    type: UPDATE_LEN_OK,
                    len: len.split('-')[0]
                }))
            .catch(err => console.log(err))
    }
}

export default function settings (state = initialState, action = null) {
    switch(action.type) {
    case GET_SETTINGS_OK:
        console.log(action.settings)
        return state
            .set('settings', action.settings)
    case UPDATE_LEN:
    case UPDATE_LEN_OK:
        return state
            .set('settings', action.len)
    case GET_PLANS:
    case GET_PLANS_OK:
        return state
            .set('plans', action.plans)
    default:
        return state
    }
}
