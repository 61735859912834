import methods from '../apiMethods'


export function getAnswersStatistics({
  id,
  sort,
  divisionList,
  organizationList,
  filterDateFrom,
  filterDateTo,
  exportCsv=false,
  exportExcel=false
} = {}) {

  let url = `/report/answer_analysis/${id}/?`
  const statusList = []

  if(filterDateFrom){
    statusList.push(`started_date_0=${filterDateFrom}`)
  }

  if(filterDateTo){
    statusList.push(`started_date_1=${filterDateTo}`)
  }

// started_date_0=2020-08-04&started_date_1=2020-08-14

  if(exportCsv){
    statusList.push('export=csv')
  }

  if(divisionList){
    divisionList.map(division => statusList.push(`division=${division}`))
  }

  if(organizationList){
    organizationList.map(organization => statusList.push(`organization=${organization}`))
  }

  if(sort){
    statusList.push(`sort=${sort}`)
  }

  if(exportExcel){
    statusList.push('export=excel')
  }

  url += statusList.join('&');

  if(exportCsv){
    return methods.download(url)
  }

  return methods.get(url)
}

export function getGameSkills(id){
  return methods.get(`/games/${id}/skills/`)
}

export function getAllDivisions(id){
  return methods.get('/divisions/all/')
}

export function getGamesStatistics({
    page=1,
    all,
    date_from,
    date_to,
    game,
    sort,
    divisionList,
    organizationList,
    fullname,
    notStarted,
    inProgress,
    finished,
    failed,
    onlyBestAttempts,
    exportCsv=false,
    exportExcel=false
} = {}) {
    var url = '/report/game_analysis/'

    if(all){
      url += 'all/'
    }

    url += '?page=' + page;

    const statusList = []

    if(date_from){
        statusList.push('started_date_0=' + date_from);
    }

    if(date_to){
        statusList.push('started_date_1=' + date_to);
    }

    if(game && game !== null && game !== undefined){
      url += '&game=' + encodeURI(game)
    }

    // if(division && division !== null && division !== undefined){
    //   url += '&division=' + encodeURI(division)
    // }

    if(divisionList){
      divisionList.map(division => url += '&division=' + encodeURI(division))
    }

    if(organizationList){
      organizationList.map(organization => url += '&organization=' + encodeURI(organization))
    }

    // if(organization && organization !== null && organization !== undefined){
    //   url += '&organization=' + encodeURI(organization)
    // }

    if(fullname && fullname !== null && fullname !== undefined){
      url += '&search=' + encodeURI(fullname)
    }

    if(notStarted && notStarted !== null && notStarted !== undefined){
        statusList.push('status=not_started')
    }

    if(inProgress && inProgress !== null && inProgress !== undefined){
        statusList.push('status=not_completed')
    }

    if(finished && finished !== null && finished !== undefined){
        statusList.push('status=succeeded')
    }

    if(failed && failed !== null && failed !== undefined){
        statusList.push('status=failed')
    }

    if(sort && sort !== null && sort !== undefined){
        statusList.push(`sort=${sort}`)
    }

    if(onlyBestAttempts && onlyBestAttempts !== null
      && onlyBestAttempts !== undefined
    ){
        statusList.push('status=best_attempts')
    }

    if(exportCsv){
      statusList.push('export=csv')
    }

    if(exportExcel){
      statusList.push('export=excel')
    }

    if(statusList.length > 0){
      url += '&'+statusList.join('&');
    }

    if(exportCsv || exportExcel){
      return methods.download(url)
    }

    return methods.get(url)
}

export function getAllOrganizations() {
    return methods.get('/organizations/all/')
}

export function getOrganizationsStructure() {
    return methods.get('/organization/structure/')
}
