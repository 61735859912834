import { combineReducers } from 'redux-immutable'

import orgStructure from '../OrgStructure/modules/'
import training from '../Training/modules/'
import notifier from '../Notifier/modules/'
import gameplay from '../Gameplay/modules/'
import reports from '../Reports/modules'
import viewer from '../Main/modules/'
import roles from '../Roles/modules/'
import users from '../Users/modules/'
import settings from '../Settings/modules/'
import groups from '../Groups/modules'

const rootReducer = combineReducers({
    viewer,
    roles,
    orgStructure,
    users,
    training,
    reports,
    notifier,
    gameplay,
    settings,
    groups
})

export default rootReducer
