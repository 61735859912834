import { createSelector } from 'reselect'
import { Map } from 'immutable'

const getCurrentUser = state => state.getIn(['viewer', 'currentUser'])
const getAllRoles = state => state.getIn(['roles', 'roles'])

const getCurrentUserRole = createSelector(
    [ getCurrentUser, getAllRoles ],
    (currentUser, allRoles) => {
        if (currentUser.isEmpty()) return Map({})

        return allRoles.get(currentUser.get('role').toString())
    }
)

const getAllPermissions = state => state.getIn(['roles', 'permissions'])

const getPermissionsOfUser = createSelector(
    [ getCurrentUserRole, getAllPermissions ],
    (role, permissions) => {
        if (role.isEmpty()) {
            return Map({})
        }

        return permissions.filter(perm => {
            return role.get('permission_groups').includes(perm.get('id'))
        })
    }
)

export default getPermissionsOfUser
