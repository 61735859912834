import { normalize, schema } from 'normalizr'

export const  normalizeSettings = response => {
    const language =  new schema.Entity('language')
    const id =  new schema.Entity('id')
    const settings = new schema.Entity('settings', {
        language: language,
        id: id
    })
    return normalize(response, settings).entities
}
