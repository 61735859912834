import methods from "../apiMethods";

export function cloneGame(id) {
  return methods.post(`/games/${id}/clone/`, {});
}

export function getAllGameBackgroundImage() {
  return methods.get(`/images/?type=background`);
}

export function getAllGameCharacterImage() {
  return methods.get(`/images/?type=character`);
}

export function createAsset(data) {
  return methods.upload("/asset/", data, "POST");
}

export function getGameSessions(gameAssignmentId) {
  return methods.get(`/game_sessions/?assignment=${gameAssignmentId}`);
}

export function getAllGames({
  page = 1,
  all,
  isPublished,
  searchQuery,
  selfAssigned,
  title,
  compact,
  excludeList,
  excludeGroup,
  showDemoGames = true,
  showQuizzes = false,
} = {}) {
  console.log("getAllGames showQuizzes: ", showQuizzes);

  var url = "/games/";

  if (all) {
    url += "all/";
  }

  url += `?page=${page}&demo=${showDemoGames ? 1 : 0}`;

  if (compact) {
    url += "&compact=true";
  }

  if (showQuizzes) {
    url += "&showQuizzes=true";
  }

  if (isPublished !== null && isPublished !== undefined) {
    url += "&is_published=" + (isPublished ? "1" : "0");
  }

  if (searchQuery && searchQuery !== null && searchQuery !== undefined) {
    url += "&search=" + encodeURI(searchQuery);
  }

  if (title !== null && title !== undefined) {
    url += "&title=" + encodeURI(title);
  }

  if (selfAssigned !== null && selfAssigned !== undefined) {
    url += "&is_self_assigned=" + (selfAssigned ? "1" : "0");
  }

  if (excludeList) {
    excludeList.forEach((id, i) => {
      url += `&exclude=${id}`;
    });
  }

  if (excludeGroup !== null && excludeGroup !== undefined) {
    url += "&exclude_group=" + excludeGroup;
  }

  return methods.get(url);
}

export function showDemoGames(body) {
  return methods.post("/show_demo_courses/", body);
}

export function createGame(game) {
  return methods.post("/games/", game);
}

export function getGame(id) {
  return methods.get(`/games/${id}/`);
}

export function updateGame(values, id) {
  return methods.patch(`/games/${id}/`, values);
}

export function deleteGame(id) {
  return methods.delete(`/games/${id}/`);
}

export function createGameTemplateResult(data) {
  return methods.post("/games/result/template/", data);
}

export function getGameTemplateResult(id) {
  return methods.get(`/games/${id}/result/template/`);
}

export function createAssignments(assigns) {
  return methods.post("/assignments/game/bulk_assignment/", assigns);
}

export function getGameAssignments({
  page = 1,
  short,
  term,
  user_id,
  available,
  game,
  division,
  divisionList,
  organizationList,
  date_from,
  date_to,
  admin,
  exportCsv,
  exportExcel,
} = {}) {
  let base_url = "/assignments/game/";

  if (short) {
    base_url += "short/";
  }

  if (admin) {
    base_url += "admin/";
  }

  let url_list = [];

  if (exportExcel) {
    base_url += "excel/";
  } else {
    url_list.push(`page=${page}`);
  }

  if (game) {
    url_list.push(`game=${game}`);
  }

  if (term) {
    url_list.push(`search=${term}`);
  }

  if (division) {
    url_list.push(`division=${division}`);
  }

  if (organizationList) {
    organizationList.map((organization) =>
      url_list.push(`organization=${organization}`)
    );
  }

  if (divisionList) {
    divisionList.map((division) => url_list.push(`division=${division}`));
  }

  if (date_from) {
    url_list.push(`started_date_0=${date_from}`);
  }

  if (date_to) {
    url_list.push(`started_date_1=${date_to}`);
  }

  if (user_id !== undefined && user_id !== null) {
    url_list.push(`user=${user_id}`);
  }

  if (available !== undefined && available !== null) {
    url_list.push(`available=${available ? "true" : "false"}`);
  }

  if (exportCsv) {
    url_list.push("export=csv");
  }

  if (exportExcel) {
    url_list.push("export=excel");
  }

  let url = base_url + "?" + url_list.join("&");

  if (exportCsv || exportExcel) {
    return methods.download(url);
  }

  return methods.get(url);
}

export function deleteAssignment(assId) {
  return methods.delete(`/assignments/game/${assId}/`);
}

export function getNextPage(urlToNextPage) {
  const url = new URL(urlToNextPage);
  return methods.get(`${url.pathname}${url.search}`);
}
