import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Hint = props => {

    const {  text, show, top, link, linkText, linkStyles, elem } = props

    return (
        <section>
            <Modal
                show={show}
                dialogClassName="custom-modal"
                style={{marginTop: top}}
                size="sm"
                className='inmodal'
            >
                <section style={{textAlign: 'center', width: '100%', margin: '0 auto'}}>
                    <p style={{fontSize: '15px'}}>{text}</p>
                </section>
            </Modal>
            <Modal
                show={show}
                createAdminPanel={()=>{}}
                style={linkStyles}
                className='inmodal'
                size="sm"
            >
                {
                    elem || null
                }
                {
                    link &&
          <li className="hint-menu-button"><Link to={link}><i className='fa fa-sitemap'></i>{linkText}</Link></li>
                }
            </Modal>
        </section>
    )
}

Hint.propTypes = {
    // text: React.PropTypes.string.isRequired,
    // title: React.PropTypes.string.isRequired,
    // toggleModal: React.PropTypes.func.isRequired
}

export default Hint
