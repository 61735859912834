import { combineReducers } from 'redux-immutable'

import games from './games'
import assignments from './assignments'

const trainingActivities = combineReducers({
    games,
    assignments
})

export default trainingActivities