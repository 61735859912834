import React, { Component } from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { Map } from "immutable";
import { Layout } from "antd";

import Header from "./Header";
import Notifier from "../../Notifier/components/";
import Helmet from "../../common/Helmet";
import Breadcrumb from "../../common/Breadcrumb";

const { Content } = Layout;

class Main extends Component {
  render() {
    const {
      location,
      user,
      isAdmin,
      inAdminRole,
      switchRole,
      children,
      logOut,
      showHints,
      hideHeader,
      toggleGuideFlags,
      guideFlags,
    } = this.props;

    const head = user.get("head");
    const currentKey = location.pathname.split("/")[1] || "/";

    return (
      <Layout className="main_modified-ant-layout">
        <Helmet
          scripts={head && head.get("scripts")}
          inAdminRole={inAdminRole}
        />
        <Notifier />
        {!hideHeader && (
          <Header
            logOut={logOut}
            showHints={showHints}
            user={user}
            isAdmin={isAdmin}
            inAdminRole={inAdminRole}
            switchRole={switchRole}
            guideFlags={guideFlags}
            toggleGuideFlags={toggleGuideFlags}
          />
        )}
        <Content>
          <>
            {currentKey === "administrator" && <Breadcrumb />}
            {currentKey === "user" && (
              <div className="breadcrumb-section"></div>
            )}
          </>
          <div
            className={
              currentKey !== "gameplay" &&
              currentKey !== "quiz" &&
              currentKey !== "public" &&
              "site-layout"
            }
          >
            {children}
          </div>
        </Content>
      </Layout>
    );
  }
}

Main.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  switchRole: PropTypes.func.isRequired,
  inAdminRole: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
};

export default Main;
