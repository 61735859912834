import methods from '../apiMethods'

export function getPosition(posId) {
    return methods.get(`/positions/${posId}/`)
}

export function getDivisions({page=1, title, date_from, date_to} = {}) {
    var url = '/divisions/?page=' + page;

    if (title){
      url += '&title=' + title;
    }

    if (date_from){
      url += '&date_from=' + date_from;
    }

    if (date_to){
      url += '&date_to=' + date_to;
    }

    return methods.get(url)
}

export function getPositions({divisionId, organizationId} = {}) {
    const urlList = []

    console.log('divisionId, organizationId: ', divisionId, organizationId)

    if(divisionId){
      urlList.push(`division=${divisionId}`)
    }

    if(organizationId){
      urlList.push(`organization=${organizationId}`)
    }
    console.log('urlList: ', urlList)
    const url = '/positions/?' + urlList.join('&')

    console.log('URL: ', url)

    return methods.get(url)
}

export function addCompany(data) {
    return methods.post('/organizations/', data)
}

export function addDivision(data) {
    return methods.post('/divisions/', data)
}

export function getAllDivisions() {
    return methods.get('/divisions/all/')
}

export function addPosition(data) {
    return methods.post('/positions/', data)
}

export function getDivision(id) {
    return methods.get(`/divisions/${id}/`)
}

export function updateDivision(data) {
    return methods.patch( `/divisions/${data.id}/`, data)
}

export function updatePosition(data) {
    return methods.patch(`/positions/${data.id}/`, data)
}

export function updateCompany(data, id) {
    return methods.patch(`/organizations/${id}/`, data)
}

export function getAllCompanies() {
    return methods.get('/organizations/')
}

export function getCompany(id) {
    return methods.get(`/organizations/${id}/full/`)
}

export function deleteCompany(id) {
    return methods.delete(`/organizations/${id}/`)
}

export function deletePosition(id) {
    return methods.delete(`/positions/${id}/`)
}

export function deleteDivision(id) {
    return methods.delete(`/divisions/${id}/`)
}

export function bulkUpdateDivisions(data) {
    return methods.put('/divisions/bulk_update/', data)
}

export function bulkUpdatePositions(data) {
    return methods.put('/positions/bulk_update/', data)
}
