import * as api from '../api'
import { fromJS } from 'immutable'
import { GET_ORGANIZATION_OK, GET_ORGANIZATION } from './organizations'
import { GETTING_GAME_ASSIGNMENTS_OK } from '../../Training/modules/assignments'

import { normalizeDivision } from '../normalizers/'

import {
    GET_EMPLOYEES_OK,
    GET_EMPLOYEE_OK
} from '../../Users/modules/'

export const GETTING_DIVISION = 'orgStructure/divisions/GETTING_DIVISION'
export const GETTING_DIVISION_OK = 'orgStructure/divisions/GETTING_DIVISION_OK'
export const DELETE_DIVISION_OK = 'orgStructure/divisions/DELETE_DIVISION_OK'
export const UPDATE_DIVISION_OK = 'orgStructure/divisions/UPDATE_DIVISION_OK'
export const UPDATE_DIVISION_ERROR = 'orgStructure/divisions/UPDATE_DIVISION_ERROR'
export const CREATE_DIVISION_OK = 'orgStructure/divisions/CREATE_DIVISION_OK'
export const CREATE_DIVISION_ERROR = 'orgStructure/divisions/CREATE_DIVISION_ERROR'

const CREATE_DIVISION = 'orgStructure/divisions/CREATE_DIVISION'
const UPDATE_DIVISION = 'orgStructure/divisions/UPDATE_DIVISION'
const DELETE_DIVISION = 'orgStructure/divisions/DELETE_DIVISION'
const BULK_UPDATE_DIVISIONS = 'orgStructure/divisions/BULK_UPDATE_DIVISIONS'
const BULK_UPDATE_DIVISIONS_OK = 'orgStructure/divisions/BULK_UPDATE_DIVISIONS_OK'
const CHANGE_PARENT = 'orgStructure/divisions/CHANGE_PARENT'
const CHANGE_PARENT_OK = 'orgStructure/divisions/CHANGE_PARENT_OK'
const CANCEL_CHANGING_OF_PARENT = 'orgStructure/divisions/CANCEL_CHANGING_OF_PARENT'

const initialState = fromJS({
    active: {},
    divisions: {},
    isLoading: false
})

export function createDivision(formValues) {
    return (dispatch, getState) => {
        dispatch({ type: CREATE_DIVISION })

        const orgStructure = getState().get('orgStructure')

        const parentType = orgStructure.getIn(['tree', 'selectedNodeType'])
        const organization = orgStructure.getIn(['organizations', 'active', 'id'])

        let parent = null

        if (parentType !== 'organization') {
            parent = orgStructure.getIn(['tree', 'selectedNode', 'id'])
        }

        let payload = Object.assign({}, formValues, {
            parent,
            organization
        })

        return api.addDivision(payload)
            .then(res => normalizeDivision(res.data))
            .then(
                entities => dispatch({
                    type: CREATE_DIVISION_OK,
                    ...entities
                }),
                err => dispatch({
                    type: CREATE_DIVISION_ERROR,
                    message: err.message
                })
            )
    }
}

export function updateDivision(modal) {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_DIVISION })

        const payload = Object.assign({}, modal, {
            id: getState().getIn(['orgStructure', 'tree', 'selectedNode', 'id'])
        })

        return api.updateDivision(payload)
            .then(res => normalizeDivision(res.data))
            .then(
                entities => dispatch({
                    type: UPDATE_DIVISION_OK,
                    ...entities
                }),
                err => dispatch({
                    type: UPDATE_DIVISION_ERROR,
                    message: err.message
                })
            )
    }
}

export function getDivision(divId) {
    return dispatch => {
        dispatch({ type: GETTING_DIVISION })

        return api.getDivision(divId)
            .then(res =>  normalizeDivision(res.data))
            .then(({ entities }) => dispatch({
                type: GETTING_DIVISION_OK,
                ...entities
            }))
    }
}

export function deleteDivision() {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_DIVISION })

        const orgStructure = getState().get('orgStructure')

        const divId = orgStructure.getIn(['tree', 'selectedNode', 'id']).toString()

        const divisions = orgStructure
            .getIn(['divisions', 'divisions'])
            .delete(divId)
            .map(moveChildrenToTop)

        const activeDivisions = orgStructure
            .getIn(['divisions', 'active'])
            .delete(divId)
            .map(moveChildrenToTop)

        return api.deleteDivision(divId)
            .then(() => dispatch({
                type: DELETE_DIVISION_OK,
                divisions,
                activeDivisions,
                divId
            }))

        function moveChildrenToTop (division) {
            return division.get('parent') === divId
                ? division.set('parent', null)
                : division
        }
    }
}

export function bulkUpdateDivisions(divisions) {
    return dispatch => {
        dispatch({ type: BULK_UPDATE_DIVISIONS })

        return api.bulkUpdateDivisions(divisions.toArray())
            .then(() => dispatch({ type: BULK_UPDATE_DIVISIONS_OK, divisions }))
    }
}

export function cancelChangingOfParent() {
    return (dispatch, getState) => {
        const orgId = getState().getIn(['orgStructure', 'organizations', 'active', 'id'])

        const divisions = getState().getIn(['orgStructure', 'divisions', 'divisions'])
            .filter(division => division.get('organization') === orgId)

        dispatch({ type: CANCEL_CHANGING_OF_PARENT, divisions })
    }
}

export function changeParent(elementId, newParentId) {
    return (dispatch, getState) => {
        dispatch({ type: CHANGE_PARENT })

        const division = getState()
            .getIn(['orgStructure', 'divisions', 'active', elementId])
            .set('parent', newParentId)

        dispatch({
            type: CHANGE_PARENT_OK,
            divisions: {
                [elementId]: division
            }})
    }
}

export default function divisions(state = initialState, action = null) {
    switch (action.type) {
    case CREATE_DIVISION:
    case DELETE_DIVISION:
    case BULK_UPDATE_DIVISIONS:
        return state
            .set('isLoading', true)

    case GETTING_GAME_ASSIGNMENTS_OK:
        return state
            .update('divisions', divisions => divisions.merge(action.divisions))

    case GET_ORGANIZATION:
        return state
            .update('active', active => {
                return active
                    .clear()
                    .merge(state.get('divisions')
                        .filter(div => div.get('organization') === action.id))
            })

    case GET_ORGANIZATION_OK:
        return state
            .update('divisions', divisions => divisions.merge(action.divisions))
            .update('active', active => active.merge(action.divisions))

    case DELETE_DIVISION_OK:
        return state
            .set('divisions', fromJS(action.divisions))
            .set('active', fromJS(action.activeDivisions))
            .set('isLoading', false)

    case CANCEL_CHANGING_OF_PARENT:
    case CHANGE_PARENT_OK:
        return state
            .update('active', active => active.merge(action.divisions))

    case GET_EMPLOYEES_OK:
    case GET_EMPLOYEE_OK:
        return state
            .update('divisions', divisions => divisions.merge(action.divisions))

    case UPDATE_DIVISION_OK:
    case BULK_UPDATE_DIVISIONS_OK:
    case GETTING_DIVISION_OK:
    case CREATE_DIVISION_OK:
        return state
            .update('divisions', divisions => divisions.merge(action.divisions))
            .update('active', active => active.merge(action.divisions))
            .set('isLoading', false)

    case UPDATE_DIVISION_ERROR:
        return state
            .set('isLoading', false)

    default:
        return state
    }
}
