import methods from '../apiMethods'

export function getAllEmployees({
    page=1,
    all,
    isPublished,
    searchQuery,
    selfAssigned,
    title,
    filterOrganization,
    filterDivision,
    filterRole,
    fired=false,
    exportCsv=false,
    excludeList
} = {}) {
    var url = '/employee/'

    if(all){
      url += 'all/'
    }

    if(exportCsv){
      url += 'export/'
    }

    url += '?page=' + page;

    if(isPublished !== null && isPublished !== undefined){
      url += '&is_published=' + (isPublished ? '1' : '0')
    }

    if(searchQuery !== null && searchQuery !== undefined && searchQuery !== ''){
      url += '&search=' + encodeURI(searchQuery)
    }

    if(title !== null && title !== undefined){
      url += '&title=' + encodeURI(title)
    }

    if(filterOrganization !== null && filterOrganization !== undefined){
      url += '&organization=' + encodeURI(filterOrganization)
    }

    if(filterDivision !== null && filterDivision !== undefined){
      url += '&division=' + encodeURI(filterDivision)
    }

    if(filterRole !== null && filterRole !== undefined){
      url += '&role=' + encodeURI(filterRole)
    }

    if(selfAssigned !== null && selfAssigned !== undefined){
      url += '&is_self_assigned=' + (selfAssigned ? '1' : '0')
    }

    if(fired !== null && fired !== undefined){
      url += '&fired=' + (fired ? '1' : '0')
    }

    if(excludeList){
      excludeList.forEach((id, i) => {
        url += `&exclude=${id}`
      });
    }

    if(exportCsv){
      url += '&format=csv'

      return methods.download(url)
    }

    return methods.get(url)
}

export function getUser(key) {
    return methods.get(getUserUrl(key))
}

function getUserUrl(key){
  const urlList = ['/user/']

  if(key){
    urlList.push(key + '/')
  }

  return urlList.join('')
}

export function createUser(user) {
    return methods.post('/users/', user)
}

export function updateUser(key, data) {
    return methods.patch(getUserUrl(key), data)
}

export function updateAvatarUser(key, data) {
    return methods.upload(getUserUrl(key), data, 'patch')
}

export function fireUsers(users) {
    return methods.patch('/users/', users)
}

export function getNextPage(urlToNextPage) {
    const url = new URL(urlToNextPage)
    return methods.get(`${url.pathname}${url.search}`)
}

export function getEmployees() {
    return methods.get('/employee/')
}

export function getAllOrganizations() {
    return methods.get('/organizations/all/')
}
export function getAllDivisions() {
    return methods.get('/divisions/all/')
}
export function getAllRoles() {
    return methods.get('/roles/all/')
}
