import { normalize, schema } from 'normalizr'

const game = new schema.Entity('games')

export function normalizeGames(response) {
    return normalize(response, [ game ]).entities
}

export function normalizeGame(response) {
    return normalize(response, game).entities
}