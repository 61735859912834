import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import {
  FolderOutlined,
  UsergroupAddOutlined,
  DatabaseOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const LearnerNavigation = ({ location, t }) => {
  const [current, setCurrent] = useState("");

  useEffect(() => {
    setCurrent(location.pathname.split("/")[2]);
  }, [location.pathname]);

  return (
    <>
      <Menu selectedKeys={[`${current}`]} mode="horizontal">
        <Menu.Item
          className="modified-item"
          key="assignments"
          icon={<FolderOutlined />}
        >
          <Link to="/user/assignments">{t("training user")}</Link>
        </Menu.Item>
        <Menu.Item
          className="modified-item"
          key="self-education"
          icon={<DatabaseOutlined />}
        >
          <Link to="/user/self-education">{t("selfeducation")}</Link>
        </Menu.Item>
        <Menu.Item
          className="modified-item"
          key="profile"
          icon={<DatabaseOutlined />}
        >
          <Link to="/user/profile">{t("profile")}</Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

const AdminNavigation = ({ location, t }) => {
  const [current, setCurrent] = useState("");

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname]);

  function handleClick(e) {
    setCurrent(e.key);
  }

  return (
    <>
      <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <SubMenu
          className="modified-item"
          key="SubMenu1"
          icon={<UsergroupAddOutlined />}
          title={t("control")}
        >
          <Menu.Item key="/administrator/users">
            <Link to="/administrator/users">{t("employees")}</Link>
          </Menu.Item>
          <Menu.Item key="/administrator/organizations">
            <Link to="/administrator/organizations">{t("orgstructure")}</Link>
          </Menu.Item>
          <Menu.Item key="/administrator/roles">
            <Link to="/administrator/roles">{t("role")}</Link>
          </Menu.Item>
          <Menu.Item key="/administrator/groups">
            <Link to="/administrator/groups">{t("groups")}</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          className="modified-item"
          key="SubMenu2"
          icon={<DatabaseOutlined />}
          title={t("training")}
        >
          <Menu.Item key="/administrator/games">
            <Link to="/administrator/games">{t("games")}</Link>
          </Menu.Item>
          <Menu.Item key="/administrator/quizzes">
            <Link to="/administrator/quizzes">{t("quizzes")}</Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          className="modified-item"
          key="SubMenu3"
          icon={<ContainerOutlined />}
          title={t("reports")}
        >
          <Menu.Item key="/administrator/dashboard">
            <Link to="/administrator/dashboard">{t("dashboard")}</Link>
          </Menu.Item>
          <Menu.Item key="/administrator/reports/games">
            <Link to="/administrator/reports/games">{t("appointment")}</Link>
          </Menu.Item>
          <Menu.Item key="/administrator/reports/answers">
            <Link to="/administrator/reports/answers">{t("answers")}</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </>
  );
};

function Navigation({ isAdmin, inAdminRole }) {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      {isAdmin && inAdminRole ? (
        <AdminNavigation location={location} t={t} />
      ) : (
        <LearnerNavigation location={location} t={t} />
      )}
    </>
  );
}

export default Navigation;
