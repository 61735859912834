import { normalize, schema } from 'normalizr'

export function normalizeGame(response) {
    const audio = new schema.Entity('audio')
    const video = new schema.Entity('videos')
    const characters = new schema.Entity('characters')
    const backgrounds = new schema.Entity('backgrounds')
    const character = new schema.Entity('character')
    const background = new schema.Entity('background')
    const offer = new schema.Entity('offers')
    const answer = new schema.Entity('answers')
    const stage = new schema.Entity('stages')
    const question = new schema.Entity('questions', {
        answers: [ answer ]
    })

    const tab = new schema.Entity('tabs', {
        audio: [ audio ],
        videos: [ video ],
        characters: [ characters ],
        backgrounds: [ backgrounds ],
        offers: [ offer ],
        questions: [ question ],
        stages: [ stage ],
    })

    const game = new schema.Entity('game', {
        tabs: [ tab ],
        character: character,
        background:  background,
    })
    // console.log(normalize(response, game).entities);
    return normalize(response, game).entities
}
