import { stateToHTML } from 'draft-js-export-html'
import { saveAs } from 'file-saver'
import { Set } from 'immutable'
// import { delteHints } from './Main/containers/index'

export const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
export const specialCharRegExp = /`|~|!|@|#|\$|%|\^|&|\*|\(|\)|\+|=|\[|\{|\]|\}|\||\\|'|<|,|\.|>|\?|\/|""|;|:|\s/g
export const letters = /[а-яё]/i
export const companyName = /^[a-z0-9\-]+$/i


export function extractFormValues({ form, fields }) {
    return Object.keys(fields).reduce((obj, key) => {
        if (fields[key].customValue) {
            return obj
        }

        if (fields[key].extractValue) {
            obj[key] = fields[key].extractValue(form[key])

        } else if (form[key].type === 'checkbox') {
            obj[key] = form[key].checked

        } else {
            obj[key] = form[key].value

        }

        return obj
    }, {})
}

export const createInitState = fields => data => {

    return Object.keys(fields).reduce((obj, fieldKey) => {
        if (data.get(fieldKey)) {
            obj[fieldKey] = data.get(fieldKey)

        } else {
            obj[fieldKey] = fields[fieldKey].default
        }

        if (data.get(fieldKey) === undefined) {
            console.warn(`Data by key '${fieldKey}' not found`)
        }

        return obj
    }, {})
}

export function createEmptyState(fields) {
    return Object.keys(fields).reduce((obj, key) => {
        obj[key] = fields[key].default
        return obj
    }, {})
}

export function submitForm({ onApply, onError, values }, fields) {
    const errors = validateValues({ values, fields })

    if (Object.keys(errors).length) {
        onError(errors)
        return
    }

    for (let key in values) {
        if (
            values[key] === undefined
            || (typeof values[key] == 'string' && values[key].length === 0)
        ) {
            delete values[key]
        }
        if (fields[key] && fields[key].type === 'rich') {
            values[key] = stateToHTML(values[key].getCurrentContent())
        }
    }

    onApply(values)
}

export function validateValues({ values, fields }) {
    return Object.keys(values).reduce((err, key) => {
        if (
            fields[key]
            &&
            fields[key].validator
            && !fields[key].validator(values[key])
        ) {
            err[key] = true
        }
        return err
    }, {})
}

export const saveToXlsx = htmlTable => {
    saveAs(new Blob([htmlTable.innerHTML], {
        type: 'application/vnd.ms-excel'
    }), 'report.xls')
}

export const checkPermission = (permissions, { isAdmin, codeName, mask }) => {
    return true
}

export const createPaginate = (page, rows) => {
    const newPage = page + 1
    const countItems = 10
    const a = rows.splice(newPage * countItems, rows.size - newPage * countItems)
    const b = a.splice(0, newPage * countItems - countItems)
    return b
}

const hintTitles =
 ['orgStructureHint', 'welcomeAdminHint', 'rolesHint', 'usersHint',
     'gamesHint', 'answersReportHint', 'gameReportHint', 'settingsHint']

export const createdHints = closeHint => {
    if (closeHint) window.localStorage.setItem(closeHint, false)
    console.log()
    const hintData = []

    hintTitles.map((hint) => {
        const localStorageData = JSON.parse(window.localStorage.getItem(hint))

        if (localStorageData === null || localStorageData !== false)
            window.localStorage.setItem(hint, true)


        hintData.push(localStorageData)
    })

    if (!hintData.includes(true) && !hintData.includes(null)) return true
    else return false

}

export const deleteHintsStorage = () => {
    hintTitles.map((hint) => {
        if (window.localStorage.getItem(hint) == 'false') {
            window.localStorage.removeItem(hint)
        }
    })
}
deleteHintsStorage()
// export const convertDataUrlToBlob = (dataURI) => {
//
//   let byteString = atob(dataURI.split(',')[1]);
//
//    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
//
//    let arrayBuffer = new ArrayBuffer(byteString.length);
//    let _ia = new Uint8Array(arrayBuffer);
//
//      for (var i = 0; i < byteString.length; i++) {
//          _ia[i] = byteString.charCodeAt(i);
//      }
//
//    let dataView = new DataView(arrayBuffer);
//
//    let blob = new Blob([dataView.buffer], { type: mimeString });
//    let url = URL.createObjectURL(blob);
//
//    return url
// }
