import { methods } from "../api/apiMethods";

export async function registrationUser(dispatch, registrationPayload) {
  try {
    dispatch({ type: "REQUEST_REGISTRATION" });

    let response = await methods.post("/double_create/", registrationPayload);

    const { data } = response;

    if (data) {
      dispatch({
        type: "REGISTRATION_SUCCESS",
        payload: data,
      });
      return data;
    }

    dispatch({
      type: "REGISTRATION_ERROR",
      error: "",
    });

    return;
  } catch (error) {
    dispatch({
      type: "REGISTRATION_ERROR",
      error: error.response.data.message,
    });
  }
}

export async function registrationUserConfirm(dispatch, confirmPayload) {
  try {
    dispatch({
      type: "REQUEST_REGISTRATIOM_CONFIRM",
    });

    let response = await methods.post("/pass_confirm/", confirmPayload);

    const { data } = response;

    if (data.user) {
      dispatch({
        type: "REGISTRATION_CONFIRM_SUCCESS",
        payload: data,
      });
      localStorage.setItem("token", data.token);
      return data;
    }

    dispatch({
      type: "REGISTRATION_CONFIRM_ERROR",
      error: "",
    });

    return;
  } catch (error) {
    dispatch({
      type: "REGISTRATION_CONFIRM_ERROR",
      error: error.response.data.message,
    });
  }
}

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await methods.post("/v2/token-auth/", loginPayload);
    const { data } = response;

    if (data.user) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: data,
      });
      localStorage.setItem("token", data.token);
      return data;
    }

    dispatch({
      type: "LOGIN_ERROR",
      error: "",
    });

    return;
  } catch (error) {
    dispatch({
      type: "LOGIN_ERROR",
      error: error.response.data.message,
    });
  }
}

export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("token");
  localStorage.removeItem("currentUserId");
  localStorage.removeItem("inAdminRole");
  localStorage.removeItem("isAdmin");
}

export async function recoverUser(dispatch, recoverPasswordPayload) {
  try {
    dispatch({
      type: "REQUEST_RECOVER_PASSWORD",
    });

    let response = await methods.post("/reset/", recoverPasswordPayload);

    const { data } = response;

    if (data.code === 200) {
      dispatch({
        type: "RECOVER_PASSWORD_SUCCESS",
        payload: data,
      });
      return data;
    }

    dispatch({
      type: "RECOVER_PASSWORD_ERROR",
      error: "",
    });

    return;
  } catch (error) {
    dispatch({
      type: "RECOVER_PASSWORD_ERROR",
      error: error.response.data.message,
    });
  }
}

export async function recoverUserConfirm(dispatch, confirmPayload) {
  try {
    dispatch({
      type: "REQUEST_RECOVER_CONFIRM",
    });

    let response = await methods.post(
      `/password_reset/${confirmPayload?.key}/`,
      {
        new_password1: confirmPayload?.password,
        new_password2: confirmPayload?.password,
      }
    );

    const { data } = response;

    if (data.user) {
      dispatch({
        type: "RECOVER_CONFIRM_SUCCESS",
        payload: data,
      });
      localStorage.setItem("token", data.token);
      return data;
    }

    dispatch({
      type: "RECOVER_CONFIRM_ERROR",
      error: "",
    });

    return;
  } catch (error) {
    dispatch({
      type: "REGISTRATION_CONFIRM_ERROR",
      error: error.response.data.message,
    });
  }
}

export function resetMessageError(dispatch) {
  dispatch({ type: "RESET_MESSAGE_ERROR" });
}
