import methods from '../apiMethods'

export function openGameSession({gameAssignmentId, gameId} = {}) {
    const params = {}

    if(gameAssignmentId){
      params['game_assignment_id'] = gameAssignmentId
    }else if(gameId){
      params['game_id'] = gameId
    }

    return methods.post('/game_sessions/', params)
}

export function getGame(gameId) {
    return methods.get(`/games/${gameId}/?nested=true`)
}

export function getGameData(gameId) {
    return methods.get(`/games/${gameId}/`)
}

export function getGameFromGameAssignment(GameAssignmentId) {
    return methods.get(`/games/assignment/${GameAssignmentId}/`)
}

export function getQuiz(id) {
    return methods.get(`/quiz/${id}/?mode=play`)
}

export function getQuizAssignment(id) {
    return methods.get(`/quiz/assignment/${id}/?mode=play`)
}

export function sendSelectedAnswer(payload) {
    return methods.post('/results/', payload)
}

export function sendSelectedMultipleAnswer(payload) {
    return methods.post('/results/multiple/', payload)
}