import methods from '../apiMethods'

export function createRole(data) {
    return methods.post('/roles/', data)
}

export function assignToRole(roleId, data) {
    return methods.patch(`/roles/${roleId}/assign/`, data)
}

export function updateRole(data) {
    return methods.patch(`/roles/${data.id}/`, data)
}

export function deleteRole(id) {
    return methods.delete(`/roles/${id}/`)
}

export function getAllRoles() {
    return methods.get('/roles/all/')
}

export function getRole(roleId) {
    return methods.get(`/roles/${roleId}/`)
}

export function getAllPermissions() {
    return methods.get('/permission_groups/')
}
