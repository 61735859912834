import * as api from '../api'
import { fromJS } from 'immutable'

import { normalizeRole, normalizeRoles } from '../normalizers/normalizeRoles'
import { normalizePermissions } from '../normalizers/normalizePermissions'

import { LOADING_ME_OK } from '../../Main/modules/'
import {
    GET_EMPLOYEES_OK,
    GET_NEXT_PAGE_EMPLOYEES_OK,
    GET_EMPLOYEE_OK,
    CREATE_USER_OK
} from '../../Users/modules/'

import { GETTING_GAME_ASSIGNMENTS_OK } from '../../Training/modules/assignments'

export const GETTING_ROLE_OK = 'roles/GETTING_ROLE_OK'
export const GETTING_ROLES_OK = 'roles/GETTING_ROLES_OK'
export const DELETING_ROLE_OK = 'roles/DELETING_ROLE_OK'
export const SUBMIT_EDIT_OK = 'roles/SUBMIT_EDIT_OK'
export const SUBMIT_EDIT_ERROR = 'roles/SUBMIT_EDIT_ERROR'

const SELECTING_ROLE = 'roles/SELECTING_ROLE'
const CREATING_ROLE = 'roles/CREATING_ROLE'
const CREATING_ROLE_OK = 'roles/CREATING_ROLE_OK'
const CREATING_ROLE_ERROR = 'roles/CREATING_ROLE_ERROR'
const DELETING_ROLE = 'roles/DELETING_ROLE'
const DELETING_ROLE_ERROR = 'roles/DELETING_ROLE_ERROR'
const GETTING_ROLES = 'roles/GETTING_ROLES'
const GETTING_ROLES_ERROR = 'roles/GETTING_ROLES_ERROR'
const GETTING_ROLE = 'roles/GETTING_ROLE'
const GETTING_PERMISSIONS = 'roles/GETTING_PERMISSIONS'
const GETTING_PERMISSIONS_ERROR = 'roles/GETTING_PERMISSIONS_ERROR'
const GETTING_PERMISSIONS_OK = 'roles/GETTING_PERMISSIONS_OK'
const SUBMIT_EDIT = 'roles/SUBMIT_EDIT'
const CHANGE_INFO = 'roles/CHANGE_INFO'
const ADD_PERMISSION_TO_ROLE = 'roles/ADD_PERMISSION_TO_ROLE'
const REMOVE_PERMISSION_FROM_ROLE = 'roles/REMOVE_PERMISSION_FROM_ROLE'

const initialState = fromJS({
    roles: { },
    permissions: { },
    selected: { },
    isLoading: false,
    createError: false
})

export function selectRole(roleId) {
    return { type: SELECTING_ROLE, roleId }
}

export function createRole(payload) {
    return dispatch => {
        dispatch({ type: CREATING_ROLE })

        return api.createRole(payload)
            .then(res => normalizeRole(res.data))
            .then(({ roles }) => dispatch({
                type: CREATING_ROLE_OK,
                roles
            }),
            err => {
                // console.log('createRole err: ', err)

                dispatch({
                  type: CREATING_ROLE_ERROR,
                  errors: true
                })
              }
            )
    }
}
export function deleteRole(roleId) {
    return dispatch => {
        dispatch({ type: DELETING_ROLE })

        return api.deleteRole(roleId)
            .then(() => dispatch({ type: DELETING_ROLE_OK, roleId }))
    }
}

export function submitEdit() {
    return (dispatch, getState) => {
        dispatch({ type: SUBMIT_EDIT })

        let payload = getState().getIn(['roles', 'selected']).toJS()

        return api.updateRole(payload)
            .then(res => normalizeRole(res.data))
            .then(
                entities => dispatch({
                    type: SUBMIT_EDIT_OK,
                    ...entities
                }),
                err => dispatch({
                    type: SUBMIT_EDIT_ERROR,
                    message: err.message
                })
            )
    }
}

export function changeRoleInfo(newInfo) {
    return { type: CHANGE_INFO, data: newInfo }
}

export function addPermission(permissionId) {
    return (dispatch, getState) => {
        const role = getState().getIn(['roles', 'selected'])

        const intId = +permissionId

        if (role.get('permission_groups').includes(intId)) return

        const selected = role
            .update('permission_groups', permGroups => permGroups.push(intId))

        dispatch({ type: ADD_PERMISSION_TO_ROLE, selected })
    }
}

export function deletePermission(permissionId) {
    return (dispatch, getState) => {
        const role = getState().getIn(['roles', 'selected'])

        const selected = role
            .update('permission_groups',
                permGroups => permGroups.filter(id => id !== permissionId))

        dispatch({ type: REMOVE_PERMISSION_FROM_ROLE, selected })
    }
}

export function getAllPermissions() {
    return dispatch => {
        dispatch({type: GETTING_PERMISSIONS})

        return api.getAllPermissions()
            .then(res => normalizePermissions(res.data.results).entities)
            .then(entities => dispatch({
                type: GETTING_PERMISSIONS_OK,
                ...entities
            }))
    }
}

export function getRole(id) {
    return dispatch => {
        dispatch({type: GETTING_ROLE})

        return api.getRole(id)
            .then(res => normalizeRole(res.data))
            .then(entities => dispatch({
                type: GETTING_ROLE_OK,
                ...entities,
                selected: entities.roles[id]
            }))
    }
}

export function getAllRoles() {
    return dispatch => {
        dispatch({type: GETTING_ROLES})

        return api.getAllRoles()
            .then(res => normalizeRoles(res.data))
            .then(entities => dispatch({
                type: GETTING_ROLES_OK,
                ...entities
            }))
    }
}

export default function roles(state = initialState, action = null) {
    switch (action.type) {
    case GETTING_ROLES_ERROR:
    case SUBMIT_EDIT_ERROR:
    case CREATING_ROLE_ERROR:
        return state
            .set('createError', true)
            .set('error', true)
    case DELETING_ROLE_ERROR:
    case GETTING_PERMISSIONS_ERROR:
        return state
            .set('isLoading', false)

    case DELETING_ROLE_OK:
        return state
            .update('roles', role => role.delete(action.roleId))
            .update('selected', selected => selected.clear())
            .set('isLoading', false)
            .set('isError', false)

    case SELECTING_ROLE:
        return state
            .set('selected', state.getIn(['roles', action.roleId]))

    case CREATING_ROLE_OK:
        return state
            .set('createError', false)
            .set('errors', false)
            .update('roles', role => role.merge(action.roles))
    case GETTING_ROLE_OK:
    case SUBMIT_EDIT_OK:
    case CREATE_USER_OK:
        return state
            .update('selected', selected => selected.merge(action.selected))
            .update('roles', roles => roles.merge(action.roles))
            .update('permissions', permissions => permissions.merge(action.permissions))
            .set('isLoading', false)

    case GETTING_ROLES_OK:
    case LOADING_ME_OK:
    case GET_EMPLOYEES_OK:
    case GET_EMPLOYEE_OK:
    case GET_NEXT_PAGE_EMPLOYEES_OK:
    case GETTING_GAME_ASSIGNMENTS_OK:
        return state
            .update('roles', roles => roles.merge(action.roles))
            .update('permissions', permissions => permissions.merge(action.permissions))
            .set('isLoading', false)

    case GETTING_ROLE:
    case GETTING_ROLES:
    case CREATING_ROLE:
    case SUBMIT_EDIT:
    case DELETING_ROLE:
        return state
            .set('isLoading', true)

    case GETTING_PERMISSIONS_OK:
        return state
            .update('permissions', permissions => permissions.merge(action.permissions))
            .set('isLoading', false)

    case CHANGE_INFO:
        return state
            .setIn(['selected', 'description'], action.data.description)
            .setIn(['selected', 'title'], action.data.title)

    case ADD_PERMISSION_TO_ROLE:
    case REMOVE_PERMISSION_FROM_ROLE:
        return state
            .update('selected', selected => selected.merge(action.selected))

    default:
        return state
    }
}
