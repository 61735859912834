import { normalize, schema } from 'normalizr'

const permission = new schema.Entity('permissions')
const title = new schema.Entity('title')
const division = new schema.Entity('divisions', {
  title
})

const position = new schema.Entity('positions', {
    division
})

const role = new schema.Entity('roles', {
    permission_groups: [ permission ]
})

const employeeSchema = new schema.Entity('employees', {
    user_position: [position],
    role
})

export function normalizeEmployee(employee) {
    return normalize(employee, employeeSchema).entities
}

export function normalizeEmployees(employees) {
    const employeesSchema = [ employeeSchema ]

    return normalize(employees, employeesSchema).entities
}
