import * as api from '../api'
import { fromJS } from 'immutable'

import {
    normalizeOrganizations,
    normalizeOrganization
} from '../normalizers/'

export const GET_ORGANIZATION = 'orgStructure/organizations/GET_ORGANIZATION'
export const GET_ORGANIZATION_OK = 'orgStructure/organizations/GET_ORGANIZATION_OK'
export const GET_ORGANIZATIONS_OK = 'orgStructure/organizations/GET_ORGANIZATIONS_OK'
export const SELECT_ORGANIZATION = 'orgStructure/organizations/SELECT_ORGANIZATION'
export const CREATE_ORGANIZATION_OK = 'orgStructure/organizations/CREATE_ORGANIZATION_OK'
export const CREATE_ORGANIZATION_ERROR = 'orgStructure/organizations/CREATE_ORGANIZATION_ERROR'
export const SUBMIT_ORGANIZATION_EDIT_OK = 'orgStructure/organizations/SUBMIT_ORGANIZATION_EDIT_OK'
export const SUBMIT_ORGANIZATION_EDIT_ERROR = 'orgStructure/organizations/SUBMIT_ORGANIZATION_EDIT_ERROR'

const GET_ORGANIZATIONS = 'orgStructure/organizations/GET_ORGANIZATIONS'
const CREATE_ORGANIZATION = 'orgStructure/organizations/CREATE_ORGANIZATION'
const DELETE_ORGANIZATION = 'orgStructure/organizations/DELETE_ORGANIZATION'
const DELETE_ORGANIZATION_OK = 'orgStructure/organizations/DELETE_ORGANIZATION_OK'
const SUBMIT_ORGANIZATION_EDIT = 'orgStructure/organizations/SUBMIT_ORGANIZATION_EDIT'

const initialState = fromJS({
    active: {},
    organizations: {},
    isLoading: false
})

export function createCompany(formValues) {
    return dispatch => {
        dispatch({ type: CREATE_ORGANIZATION })

        return api.addCompany(formValues)
            .then(res => normalizeOrganization(res.data))
            .then(
                entities => dispatch({
                    type: CREATE_ORGANIZATION_OK,
                    ...entities
                },
                err => dispatch({
                    type: CREATE_ORGANIZATION_ERROR,
                    message: err.message
                })
                )
            )
    }
}

export function deleteCompany() {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_ORGANIZATION })

        const orgId = getState().getIn(['orgStructure', 'organizations', 'active', 'id'])

        return api.deleteCompany(orgId)
            .then(() => dispatch({ type: DELETE_ORGANIZATION_OK, orgId }))
    }
}

export function updateOrganization(payload) {
    return (dispatch, getState) => {
        dispatch({ type: SUBMIT_ORGANIZATION_EDIT })

        const id = getState().getIn(['orgStructure', 'organizations', 'active', 'id'])

        return api.updateCompany(payload, id)
            .then(res => normalizeOrganization(res.data))
            .then(
                entities => dispatch({
                    type: SUBMIT_ORGANIZATION_EDIT_OK,
                    ...entities,
                    organization: entities.organizations[id]
                },
                err => dispatch({
                    type: SUBMIT_ORGANIZATION_EDIT_ERROR,
                    message: err.message
                })
                ))
    }
}

export function selectOrganization(id) {
    return (dispatch, getState) => {
        const organization = getState().getIn(['orgStructure', 'organizations', 'organizations', id])

        dispatch({ type: SELECT_ORGANIZATION, organization })
    }
}

export function getCompany(id) {
    return dispatch => {
        dispatch({ type: GET_ORGANIZATION })

        return api.getCompany(id)
            .then(res => normalizeOrganization(res.data))
            .then(entities => dispatch({
                type: GET_ORGANIZATION_OK,
                ...entities,
                organization: entities.organizations[id],
            }))
    }
}

export function getAllCompanies() {
    return dispatch => {
        dispatch({ type: GET_ORGANIZATIONS })

        return api.getAllCompanies()
            .then(res => normalizeOrganizations(res.data))
            .then(entities => dispatch({
                type: GET_ORGANIZATIONS_OK,
                ...entities
            }))
    }
}

export default function organizations(state = initialState, action = null) {
    switch (action.type) {
    case GET_ORGANIZATIONS:
    case CREATE_ORGANIZATION:
    case GET_ORGANIZATION:
    case DELETE_ORGANIZATION:
    case SUBMIT_ORGANIZATION_EDIT:
        return state.set('isLoading', true)

    case CREATE_ORGANIZATION_ERROR:
    case SUBMIT_ORGANIZATION_EDIT_ERROR:
        return state.set('isLoading', false)

    case DELETE_ORGANIZATION_OK:
        return state
            .deleteIn(['organizations', action.orgId.toString()])
            .update('active', active => active.clear())
            .set('isLoading', false)

    case SELECT_ORGANIZATION:
        return state
            .update('active', active => active.merge(action.organization))

    case SUBMIT_ORGANIZATION_EDIT_OK:
    case GET_ORGANIZATION_OK:
        return state
            .update('active', active => active.merge(action.organization))
            .update('organizations', organizations => organizations.merge(action.organizations))
            .set('isLoading', false)

    case CREATE_ORGANIZATION_OK:
        return state
            .update('organizations', organizations => organizations.merge(action.organizations))
            .set('isLoading', false)
            .set('isError', false)

    case GET_ORGANIZATIONS_OK:
        return state
            .update('organizations', organizations => organizations.merge(
                action.organizations
              ).sort(
                (item1, item2) => item1.get('full_title').localeCompare(item2.get('full_title'))
              )
             )
            .set('isLoading', false)
            .set('isError', false)

    default:
        return state
    }
}
