import * as api from '../api'
import { fromJS } from 'immutable'

import { normalizeGames, normalizeGame } from '../normalizers/normalizeGames'

import { GETTING_GAME_ASSIGNMENTS_OK } from './assignments'

export const UPDATING_GAME_OK = 'training/games/UPDATING_GAME_OK'
export const UPDATING_GAME_ERROR = 'training/games/UPDATING_GAME_ERROR'
export const CLONNING_GAME = 'training/games/CLONNING_GAME'
export const CLONNING_GAME_OK = 'training/games/CLONNING_GAME_OK'
export const CLONNING_GAME_ERROR = 'training/games/CLONNING_GAME_ERROR'
export const DELETING_GAME_OK = 'training/games/DELETING_GAME_OK'
export const CREATING_GAME_OK = 'training/games/CREATING_GAME_OK'
export const DELETING_GAME_ERROR = 'training/games/DELETING_GAME_ERROR'
export const CREATING_GAME_ERROR = 'training/games/CREATING_GAME_ERROR'
export const CREATING_GAME_TEMPLATE_RESULT_OK = 'training/games/CREATING_GAME_TEMPLATE_RESULT_OK'
export const CREATING_GAME_TEMPLATE_RESULT_ERROR = 'training/games/CREATING_GAME_TEMPLATE_RESULT_ERROR'

const GETTING_GAMES = 'training/games/GETTING_GAMES'
const GETTING_GAMES_OK = 'training/games/GETTING_GAMES_OK'
const GETTING_GAMES_ERROR = 'training/games/GETTING_ERROR'
const SHOW_DEMO_GAMES = 'training/games/SHOW_DEMO_GAMES'
const SHOW_DEMO_GAMES_OK = 'training/games/SHOW_DEMO_GAMES_OK'
const SHOW_DEMO_GAMES_ERROR = 'training/games/SHOW_DEMO_GAMES_ERROR'
const GETTING_GAME = 'training/games/GETTING_GAME'
const GETTING_GAME_OK = 'training/games/GETTING_GAME_OK'
const CREATING_GAME = 'training/games/CREATING_GAME'
const UPDATING_GAME = 'training/games/UPDATING_GAME'
const GETTING_NEXT_PAGE = 'training/games/GETTING_NEXT_PAGE'
const GETTING_NEXT_PAGE_OK = 'training/games/GETTING_NEXT_PAGE_OK'
const GETTING_FILTERED_GAMES = 'training/games/GETTING_FILTERED_GAMES'
const GETTING_FILTERED_GAMES_OK = 'training/games/GETTING_FILTERED_GAMES_OK'
const DELETING_GAME = 'training/games/DELETING_GAME'
const SELECT_GAME = 'training/games/SELECT_GAME'
const CANCEL_SELECTION = 'training/games/CANCEL_SELECTION'
const CANCEL_SELECTION_ALL = 'training/games/CANCEL_SELECTION_ALL'
const GETTING_SELF_EDUCATION_GAMES = 'training/games/GETTING_SELF_EDUCATION_GAMES'
const GETTING_SELF_EDUCATION_GAMES_OK = 'training/games/GETTING_SELF_EDUCATION_GAMES_OK'
const GETTING_ALL_GAME_BACKGROUND_IMAGE = 'training/games/GETTING_ALL_GAME_BACKGROUND_IMAGE'
const GETTING_ALL_GAME_BACKGROUND_IMAGE_OK = 'training/games/GETTING_ALL_GAME_BACKGROUND_IMAGE_OK'
const GETTING_ALL_GAME_BACKGROUND_IMAGE_ERROR = 'training/games/GETTING_ALL_GAME_BACKGROUND_IMAGE_ERROR'
const GETTING_ALL_GAME_CHARACTER_IMAGE = 'training/games/GETTING_ALL_GAME_CHARACTER_IMAGE'
const GETTING_ALL_GAME_CHARACTER_IMAGE_OK = 'training/games/GETTING_ALL_GAME_CHARACTER_IMAGE_OK'
const GETTING_ALL_GAME_CHARACTER_IMAGE_ERROR = 'training/games/GETTING_ALL_GAME_CHARACTER_IMAGE_ERROR'
const CREATING_GAME_TEMPLATE_RESULT = 'training/games/CREATING_GAME_TEMPLATE_RESULT'

const initialState = fromJS({
  games: {},
  total: 0,
  page: 1,
  selected: {},
  nextPage: null,
  prevPage: null,
  perPage: 0,
  totalPages: 0,
  isLoading: false,
  errorMessage: '',
  backgroundImages: [],
  characterImages: []
})

export function showDemoGames(body) {
  return (dispatch) => {
    dispatch({ type: SHOW_DEMO_GAMES });
    return api.showDemoGames(body).then(
      (res) =>
        dispatch({
          type: SHOW_DEMO_GAMES_OK,
        }),
      (err) =>
        dispatch({
          type: SHOW_DEMO_GAMES_ERROR,
          message: err.message,
        })
    );
  };
}

export function createGameTemplateResult(data) {
  return dispatch => {
    dispatch({ type: CREATING_GAME_TEMPLATE_RESULT })

    return api.createGameTemplateResult(data)
      .then(
        (res) => dispatch({
          type: CREATING_GAME_TEMPLATE_RESULT_OK
        }),
        err => dispatch({
          type: CREATING_GAME_TEMPLATE_RESULT_ERROR,
          message: err.message
        })
      )
  }
}

export function getAllGameBackgroundImage() {
  return dispatch => {
    dispatch({ type: GETTING_ALL_GAME_BACKGROUND_IMAGE })

    return api.getAllGameBackgroundImage()
      .then(
        res => dispatch({
          type: GETTING_ALL_GAME_BACKGROUND_IMAGE_OK,
          backgroundImages: res.data
        }),
        err => dispatch({
          type: GETTING_ALL_GAME_BACKGROUND_IMAGE_ERROR
        })
      )
  }
}

export function getAllGameCharacterImage() {
  return dispatch => {
    dispatch({ type: GETTING_ALL_GAME_CHARACTER_IMAGE })

    return api.getAllGameCharacterImage()
      .then(
        res => dispatch({
          type: GETTING_ALL_GAME_CHARACTER_IMAGE_OK,
          characterImages: res.data
        }),
        err => dispatch({
          type: GETTING_ALL_GAME_CHARACTER_IMAGE_ERROR
        })
      )
  }
}

export function cloneGame(gameId) {
  return (dispatch, getState) => {
    dispatch({ type: CLONNING_GAME })

    return api.cloneGame(gameId)
      .then(
        ({ data }) => dispatch({
          type: CLONNING_GAME_OK,
          clonned: normalizeGame(data).games
        }),
        err => dispatch({
          type: CLONNING_GAME_ERROR,
          message: err.message
        })
      )
  }
}

export function getSelfEducationGames(body) {
  return dispatch => {
    dispatch({ type: GETTING_SELF_EDUCATION_GAMES })

    return api.getAllGames(body)
      .then(res => normalizeGames(res.data.results))
      .then(({ games }) => {
        dispatch({
          type: GETTING_SELF_EDUCATION_GAMES_OK,
          games
        })
      })
  }
}

export function selectGame(gameId) {
  return (dispatch, getState) => {
    const game = getState().getIn(['training', 'games', 'games', gameId])

    return dispatch({
      type: SELECT_GAME,
      game: {
        [gameId]: game
      },
      gameId
    })
  }
}

export function cancelSelection(gameId) {
  return (dispatch, getState) => {
    const game = getState().getIn(['training', 'games', 'selected', gameId])

    return dispatch({
      type: CANCEL_SELECTION,
      game: {
        [gameId]: game
      },
      gameId
    })
  }
}

export function cancelSelectionAll() {
  return (dispatch, getState) => {
    const selected = getState().getIn(['training', 'games', 'selected'])

    return dispatch({ type: CANCEL_SELECTION_ALL, selected })
  }
}

export function createGame(data) {
  return dispatch => {
    dispatch({ type: CREATING_GAME })

    return api.createGame(data)
      .then(res => normalizeGame(res.data))
      .then(
        ({ games }) => dispatch({
          type: CREATING_GAME_OK,
          games
        }),
        err => dispatch({
          type: CREATING_GAME_ERROR,
          message: err.message
        })
      )
  }
}

export function deleteGame(gameId) {
  return dispatch => {
    dispatch({ type: DELETING_GAME })

    return api.deleteGame(gameId)
      .then(
        () => dispatch({
          type: DELETING_GAME_OK,
          gameId
        }),
        err => dispatch({
          type: DELETING_GAME_ERROR,
          message: err.message
        })
      )
  }
}

export function getAllGames({page=1, isPublished, searchQuery, selfAssigned, compact, showDemoGames, showQuizzes} = {}) {
  return dispatch => {
    dispatch({ type: GETTING_GAMES })

    return api.getAllGames({
      page: page,
      compact: compact,
      isPublished: isPublished,
      searchQuery: searchQuery,
      selfAssigned: selfAssigned,
      showDemoGames: showDemoGames,
      showQuizzes: showQuizzes,
    })
      .then(response => {
        const normalized = normalizeGames(response.data.results)

        dispatch({
          type: GETTING_GAMES_OK,
          games: normalized.games,
          nextPage: response.data.next,
          prevPage: response.data.prev,
          total: response.data.total,
          count: response.data.count,
          totalPages: response.data.total_pages,
          perPage: response.data.per_page,
          page: response.data.page,
        })
      },
        err => dispatch({
          type: GETTING_GAMES_ERROR,
          errorMessage: 'Error Message'
        })
      )
  }
}

export function getGame(gameId) {
  return dispatch => {
    dispatch({ type: GETTING_GAME })

    return api.getGame(gameId)
      .then(res => normalizeGame(res.data))
      .then(({ games }) => dispatch({
        type: GETTING_GAME_OK,
        games
      }))
  }
}

export function getFilteredGames({page=1, isPublished, searchQuery} = {}) {
  return dispatch => {
    dispatch({ type: GETTING_FILTERED_GAMES })

    return api.getAllGames({page: page, isPublished: isPublished, searchQuery:searchQuery})
      .then(res => {
        const normalized = normalizeGames(res.data.results)

        dispatch({
          type: GETTING_FILTERED_GAMES_OK,
          games: normalized.games,
          nextPage: res.data.next
        })
      })
  }
}

export function getNextPage(pageUrl) {
  return dispatch => {
    dispatch({ type: GETTING_NEXT_PAGE })

    return api.getNextPage(pageUrl)
      .then(res => {
        const normalized = normalizeGames(res.data.results)

        dispatch({
          type: GETTING_NEXT_PAGE_OK,
          games: normalized.games,
          nextPage: res.data.next
        })
      })
  }
}

export function updateGame(values, gameId) {
  return dispatch => {
    dispatch({ type: UPDATING_GAME })

    return api.updateGame(values, gameId)
      .then(res => normalizeGame(res.data))
      .then(
        ({ games }) => dispatch({
          type: UPDATING_GAME_OK,
          games
        }),
        err => dispatch({
          type: UPDATING_GAME_ERROR,
          message: err.message
        })
      )
  }
}

export default function games (state = initialState, action = null) {
  switch (action.type) {
    case GETTING_GAMES:
    case GETTING_GAME:
    case UPDATING_GAME:
    case GETTING_NEXT_PAGE:
    case DELETING_GAME:
    case CLONNING_GAME:
    case GETTING_ALL_GAME_BACKGROUND_IMAGE:
    case GETTING_ALL_GAME_CHARACTER_IMAGE:
      return state
        .set('isLoading', true)

    case CLONNING_GAME_OK:
      return state
        .update('games', games => games.merge(action.clonned))
        .set('isLoading', false)

    case DELETING_GAME_OK:
      return state
        .update('games', games => games.delete(action.gameId))
        .set('isLoading', false)

    case GETTING_FILTERED_GAMES:
      return state
        .update('games', games => games.clear())
        .set('isLoading', true)

    case GETTING_GAME_OK:
    case GETTING_GAME_ASSIGNMENTS_OK:
    case UPDATING_GAME_OK:
    case CREATING_GAME_OK:
    case CREATING_GAME_TEMPLATE_RESULT_OK:
    case GETTING_ALL_GAME_BACKGROUND_IMAGE_OK:
    case GETTING_ALL_GAME_CHARACTER_IMAGE_OK:
      return state
        .update('games', games => games.merge(action.games))
        .update('backgroundImages', backgroundImages => backgroundImages.merge(action.backgroundImages))
        .update('characterImages', characterImages => characterImages.merge(action.characterImages))
        .set('isLoading', false)

    case GETTING_GAMES_OK:
    case GETTING_NEXT_PAGE_OK:
    case GETTING_FILTERED_GAMES_OK:
    case GETTING_SELF_EDUCATION_GAMES_OK:
      return state
        .update('games', games => games.clear().merge(action.games).sort(
          (item1, item2) => item1.get('title').localeCompare(item2.get('title'))
        )
        )
        .set('nextPage', action.nextPage)
        .set('prevPage', action.prevPage)
        .set('perPage', action.perPage)
        .set('total', action.total)
        .set('page', action.page)
        .set('totalPages', action.totalPages)
        .set('isLoading', false)

    case GETTING_GAMES_ERROR:
    case GETTING_ALL_GAME_BACKGROUND_IMAGE_ERROR:
    case GETTING_ALL_GAME_CHARACTER_IMAGE_ERROR:
      return state
        .set('errorMessage', action.errorMessage)

    case SELECT_GAME:
      return state
        .update('selected', selected => selected.merge(action.game))
        .update('games', games => games.delete(action.gameId))

    case CANCEL_SELECTION:
      return state
        .update('games', selected => selected.merge(action.game))
        .update('selected', games => games.delete(action.gameId))

    case CANCEL_SELECTION_ALL:
      return state
        .update('games', games => games.merge(action.selected))
        .update('selected', selected => selected.clear())

    default:
      return state
  }
}
