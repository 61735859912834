import methods from '../apiMethods'

export function getLoggedInUser() {
    return methods.get('/users/me/')
}

export function offHints(data) {
    return methods.patch('/users/me/', data)
}
export function onHints(data) {
    // return methods.patch('/users/me/', {show_hints: data})
}
// onHints()
export function authenticate(credentials) {
    return methods.post('/token-auth/', credentials)
}

export function external_authenticate(url, credentials) {
    return methods.post(url, credentials, true)
}

export function registrate(payload) {
    return methods.post('/double_create/', payload)
}

export function checkKey(key) {
    return methods.post('/key/check/', key)
}

export function sendBugs(message, email) {
    return methods.post('/bug_report/', {message, email})
}

export function sendConfInfo(password, key) {
    return methods.post('/pass_confirm/', { password, key })
}

export function changeDirectory(id, data) {
    return methods.patch(`/users/${id}/`, data)
}

export function resetPassword(payload) {
    return methods.post('/reset/', payload)
}

export function getSettings() {
    return methods.get('/settings/')
}

export function passwordReset(password, key) {
    return methods.post(
      `/password_reset/${key}/`,
      {
        new_password1: password,
        new_password2: password
      }
  )
}

export function getInfo() {
    return methods.get('/info/')
}

export function getPlans(){
    return methods.get('/billing/plan/')
}

export function getBillingHistory(){
    return methods.get('/billing/history/')
}

export function billingCharge({currentPlan, billingData, billingPeriod}){
    const data = {
        plan: currentPlan,
        billing_data: billingData,
        period: billingPeriod
    }

    return methods.post('/billing/charge/', data)
}


export function getCompanyBilling(){
    return methods.get('/billing/')
}

export function sendContact(){
    return methods.get('/contact/')
}

