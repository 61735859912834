import React from "react";
import { Layout, Menu } from "antd";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;

const userLocale = window.localStorage.getItem("i18nextLng");

const MenuComponent = ({ t }) => (
  <Menu mode="horizontal">
    <Menu.Item key="1">
      <a
        href={
          userLocale === "en"
            ? "https://www.iubenda.com/terms-and-conditions/38088118"
            : "https://www.iubenda.com/polozhyeniya-i-usloviya/69526985"
        }
      >
        {t("terms of use")}
      </a>
    </Menu.Item>
    <Menu.Item key="2">
      <a
        href={
          userLocale === "en"
            ? "https://www.iubenda.com/privacy-policy/38088118"
            : "https://www.iubenda.com/privacy-policy/69526985"
        }
      >
        {t("privacy policy")}
      </a>
    </Menu.Item>
    <Menu.Item key="3">
      <a href="mailto:support@quickskills.pro?subject=I need a help">
        {t("support")}
      </a>
    </Menu.Item>
    <Menu.Item key="4">
      <a href="https://quickskills.pro/contacts">{t("contact")}</a>
    </Menu.Item>
  </Menu>
);

const FooterComponent = (props) => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <Footer style={{ textAlign: "center" }}>
      <MenuComponent t={t} />
      <span>
        Copyright &copy; {year} Quickskills |{" "}
        <a
          href={
            userLocale === "en"
              ? "https://www.iubenda.com/privacy-policy/38088118"
              : "https://www.iubenda.com/privacy-policy/69526985"
          }
        >
          {t("privacy policy")}
        </a>
      </span>
    </Footer>
  );
};

export default FooterComponent;
