import React, { createContext, useContext, useState } from 'react'

export const ModalContext = createContext();
export const useModal = () => useContext(ModalContext);

export default function ModalProvider ({ children }) {
    const [currentModal, setCurrentModal] = useState(0);
    const changePositionModal = () => setCurrentModal(currentModal + 1);

    const setCurrentPositionPopup = (position) => {
            if (typeof position === "number") {
                setCurrentModal(position)
            }};

    return (
        <ModalContext.Provider value={{
            currentModal, changePositionModal, setCurrentPositionPopup
        }}>
            { children }
        </ModalContext.Provider>
    )
}