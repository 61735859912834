import { fromJS } from 'immutable'

import {
    CREATE_ORGANIZATION_ERROR,
    SUBMIT_ORGANIZATION_EDIT_OK,
    SUBMIT_ORGANIZATION_EDIT_ERROR
} from '../../OrgStructure/modules/organizations'

import {
    UPDATE_DIVISION_OK,
    UPDATE_DIVISION_ERROR,
    CREATE_DIVISION_OK,
    CREATE_DIVISION_ERROR,
    DELETE_DIVISION_OK
} from '../../OrgStructure/modules/divisions'

import {
    UPDATE_POSITION_OK,
    UPDATE_POSITION_ERROR,
    DELETE_POSITION_OK,
    CREATE_POSITION_OK
} from '../../OrgStructure/modules/positions'

import {
    SUBMIT_EDIT_OK,
    SUBMIT_EDIT_ERROR
} from '../../Roles/modules/'

import {
    CREATING_GAME_ASSIGNMENTS_OK,
    CREATING_GAME_ASSIGNMENTS_ERROR,
    CREATING_GAME_ASSIGNMENTS_ERR
} from '../../Training/modules/assignments'

import {
  UPDATING_GAME_OK,
  UPDATING_GAME_ERROR,
  CLONNING_GAME,
  CLONNING_GAME_OK,
  CLONNING_GAME_ERROR,
  DELETING_GAME_OK,
  CREATING_GAME_OK,
  CREATING_GAME_ERROR,
  DELETING_GAME_ERROR,
  CREATING_GAME_TEMPLATE_RESULT_OK,
  CREATING_GAME_TEMPLATE_RESULT_ERROR
} from '../../Training/modules/games'

import {
    UPDATE_USER_OK,
    UPDATE_USER_ERROR,
    CREATE_USER_OK,
    CREATE_USER_ERROR,
    UPDATE_USERS_OK,
    UPDATE_USER_AVATAR_OK
} from '../../Users/modules/'

import {
    SENDING_SELECTED_ANSWER_ERROR,
    STARTING_TEST_RUN_ERROR,
    GETTING_GAME_WARNING,
    GETTING_GAME_ERROR
} from '../../Gameplay/modules/'

import {
    BILLING_CHARGE_OK,
} from '../../Main/modules/'

const ADD_NOTIFICATION = 'notifier/ADD_NOTIFICATION'
const MARK_AS_SHOWED = 'notifier/MARK_AS_SHOWED'

const initialState = fromJS({
    notification: {}
})

export const addNotification = notification => ({ type: ADD_NOTIFICATION, notification })
export const markAsShowed = () => dispatch => dispatch({ type: MARK_AS_SHOWED })

export default function notifier(state = initialState, action = null) {
    switch (action.type) {
      case BILLING_CHARGE_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "Transaction successful!",
            message: "Thank you for shopping!",
          })
        );
      case UPDATE_USERS_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "users have been edited",
            message: "we save changes",
          })
        );

      case DELETING_GAME_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "game deleted",
            message: "we save changes",
          })
        );
      case CREATING_GAME_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "game created",
            message: "we save changes",
          })
        );
      case CREATING_GAME_TEMPLATE_RESULT_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "template created",
            message: "we save changes",
          })
        );
      case CREATE_POSITION_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "position created",
            message: " ",
          })
        );

      case DELETE_POSITION_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "position deleted",
            message: " ",
          })
        );

      case DELETE_DIVISION_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "subdivision deleted",
            message: " ",
          })
        );

      case CREATE_DIVISION_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "subdivision created",
            message: " ",
          })
        );

      case CREATE_USER_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "user created",
            message: " ",
          })
        );

      case CLONNING_GAME:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "info",
            title: "cloning game",
            message: "if game big",
          })
        );

      case CLONNING_GAME_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "game cloned",
            message: "created second game",
          })
        );

      case UPDATE_USER_AVATAR_OK:
      case UPDATE_USER_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "user edited",
            message: "we save changes",
          })
        );

      case UPDATE_POSITION_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "post edited",
            message: "we save changes",
          })
        );

      case UPDATE_DIVISION_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "subdivision edited",
            message: "we save changes",
          })
        );

      case UPDATING_GAME_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "game edited",
            message: "we save changes",
          })
        );

      case CREATING_GAME_ASSIGNMENTS_OK:
        console.log('STATE', state)
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "training assigned",
            message: `Назначено игр — ${action.assigned.length} из ${
              action.assigned.length + action.unassigned.length
            }`,
          })
        );

      case SUBMIT_EDIT_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "role edited",
            message: "we save changes",
          })
        );

      case SUBMIT_ORGANIZATION_EDIT_OK:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "success",
            title: "org edited",
            message: "we save changes",
          })
        );

      case GETTING_GAME_WARNING:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "warning",
            title: "warning",
            message: action.message,
          })
        );
      case CREATE_USER_ERROR:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "error",
            title: "err",
            message: action.message,
          })
        );

      case CREATING_GAME_ASSIGNMENTS_ERR:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "error",
            title: "Ошибка!",
            message: "Не удалось назначить курс",
          })
        );

      case CREATE_ORGANIZATION_ERROR:
      case SUBMIT_ORGANIZATION_EDIT_ERROR:
      case SUBMIT_EDIT_ERROR:
      case UPDATING_GAME_ERROR:
      case CREATING_GAME_ASSIGNMENTS_ERROR:
      case UPDATE_DIVISION_ERROR:
      case UPDATE_POSITION_ERROR:
      case UPDATE_USER_ERROR:
      case SENDING_SELECTED_ANSWER_ERROR:
      case CLONNING_GAME_ERROR:
      case STARTING_TEST_RUN_ERROR:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "error",
            title: "Ошибка!",
            message: "Вы не можете начать данную игру",
            timeOut: 0,
            extendedTimeOut: 0,
          })
        );

      case CREATE_DIVISION_ERROR:
      case CREATING_GAME_ERROR:
      case DELETING_GAME_ERROR:
      case GETTING_GAME_ERROR:
      case CREATING_GAME_TEMPLATE_RESULT_ERROR:
        return state.update("notification", (notification) =>
          notification.merge({
            type: "error",
            title: "Ошибка!",
            message: action.message,
            timeOut: 0,
            extendedTimeOut: 0,
          })
        );

      case ADD_NOTIFICATION:
        return state.update("notification", (notification) =>
          notification.merge(action.notification)
        );

      case MARK_AS_SHOWED:
        return state.update("notification", (notification) =>
          notification.clear()
        );

      default:
        return state;
    }
}
