import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import i18n from "../../i18n";
import { useModal } from "./ModalProvider";
import { history } from '../../history'
import {changeRole} from '../modules/'

export const IntroGuid = ({showModal, toggleModal, dispatch}) => {
    const [show, setShow] = useState(showModal);
    const handleClose = () => {
        setShow(false);
        localStorage.setItem('alreadyVisited', 0)
    }

    const { changePositionModal } = useModal();

    const handleStartGuide = () => {
        setShow(false)
        localStorage.setItem('inAdminRole', true)
        localStorage.setItem('alreadyVisited', JSON.stringify({game: 0, group: 0, group_row: 0, users: 0}))
        changePositionModal();
        dispatch(changeRole())
        history.push('/administrator/dashboard')
    }

    return (
        <Modal show={show} onHide={handleClose} size='lg' style={{ height: '100%' }} className='inmodal welcome-modal d-flex align-items-center'>
            <Modal.Body>
                <div className="d-flex align-items-center">
                    <div className="modal-content-left-side">
                        <div className="d-flex align-items-center">
                            <img className="col-4 mr-5" src="../../Bear.png" alt="bear"/>
                            <div className="text-left col-6">
                                <h1 className="text-dark mb-4" style={{ fontSize: '1.5em' }}>{i18n.t('welcome')} QuickSkills!</h1>
                                <p className="mb-4" style={{ fontSize: '0.9em' }}>{i18n.t('pre-guid description')}</p>
                                <div className="mt-3">
                                    <button onClick={handleStartGuide} className='btn btn-lg modal-button btn-primary mr-2'>{i18n.t('btn start guide')}</button>
                                    <button onClick={handleClose} className='btn btn-lg modal-button' style={{color: '#4882FC'}}>{i18n.t('skip')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
