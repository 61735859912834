import * as api from '../api'

import { fromJS } from 'immutable'

const GETTING_REPORT_DATA = 'reports/gamesReport/GETTING_REPORT_DATA'
const GETTING_REPORT_DATA_OK = 'reports/gamesReport/GETTING_REPORT_DATA_OK'

const initialState = fromJS({
    allAttempts: [],
    page: 1,
    perPage: 0,
    total: 0,
    totalPages: 0,
    isLoading: false
})

export function getGamesStatistics(settings) {
    return dispatch => {
        dispatch({ type: GETTING_REPORT_DATA })

        return api.getGamesStatistics(settings)
            .then(({ data }) => {
                return dispatch({
                    type: GETTING_REPORT_DATA_OK,
                    allAttempts: data.results,
                    page: data.page,
                    perPage: data.per_page,
                    total: data.total,
                    totalPages: data.total_pages,
                })
            })
    }
}

export default function gamesReport(state = initialState, action = null) {
    switch (action.type) {
        case GETTING_REPORT_DATA:
            return state
                .set('isLoading', true)

        case GETTING_REPORT_DATA_OK:
            return state
                .update('allAttempts', allAttempts => allAttempts.clear().merge(action.allAttempts))
                .set('page', action.page)
                .set('perPage', action.perPage)
                .set('total', action.total)
                .set('totalPages', action.totalPages)
                .set('isLoading', false)

        default:
            return state
    }
}
