import { combineReducers } from 'redux-immutable'

import organizations from './organizations'
import divisions from './divisions'
import positions from './positions'
import tree from './tree'

const orgStructure = combineReducers({
    organizations,
    divisions,
    positions,
    tree
})

export default orgStructure
