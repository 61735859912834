import { combineReducers } from "redux";
import * as api from "../../Training/api";
import * as groupsApi from "../api";

const GETTING_GROUPS = "GETTING_GROUPS";
const GETTING_GROUPS_OK = "GETTING_GROUPS_OK";
const GETTING_GROUPS_ERROR = "GETTING_GROUPS_ERROR";
const GETTING_GROUP = "GETTING_GROUP";
const GETTING_GROUP_OK = "GETTING_GROUP_OK";
const GETTING_GROUP_ERROR = "GETTING_GROUP_ERROR";
const CREATING_GROUP = "CREATING_GROUP";
const CREATING_GROUP_OK = "CREATING_GROUP_OK";
const CREATING_GROUP_ERROR = "CREATING_GROUP_ERROR";
const GETTING_GAMES = "GETTING_GAMES";
const GETTING_GAMES_OK = "GETTING_GAMES_OK";
const GETTING_GAMES_ERROR = "GETTING_GAMES_ERROR";
const SELECT_GAME = "SELECT_GAME";
const CANCEL_SELECTION = "CANCEL_SELECTION";
const CANCEL_SELECTION_ALL = "CANCEL_SELECTION_ALL";

const initialState = {
  groups: [],
  group: {},
  groupsPagination: {
    page: 1,
    perPage: 0,
    total: 0,
    totalPages: 0,
  },
  games: [],
  gamesPagination: {
    page: 1,
    perPage: 0,
    total: 0,
    totalPages: 0,
  },
  selected: [],
  isLoading: false,
  errorMessage: "",
};

export function getAllGroups({ page = 1 } = {}) {
  return (dispatch) => {
    dispatch({ type: GETTING_GROUPS });

    return groupsApi
      .getGroups({
        page: page,
      })
      .then(
        (response) => {
          dispatch({
            type: GETTING_GROUPS_OK,
            groups: response.data.results,
            groupsPagination: {
              ...initialState.groupsPagination,
              page: response.data.page,
              perPage: response.data.per_page,
              total: response.data.total,
              totalPages: response.data.total_pages,
            },
          });
        },
        (err) =>
          dispatch({
            type: GETTING_GROUPS_ERROR,
            errorMessage: err.response.data,
          })
      );
  };
}

export function getGroup(groupId) {
  return (dispatch) => {
    dispatch({ type: GETTING_GROUP });

    return groupsApi
      .getGroup(groupId)
      .then(
        (response) => {
          dispatch({
            type: GETTING_GROUP_OK,
            group: response.data,
          });
        },
        (err) =>
          dispatch({
            type: GETTING_GROUP_ERROR,
            errorMessage: err.response.data,
          })
      );
  };
}

export function createGroup(name, description) {
  return (dispatch) => {
    dispatch({ type: CREATING_GROUP });

    return groupsApi.createGroup(name, description).then(
      (response) => {
        dispatch({
          type: CREATING_GROUP_OK,
          group: response.data,
        });
      },
      (err) =>
        dispatch({
          type: CREATING_GROUP_ERROR,
          errorMessage: err.response.data,
        })
    );
  };
}

export function getAllGames({
  page = 1,
  isPublished,
  searchQuery,
  selfAssigned,
  compact,
  showDemoGames,
  excludeGroup,
} = {}) {
  return (dispatch) => {
    dispatch({ type: GETTING_GAMES });

    return api
      .getAllGames({
        page: page,
        compact: compact,
        isPublished: isPublished,
        searchQuery: searchQuery,
        selfAssigned: selfAssigned,
        showDemoGames: showDemoGames,
        excludeGroup: excludeGroup,
      })
      .then(
        (response) => {
          dispatch({
            type: GETTING_GAMES_OK,
            games: response.data.results,
            gamesPagination: {
              ...initialState.gamesPagination,
              page: response.data.page,
              perPage: response.data.per_page,
              total: response.data.total,
              totalPages: response.data.total_pages,
            },
          });
        },
        (err) =>
          dispatch({
            type: GETTING_GAMES_ERROR,
            errorMessage: err.response.data,
          })
      );
  };
}

export function selectGame(game) {
  return (dispatch) => {
    return dispatch({
      type: SELECT_GAME,
      game,
      gameId: game.id,
    });
  };
}

export function cancelSelection(game) {
  return (dispatch) => {
    return dispatch({
      type: CANCEL_SELECTION,
      game,
      gameId: game.id,
    });
  };
}

export function cancelSelectionAll() {
  return (dispatch) => {
    return dispatch({ type: CANCEL_SELECTION_ALL });
  };
}

function groupsReducer(state = initialState, action = null) {
  switch (action.type) {
    case GETTING_GROUPS:
      return {
        ...state,
        isLoading: true,
      };
    case GETTING_GROUPS_OK:
      return {
        ...state,
        isLoading: false,
        groups: action.groups,
        groupsPagination: action.groupsPagination,
      };
    case GETTING_GROUPS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case GETTING_GROUP:
      return {
        ...state,
        isLoading: true,
      };
    case GETTING_GROUP_OK:
      return {
        ...state,
        isLoading: false,
        group: action.group,
      };
    case GETTING_GROUP_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case CREATING_GROUP:
      return {
        ...state,
        isLoading: true,
      };
    case CREATING_GROUP_OK:
      return {
        ...state,
        isLoading: false,
        group: action.group,
        groups: [action.group, ...state.groups]
      };
    case CREATING_GROUP_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case GETTING_GAMES:
      return {
        ...state,
        isLoading: true,
      };
    case GETTING_GAMES_OK:
      const selectedGameIds = state.selected.map((game) => game.id);
      const filteredGames = action.games.filter(
        (game) => !selectedGameIds.includes(game.id)
      );
      return {
        ...state,
        isLoading: false,
        games: filteredGames,
        gamesPagination: action.gamesPagination,
      };
    case GETTING_GAMES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case SELECT_GAME:
      return {
        ...state,
        selected: [...state.selected, action.game],
        games: state.games.filter((game) => game.id !== action.game.id),
      };
    case CANCEL_SELECTION:
      return {
        ...state,
        games: [...state.games, action.game],
        selected: state.selected.filter((game) => game.id !== action.game.id),
      };
    case CANCEL_SELECTION_ALL:
      return {
        ...state,
        selected: [],
      };

    default:
      return state;
  }
}

const groups = combineReducers({
  groupsReducer,
});

export default groups;
