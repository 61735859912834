import React from "react";
import { Layout, Row, Col, Typography, Divider, Image, Space } from "antd";
import { Link } from "react-router-dom";

import LOGO from "../../assets/img/quickskills-logo-2021.svg";

const { Header } = Layout;
const { Text } = Typography;

const HeaderComponent = (props) => {
  const { title, question, routeTitle, route } = props.params;
  return (
    <Header>
      <Col className="header-left-col">
        <Row justify="center">
          <div className="logo">
            <Image src={LOGO} preview={false} />
          </div>
          <Space>
            <Divider type="vertical" className="modified-divider" />
          </Space>
        </Row>
      </Col>
      <Col flex="auto" className="header-right-col">
        <Row>
          <Col flex="1">
            <Text>{title}</Text>
          </Col>
          <Col flex="0 1 auto">
            <Text>{question}</Text>
            <Link to={`/${route}`}> {routeTitle}</Link>
          </Col>
        </Row>
      </Col>
    </Header>
  );
};

export default HeaderComponent;
