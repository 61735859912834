import methods from '../apiMethods'

export function getSettings() {
    return methods.get('/settings/')
}
export function postLanguage(len) {
    return methods.post('/settings/', len)
}
export function sendBitrixUser(lead) {
    return methods.post('/create_lead/', lead)
}
export function getPlans() {
    return methods.get('/sub_plans/')
}

//Maybe this will help with create saas with payWidget
// export function payWidget(instId, userId, domain) {
//     const data = {
//       userId,
//       instId,
//       domain
//     }
//     var wayforpay = new Wayforpay();
//           wayforpay.run({
//               "orderReference":ok,
//               "merchantAccount":"www_quickskills_pro",
//               "merchantDomainName":"quickskills.pro",
//               "merchantTransactionSecureType": "AUTO",
//               "orderDate": Date.now(),
//               "status":"accept",
//               "amount":"1.00",
//               "currency":"UAH",
//               "productName[]": ["test"],
//               "productPrice[]": ["1"],
//               "productCount[]": ['1'],
//               "clientFirstName":"Bulba",
//               "clientLastName":"Taras",
//               "clientCountry":"UA",
//               // "merchantSignature":md5('www_quickskills_pro', 'quickskills.pro', ok, Date.now(), "1.00", 'UAH', 'test', '1', "1.00", '44601c20c51df18b0006a01f66bb1dc92b7f5852'),
//               requestType: "VERIFY"
//               },
//               function (response) {
//                   // on approved
//               },
//               function (response) {
//                   // on declined
//               },
//               function (response) {
//                   // on pending or in processing
//               }
//           );
//
//       }
// let data = {user: '1', domain_prefix: 'rswgrfvfdss', instance_name: 'rswgrfvfdss', subscription_plan: '3', pk: '1'}
// let json = JSON.stringify(data)
//   methods.post(`/instance_create/`, json)
//       .then(res => console.log(res.data))
//       .then(methods.get(`/generate_user/${user.id}/`))
//       .then(
//           let wayforpay = new Wayforpay()
//             wayforpay.run(
//             function (response) {
//                 approve("myOrder1284");
//               },
//             function (response) {
//                 declined("myOrder1284");
//               },
//             function (response) {
//                 pending(id);
//               }
//             )
//           )
