import * as api from '../api'
import { fromJS } from 'immutable'

import { GETTING_DIVISION_OK, DELETE_DIVISION_OK } from './divisions'
import { GET_ORGANIZATION_OK } from './organizations'
import { GET_EMPLOYEE_OK, GET_EMPLOYEES_OK } from '../../Users/modules/'
import { LOADING_ME_OK } from '../../Main/modules/'
import { GETTING_GAME_ASSIGNMENTS_OK } from '../../Training/modules/assignments'

import {
    normalizePositions,
    normalizePosition
} from '../normalizers/'

export const GET_POSITIONS_OK = 'orgStructure/positions/GET_POSITIONS_OK'
export const GET_POSITION_OK = 'orgStructure/positions/GET_POSITION_OK'
export const UPDATE_POSITION_OK = 'orgStructure/positions/UPDATE_POSITION_OK'
export const UPDATE_POSITION_ERROR = 'orgStructure/positions/UPDATE_POSITION_ERROR'
export const CREATE_POSITION_OK = 'orgStructure/positions/CREATE_POSITION_OK'
export const DELETE_POSITION_OK = 'orgStructure/positions/DELETE_POSITION_OK'

const GET_POSITIONS = 'orgStructure/positions/GET_POSITIONS'
const GET_POSITION = 'orgStructure/positions/GET_POSITION'
const CREATE_POSITION = 'orgStructure/positions/CREATE_POSITION'
const UPDATE_POSITION = 'orgStructure/positions/UPDATE_POSITION'
const DELETE_POSITION = 'orgStructure/positions/DELETE_POSITION'
const BULK_UPDATE_POSITIONS = 'orgStructure/positions/BULK_UPDATE_POSITIONS'
const BULK_UPDATE_POSITIONS_OK = 'orgStructure/positions/BULK_UPDATE_POSITIONS_OK'

const initialState = fromJS({
    positions: {},
    isLoading: false
})

export function getPosition(posId) {
    return dispatch => {
        dispatch({ type: GET_POSITION })

        return api.getPosition(posId)
            .then(res => normalizePosition(res.data))
            .then(entitities => dispatch({
                type: GET_POSITION_OK,
                ...entitities
            }))
    }
}

export function getPositions({divisionId, organizationId} = {}) {
    return (dispatch) => {
        dispatch({ type: GET_POSITIONS })

        return api.getPositions({divisionId, organizationId})
            .then(res => normalizePositions(res.data.results))
            .then(entities => dispatch({
                type: GET_POSITIONS_OK,
                ...entities
            }))
    }
}

export function createPosition(formValues) {
    return (dispatch, getState) => {
        dispatch({ type: CREATE_POSITION })

        let action = {}

        /**
         * Cleaning up prev position of user if necessary
         */

        if (formValues.user) {
            const prevPositionOfUser = getState().getIn(['orgStructure', 'positions', 'positions']).find(pos => {
                return pos.get('user') === formValues.user
            })

            if (prevPositionOfUser) {
                action = {
                    oldPosition: {
                        [prevPositionOfUser.get('id')]: prevPositionOfUser.set('user', null)
                    }
                }
            }
        }

        const parentType = getState().getIn(['orgStructure', 'tree', 'selectedNodeType'])
        const orgId = getState().getIn(['orgStructure', 'organizations', 'active', 'id'])

        const payload = Object.assign({}, formValues, {
            organization: orgId,
            division: parentType !== 'organization'
                ? getState().getIn(['orgStructure', 'tree', 'selectedNode', 'id'])
                : null
        })

        return api.addPosition(payload)
            .then(res => normalizePosition(res.data))
            .then(entitities => dispatch(Object.assign({}, action, {
                type: CREATE_POSITION_OK,
                ...entitities
            })))
    }
}

export function updatePosition(payload) {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_POSITION })

        let action = {}

        /**
         * Cleaning up prev position of user if necessary
         */

        if (payload.user) {
            const prevPositionOfUser = getState().getIn(['orgStructure', 'positions', 'positions']).find(pos => {
                return pos.get('user') === payload.user
            })

            if (prevPositionOfUser && prevPositionOfUser.get('id') !== payload.id) {
                action = {
                    oldPosition: {
                        [prevPositionOfUser.get('id')]: prevPositionOfUser.set('user', null)
                    }
                }
            }
        }

        return api.updatePosition(payload)
            .then(res => normalizePosition(res.data))
            .then(
                entitities => dispatch(Object.assign({}, action, {
                    type: UPDATE_POSITION_OK,
                    ...entitities
                })),
                err => dispatch({
                    type: UPDATE_POSITION_ERROR,
                    message: err.message
                })
            )
    }
}

export function deletePosition(id) {
    return dispatch => {
        dispatch({ type: DELETE_POSITION })

        return api.deletePosition(id)
            .then(() => dispatch({ type: DELETE_POSITION_OK, id }))
    }
}

export function bulkUpdatePositions(positions) {
    return dispatch => {
        dispatch({ type: BULK_UPDATE_POSITIONS })

        return api.bulkUpdatePositions(positions.toArray())
            .then(() => dispatch({ type: BULK_UPDATE_POSITIONS_OK, positions }))
    }
}

export default function divisions(state = initialState, action = null) {
    switch (action.type) {
    case CREATE_POSITION:
    case BULK_UPDATE_POSITIONS:
    case DELETE_POSITION:
    case GET_POSITIONS:
        return state
            .set('isLoading', true)

    case CREATE_POSITION_OK:
    case UPDATE_POSITION_OK:
        return state
            .update('positions', positions => positions.merge(action.oldPosition))
            .update('positions', positions => positions.merge(action.positions))
            .set('isLoading', false)

    case GET_POSITIONS_OK:
    case GET_POSITION_OK:
    case GETTING_DIVISION_OK:
    case GET_ORGANIZATION_OK:
    case BULK_UPDATE_POSITIONS_OK:
    case GET_EMPLOYEE_OK:
    case LOADING_ME_OK:
    case GET_EMPLOYEES_OK:
    case GETTING_GAME_ASSIGNMENTS_OK:
        return state
            .update('positions', positions => positions.clear().merge(action.positions))
            .set('isLoading', false)

    case DELETE_POSITION_OK:
        return state
            .deleteIn(['positions', action.id])
            .set('isLoading', false)

    case DELETE_DIVISION_OK:
        return state
            .update('positions', positions => positions.map(position => {
                return position.get('division') === action.divId
                    ? position.set('division', null)
                    : position
            }))

    default:
        return state
    }
}
