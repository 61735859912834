import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  changeDirect,
  changeRole,
  getLoggedInUser,
  logOut,
  updateCurrentUser,
} from "../modules/";
import getPermissionsOfUser from "../../Main/selectors/currentUserPermissionsSelector";
import { withRouter } from "react-router-dom";
import { Map } from "immutable";
import Main from "../components/";
import IntroVideo from "../HOC/IntroVideo";

class App extends React.Component {
  state = {
    isModerDirectory: true,
  };

  handleLogOut = () => {
    const { dispatch } = this.props;
    dispatch(logOut());
    this.props.history.push("/login");
  };

  componentDidMount() {
    this.props.dispatch(getLoggedInUser());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      if (
        this.props.user &&
        !this.props.user.get("fired") &&
        nextProps.user.get("fired")
      ) {
        this.handleLogOut();
        return;
      }

      if (!nextProps.user.equals(this.props.currentUser)) {
        nextProps.dispatch(updateCurrentUser(nextProps.user));
      }
    }
  }

  switchRole = () => {
    const { dispatch, inAdminRole, currentUser } = this.props;

    const userId = currentUser.get("id");

    dispatch(changeRole());
    let url = inAdminRole ? "/user/assignments" : "/administrator/dashboard";

    const fields = {
      in_moder_mode: !inAdminRole,
    };

    dispatch(changeDirect(userId, { ...fields }));

    this.props.history.push(url);
    localStorage.setItem("inAdminRole", !inAdminRole);
  };


  render() {
    const {
      location,
      inAdminRole,
      isAdmin,
      children,
      currentUser,
      showHints,
      hideHeader,
      toggleGuideFlags,
      guideFlags,
    } = this.props;

    return (
      <Main
        location={location}
        user={currentUser}
        inAdminRole={inAdminRole}
        isAdmin={isAdmin}
        switchRole={this.switchRole}
        logOut={this.handleLogOut}
        showHints={showHints ? showHints : false}
        hideHeader={hideHeader}
        toggleGuideFlags={toggleGuideFlags}
        guideFlags={guideFlags}
      >
        {children}
      </Main>
    );
  }
}

App.propTypes = {
  user: PropTypes.instanceOf(Map),
  inAdminRole: PropTypes.bool.isRequired,
  existEmail: PropTypes.bool.isRequired,
  currentUserPermissions: PropTypes.instanceOf(Map).isRequired,
};

const mapStateToProps = (state) => {
  const currentUser = state.getIn(["viewer", "currentUser"]);

  const currentId = !currentUser.isEmpty()
    ? currentUser.get("id").toString()
    : "0";

  return {
    user:
      state.getIn(["users", "employees", currentId]) ||
      state.getIn(["users", "selected", currentId]),
    currentUser,
    showHints: state.getIn(["users", "employees", currentId, "show_hints"]),
    inAdminRole: state.getIn(["viewer", "inAdminRole"]),
    isAdmin: state.getIn(["viewer", "isAdmin"]),
    isAuthenticated: state.getIn(["viewer", "isAuthenticated"]),
    userEmail: state.getIn(["viewer", "currentUser", "email"]),
    existEmail: state.getIn(["viewer", "existEmail"]),
    currentUserPermissions: getPermissionsOfUser(state),
    hideHeader: state.getIn(["gameplay", "playing"]),
  };
};

export default withRouter(connect(mapStateToProps)(IntroVideo(App)));
