import { combineReducers } from 'redux-immutable'

import gamesReport from './gamesReport'
import answersReport from './answersReport'

const reports = combineReducers({
    gamesReport,
    answersReport
})

export default reports
