import { fromJS } from 'immutable'

const SELECT_TREE_NODE = 'orgStructure/tree/SELECT_TREE_NODE'

const initialState = fromJS({
    selectedNode: {},
    selectedNodeType: ''
})

export function selectTreeNode(selectedNode, selectedNodeType) {
    return {
        type: SELECT_TREE_NODE,
        payload: {
            selectedNode,
            selectedNodeType
        }
    }
}

export default function tree(state = initialState, action = null) {
    switch (action.type) {
    case SELECT_TREE_NODE:
        return state.merge(action.payload)

    default:
        return state
    }
}
