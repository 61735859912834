import * as api from '../api'
import { assignToRole } from '../../Roles/api'
import { fromJS } from 'immutable'
import { normalizeEmployees, normalizeEmployee } from '../normalizers/'

import { GET_ORGANIZATION_OK } from '../../OrgStructure/modules/organizations'
import { GETTING_DIVISION_OK } from '../../OrgStructure/modules/divisions'
import { DELETING_ROLE_OK } from '../../Roles/modules/'
import { GETTING_GAME_ASSIGNMENTS_OK } from '../../Training/modules/assignments'
import { LOADING_ME_OK } from '../../Main/modules'
import {
    GET_POSITIONS_OK,
    UPDATE_POSITION_OK,
    CREATE_POSITION_OK,
    GET_POSITION_OK
} from '../../OrgStructure/modules/positions'

export const GET_EMPLOYEES_OK = 'users/GET_EMPLOYEES_OK'
export const GET_EMPLOYEE_OK = 'users/GET_EMPLOYEE_OK'
export const GET_NEXT_PAGE_EMPLOYEES_OK = 'users/GET_NEXT_PAGE_EMPLOYEES_OK'
export const UPDATE_USER_OK = 'users/UPDATE_USER_OK'
export const UPDATE_USER_AVATAR_OK = 'users/UPDATE_USER_AVATAR_OK'
export const UPDATE_USER_AVATAR_ERROR = 'users/UPDATE_USER_AVATAR_ERROR'
export const UPDATE_USER_ERROR = 'users/UPDATE_USER_ERROR'
export const CREATE_USER_OK = 'users/CREATE_USER_OK'
export const CREATE_USER_ERROR = 'users/CREATE_USER_ERROR'
export const UPDATE_USERS_OK = 'users/UPDATE_USERS_OK'
export const UPDATE_FIRE_USER_OK = 'users/UPDATE_FIRE_USER_OK'
export const UPDATE_USERS_ERROR = 'users/UPDATE_USERS_ERROR'
export const DELETE_USERS_OK = 'users/DELETE_USERS_OK'
export const DELETE_USERS_ERROR = 'users/DELETE_USERS_OK'

const GET_EMPLOYEES = 'users/GET_EMPLOYEES'
const GET_FILTERED_EMPLOYEES = 'users/GET_FILTERED_EMPLOYEES'
const GET_FILTERED_EMPLOYEES_OK = 'users/GET_FILTERED_EMPLOYEES_OK'
const GET_NEXT_PAGE_EMPLOYEES = 'users/GET_NEXT_PAGE_EMPLOYEES'
const GET_EMPLOYEE = 'users/GET_EMPLOYEE'
const SELECT_USER = 'users/SELECT_USER'
const SELECT_ALL_USERS = 'users/SELECT_ALL_USERS'
const CANCEL_SELECTING_OF_ALL_USERS = 'users/CANCEL_SELECTING_OF_ALL_USERS'
const CREATE_USER = 'users/CREATE_USER'
const UPDATE_USER = 'users/UPDATE_USER'
const UPDATE_USER_AVATAR = 'users/UPDATE_USER_AVATAR'
const UPDATE_USERS = 'users/UPDATE_USERS'
const DELETE_USERS = 'users/DELETE_USERS'

const initialState = fromJS({
    employees: {},
    total: 0,
    page: 1,
    count: 0,
    selected: {},
    nextPage: null,
    prevPage: null,
    perPage: 0,
    totalPages: 0,
    isLoading: false,
    isValid: true,
    validationErrors: {},
    guideFlags: {
        showGuide: false,
        stepShowGuide: false,
    }
})

export function getUser(userId) {
    return dispatch => {
        dispatch({ type: GET_EMPLOYEE })

        return api.getUser(userId)
            .then(res => normalizeEmployee(res.data))
            .then(normalized => dispatch({
                type: GET_EMPLOYEE_OK,
                userKey: normalized.employees[
                    Object.keys(normalized.employees)[0]
                ].id,
                ...normalized,
            }))
    }
}

export function assignRole(newRoleId) {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_USERS })

        const employees = getState().getIn(['users', 'selected'])
            .map(selected => selected.set('role', newRoleId))

        const payload = employees.toArray().map(employee => employee.get('id'))

        return assignToRole(newRoleId, payload)
            .then(
                () => dispatch({
                    type: UPDATE_USERS_OK,
                    employees
                }),
                err => dispatch({
                    type: UPDATE_USERS_ERROR,
                    message: err.message
                })
            )
    }
}

export function updateUser(id, data) {
    return dispatch => {
        dispatch({ type: UPDATE_USER })

        return api.updateUser(id, data)
            .then(res => normalizeEmployee(res.data))
            .then(
                normalized => dispatch({
                    type: UPDATE_USER_OK,
                    ...normalized
                }),
                err => dispatch({
                    type: UPDATE_USER_ERROR,
                    message: err.message
                })
            )
    }
}

export function updateUserAvatar(id, data) {
    return dispatch => {
        dispatch({ type: UPDATE_USER_AVATAR })

        return api.updateAvatarUser(id, data)
            .then(res => normalizeEmployee(res.data))
            .then(
                normalized => dispatch({
                    type: UPDATE_USER_AVATAR_OK,
                    ...normalized
                }),
                err => dispatch({
                    type: UPDATE_USER_AVATAR_ERROR,
                    message: err.message
                })
            )
    }
}

export function fireUsers() {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_USERS })

        const employees = getState()
            .getIn(['users', 'selected'])
            .map(user => user.set('fired', true))

        const payload = employees.toArray().map(user => {
            return {
                id: user.get('id'),
                fired: true
            }
        })

        return api.fireUsers(payload)
            .then(
                () => dispatch({
                    type: UPDATE_FIRE_USER_OK,
                    employees
                }),
                err => dispatch({
                    type: UPDATE_USERS_ERROR,
                    message: err.message
                })
            )
    }
}

export function createUser(formValues) {
    return dispatch => {
        dispatch({ type: CREATE_USER })

        const handleResponse = ({ data }) => {
            const normalized =  normalizeEmployee(data)

            dispatch({
                type: CREATE_USER_OK,
                ...normalized
            })
        }

        const handleError = (message) => dispatch({
            type: CREATE_USER_ERROR,
            message
        })

        return api.createUser(formValues)
            .then(
                res => handleResponse(res),
                error => {
                  const {
                    data
                  } = error.response

                  return handleError(
                    data ? data.non_field_errors[0] : error.message
                  )
                }
            )
    }
}

export function selectAllUsers() {
    return (dispatch, getState) => {
        const employees = getState().getIn(['users', 'employees'])

        const selected = employees.filter(employee => !employee.get('fired'))

        const users = employees.filter(employee => selected.includes(employee))

        dispatch({ type: SELECT_ALL_USERS, selected, users })
    }
}

export function cancelSelection() {
    return dispatch => dispatch({ type: CANCEL_SELECTING_OF_ALL_USERS })
}

export function selectUser(userId, moveFrom, moveTo) {
    return (dispatch, getState) => {
        const user = getState().getIn(['users', moveFrom, userId])

        const action = {
            type: SELECT_USER,
            userId,
            moveFrom,
            moveTo,
            user: {
                [userId]: user
            }
        }

        dispatch(action)
    }
}

export function getFilteredEmployees(settings) {
    return dispatch => {
        dispatch({ type: GET_FILTERED_EMPLOYEES })

        let searchParams = []

        const {
            booleanSelectors,
            valueSelectors,
            searchQuery
        } = settings


        return api.getEmployees()
            .then(res => {
                const normalized = normalizeEmployees(res.data.results)

                dispatch({
                    type: GET_FILTERED_EMPLOYEES_OK,
                    ...normalized,
                    nextPage: res.data.next
                })
            })
    }
}

export function getEmployees(settings) {
    return dispatch => {
        dispatch({ type: GET_EMPLOYEES })

        return api.getAllEmployees(settings)
            .then(res => {
                const normalized = normalizeEmployees(res.data.results)

                dispatch({
                    type: GET_EMPLOYEES_OK,
                    ...normalized,
                    nextPage: res.data.next,
                    prevPage: res.data.prev,
                    total: res.data.total,
                    count: res.data.count,
                    totalPages: res.data.total_pages,
                    perPage: res.data.per_page,
                    page: res.data.page,
                })
            })
    }
}

export function getNextPage() {
    return (dispatch, getState) => {
        dispatch({ type: GET_NEXT_PAGE_EMPLOYEES })

        const nextPage = getState().getIn(['users', 'nextPage'])

        return api.getNextPage(nextPage)
            .then(res => {
                const normalized = normalizeEmployees(res.data.results)

                dispatch({
                    type: GET_NEXT_PAGE_EMPLOYEES_OK,
                    ...normalized,
                    nextPage: res.data.next
                })
            })
    }
}

export default function users (state = initialState, action = null) {
    switch (action.type) {
    case GET_EMPLOYEES:
    case CREATE_USER:
    case DELETE_USERS:
    case UPDATE_USER:
    case UPDATE_USERS:
    case GET_NEXT_PAGE_EMPLOYEES:
        return state.set('isLoading', true)

    case GET_FILTERED_EMPLOYEES:
        return state
            .set('isLoading', true)
            .update('employees', employees => employees.clear())

    case DELETE_USERS_OK:
        return state
            .set('isLoading', false)
            .update('selected', selected => selected.clear())
            .update('counts', counts => counts.merge(action.newCounts))

    case UPDATE_USER_AVATAR:
    case UPDATE_USERS_OK:
        return state
            .set('isLoading', false)
            .update('employees', employees => employees.merge(state.get('selected')))
            .update('selected', selected => selected.clear())
            // .update('selected', selected => selected.merge(action.employees))
    case UPDATE_FIRE_USER_OK:
        return state
            .set('isLoading', false)
            .update('selected', selected => selected.clear())

    case CREATE_USER_OK:
        return state
            .update('employees', employees => employees.merge(action.employees))
            .set('isLoading', false)

    case CREATE_USER_ERROR:
        return state
            .set('isLoading', false)

    case DELETING_ROLE_OK:
        return state
            .update('employees', employees => employees.map(employee => {
                if (employee.get('role') === action.roleId) {
                    return employee.set('role', null)
                }

                return employee
            }))

    case GETTING_DIVISION_OK:
    case GET_POSITIONS_OK:
    case GET_POSITION_OK:
    case GETTING_GAME_ASSIGNMENTS_OK:
    case GET_ORGANIZATION_OK:
    case UPDATE_POSITION_OK:
    case CREATE_POSITION_OK:
        return state
            .update('employees', employees => employees.merge(action.employees))

        case GET_EMPLOYEES_OK:
            return state
                .update('employees', employees => employees.clear().merge(action.employees))
                .set('nextPage', action.nextPage)
                .set('prevPage', action.prevPage)
                .set('perPage', action.perPage)
                .set('total', action.total)
                .set('page', action.page)
                .set('count', action.count)
                .set('totalPages', action.totalPages)
                .set('page', action.page)
                .set('isLoading', false)
                .set('count', action.count)

    case GET_EMPLOYEE_OK:
    case LOADING_ME_OK:
        return state
            .set('isLoading', false)
            .set('userKey', action.userKey)
            .update('employees', employees => employees.merge(action.employees))

    case GET_FILTERED_EMPLOYEES_OK:
    case GET_NEXT_PAGE_EMPLOYEES_OK:
    case UPDATE_USER_OK:
        return state
            .set('isLoading', false)
            .update('employees', employees => employees.merge(action.employees))
            .set('nextPage', action.nextPage)

    case SELECT_ALL_USERS:
        return state
            .update('selected', selected => selected.merge(action.selected))
            .set('employees', action.users)

    case CANCEL_SELECTING_OF_ALL_USERS:
        return state
            .update('employees', employees => employees.merge(state.get('selected')))
            .update('selected', selected => selected.clear())

    case SELECT_USER:
        return state
            .update(action.moveTo, selected => selected.merge(action.user))
            .update(action.moveFrom, from => from.delete(action.userId))

    default:
        return state
    }
}
