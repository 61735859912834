import React from 'react'
import { markAsShowed } from '../modules/'
import { connect } from 'react-redux'
import NotifierComponent from '../containers/index'

class Notifier extends React.Component {
    constructor(props) {
        super(props)
        this.markShow = this.markShow.bind(this)
    }

    markShow() {
        this.props.dispatch(markAsShowed())
    }

    render() {
        const { notification } = this.props
        return (
            <NotifierComponent
                notification={notification}
                markShow={this.markShow}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        notification: state.getIn(['notifier', 'notification'])
    }
}

export default connect(mapStateToProps)(Notifier)
