import { normalize, schema } from 'normalizr'

const permission = new schema.Entity('permissions')

const role = new schema.Entity('roles', {
    permission_groups: [ permission ]
})

export const normalizeRole = response => {
    return normalize(response, role).entities
}
export const normalizeRoles = response => {
    return normalize(response,  [ role ]).entities
}
