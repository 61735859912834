import React from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { withTranslation } from "react-i18next";

const routes = [
  { path: "/administrator/dashboard", breadcrumb: "dashboard" },
  { path: "/administrator/games", breadcrumb: "games" },
  { path: "/administrator/games/:gameId", breadcrumb: "edit_game" },
  { path: "/administrator/organizations", breadcrumb: "all_organizations" },
  { path: "/administrator/organizations/:orgId", breadcrumb: "organization" },
  { path: "/administrator/positions/:posId", breadcrumb: "position" },
  { path: "/administrator/reports/games", breadcrumb: "reports" },
  { path: "/user/profile/", breadcrumb: "profile" },
  { path: "/administrator/billing", breadcrumb: "billing" },
  { path: "/administrator/groups", breadcrumb: "groups" },
  { path: "/administrator/groups/:groupId", breadcrumb: "edit_group" },
  { path: '/administrator/quizzes', breadcrumb: 'quizzes' },
  { path: '/administrator/quizzes/create', breadcrumb: 'create quiz' },
  { path: '/administrator/quizzes/:quizId', breadcrumb: 'quiz properties' },
  { path: '/administrator/quizzes/:quizId/question/:id', breadcrumb: 'edit quiz question' },
  { path: '/administrator/users', breadcrumb: 'employees' },
  { path: '/administrator/users/:userId', breadcrumb: 'edit_profile' },
  { path: '/administrator/roles', breadcrumb: 'all_roles' },
  { path: '/administrator/reports/answers', breadcrumb: 'answers' },
  { path: '/administrator/reports/answers/:gameId', breadcrumb: 'answer' },
];

const Breadcrumbs = ({ t }) => {
  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  return (
    <nav aria-label="breadcrumb" className="breadcrumb-section">
      <ol className="breadcrumb">
        {breadcrumbs[0].breadcrumb.props.children !== "dashboard" &&
          breadcrumbs[0].breadcrumb.props.children !== "profile" && (
            <li className="breadcrumb-item">
              <Link to="/">{t("dashboard")}</Link>
            </li>
          )}
        {breadcrumbs.map(({ match, breadcrumb }) => {
          if (breadcrumbs[0].breadcrumb.props.children !== "profile") {
            return (
              <li key={match.url} className="breadcrumb-item">
                <Link to={`${match.url}`}>
                  {t(`${breadcrumb.props.children}`)}
                </Link>
              </li>
            );
          }
          return null;
        })}
      </ol>
    </nav>
  );
};

export default withTranslation("translations")(Breadcrumbs);
