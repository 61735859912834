import { normalize, schema } from 'normalizr'

// normalize role
const permission = new schema.Entity('permissions')

const role = new schema.Entity('roles', {
    permission_groups: [permission],
})

/**
 * Recursive linking — position ← user ← position
 */

const position = new schema.Entity('positions')

const division = new schema.Entity('divisions', {
    user_position: [position],
})

const organization = new schema.Entity('organizations', {
    divisions: [division],
    user_position: [position],
})

export function normalizeRole(response) {
    return normalize(response, role).entities
}
export function normalizeRoles(response) {
    return normalize(response, [role]).entities
}

export function normalizeOrganizations(response) {
    return normalize(response, [organization]).entities
}

export function normalizeOrganization(response) {
    return normalize(response, organization).entities
}

export function normalizeDivision(response) {
    return normalize(response, division).entities
}

export function normalizePosition(response) {
    return normalize(response, position).entities
}

export function normalizePositions(response) {
    return normalize(response, [position]).entities
}
