import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useAuthState } from "../context";

function isIE() {
  let ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

  return is_ie;
}

const AppRoutes = ({ component: Component, path, isPrivate, isAdmin, ...rest }) => {
  const userDetails = useAuthState();
  return (
    <Route
      path={path}
      render={(props) => {
        if (isPrivate && !Boolean(userDetails.token)) {
          if (isIE()) {
            return (
              <Redirect
                to={{ pathname: "/browser", state: { from: props.location } }}
              />
            );
          } else {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }
        } else {
          if (
            isAdmin &&
            !Boolean(
              userDetails?.user.is_admin || localStorage.getItem("isAdmin")
            )
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/user/assignments",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            return <Component {...props} />;
          }
        }
      }}
      {...rest}
    />
  );
};

export default AppRoutes;
