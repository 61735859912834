import React, { useState } from "react";
import { Layout, Image, Select, Space, Divider } from "antd";
import { Link } from "react-router-dom";
import { CaretDownFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import * as setting_api from "../../Settings/api";
import Hint from "../../Notifier/components/Hints";
import { useModal } from "../HOC/ModalProvider";

import LOGO from "../../styles/img/quickskills-logo-2021.svg";
import Navigation from "./HeaderNavigation";
import GlobalMenu from "./GlobalMenu";

const { Header } = Layout;
const { Option } = Select;

const HeaderComponent = (props) => {
  const {
    logOut,
    showHints,
    isAdmin,
    inAdminRole,
    user,
    switchRole,
    dispatch,
    toggleGuideFlags,
    guideFlags,
  } = props;

  const { t, i18n } = useTranslation();
  const [showHint, toggleHints] = useState(false);
  const [guideButtonClass, setGuideButtonClass] = useState("btn-popup");
  const { currentModal, setCurrentPositionPopup } = useModal();

  const toggleModalHints = () => toggleHints({ showHint: false });

  const togglePopup = () => {
    if (currentModal === 501) {
      setCurrentPositionPopup(0);
      let tempClass = guideButtonClass.replace("btn-popup__shadow", "");
      setGuideButtonClass(tempClass);
    } else {
      setCurrentPositionPopup(501);
      setGuideButtonClass(guideButtonClass + " btn-popup__shadow");
    }
  };

  const language = localStorage.getItem("i18nextLng").split("-")[0];

  function changeLanguage(lng) {
    i18n.changeLanguage(lng);

    setting_api.postLanguage({ language: lng });
  }

  const guide = (
    <div className={guideButtonClass} onClick={togglePopup}>
      <img className="btn-popup-img" src="../../btn-popup.png" alt="Guide" />
    </div>
  );

  const whitelabel = user.get("whitelabel");

  return (
    <Header
      style={{
        backgroundColor:
          (whitelabel && whitelabel.get("top_bar_background")) || "#fff",
      }}
    >
      <Link
        to={isAdmin && inAdminRole && "/administrator/dashboard"}
        className="logo"
      >
        <Image
          src={(whitelabel && whitelabel.get("logo")) || LOGO}
          alt="Quickskills 2.0"
          preview={false}
        />
      </Link>
      <Space>
        <Divider type="vertical" className="modified-divider" />
      </Space>
      <Navigation
        user={user}
        inAdminRole={inAdminRole}
        isAdmin={isAdmin}
        toggleGuideFlags={toggleGuideFlags}
        guideFlags={guideFlags}
      />
      <Space style={{ marginLeft: "auto" }}>
        {inAdminRole && guide}
        <Hint
          showHint={showHint}
          toggleModalHints={toggleModalHints}
          showHints={showHints}
          inAdminRole={inAdminRole}
        />
        <Select
          defaultValue={language}
          suffixIcon={<CaretDownFilled />}
          onChange={changeLanguage}
        >
          <Option value="en">{t("en")}</Option>
          <Option value="ua">{t("ua")}</Option>
          <Option value="ru">{t("ru")}</Option>
        </Select>
        <GlobalMenu
          user={user}
          inAdminRole={inAdminRole}
          isAdmin={isAdmin}
          logOut={logOut}
          switchRole={switchRole}
          dispatch={dispatch}
        />
      </Space>
    </Header>
  );
};

export default HeaderComponent;
