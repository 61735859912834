import methods from '../apiMethods'

export function getGroups({page=1} = {}) {
    return methods.get(`/group/?page=${page}`)
}

export function getAllGroups() {
    return methods.get('/group/all/')
}

export function getGroup(id) {
    return methods.get(`/group/${id}/`)
}

export function createGroup(name, description, period, attempts) {
    const payload = {
        name: name,
        description: description,
        period: period,
        attempts: attempts
    }

    return methods.post('/group/', payload)
}

export function updateGroup(id, name, description, period, attempts) {
    const payload = {
        name: name,
        description: description,
        period: period,
        attempts: attempts,
    }

    return methods.put(`/group/${id}/`, payload)
}

export function deleteGroup(id) {
    return methods.delete(`/group/${id}/`)
}

export function addEmployeesToGroup(id, employeesList) {

    const payload={
        employees: employeesList
    }

    return methods.post(`/group/${id}/employee/`, payload)
}

export function addGamesToGroup(id, gameID, attempts, period) {
    const payload = {
       game: gameID,
       attempts: attempts,
       period: period,
    }

    return methods.post(`/group/${id}/game/`, payload)
}

export function deleteEmployeesFromGroup(id, employeesList) {

    const payload={
        employees: employeesList
    }

    return methods.delete(`/group/${id}/employee/`, payload)
}

export function deleteGamesFromGroup(id, gamesList) {

    const payload={
        games: gamesList
    }

    return methods.delete(`/group/${id}/game/`, payload)
}
