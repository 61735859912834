import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const resources = {
  ua: {
    translations: {
      branding: "Брендування уроку",
      branding_enabled: "Якщо ввімкнено цю опцію, то на кожному екрані уроку буде логотип вашої компанії",
      quiz_is_not_published: "Тест не публікується",
      quiz_is_published: "Тест опублікована",
      multiple_anwers: "Питання з декількома варіантами відповіді",
      "quiz was successfully saved": "Вікторину успішно збережено",
      cards_no_zero: "Мінімум, що потрібно картці, - 0",
      cards_no_hundert: "Максимум, що потрібно картці, - 100",
      cards_dont_connect:
        "Відсотки проходження не збалансовані, будь ласка, приведіть відсотки у відповідність.",
      example: "Приклад",
      "change browser title":
        "Схоже, що ви використовуєте браузер, який ми не повністю підтримуємо.",
      "change browser subtitle":
        "Ми рекомендуємо спробувати використовувати останню версію",
      or: "або",
      earned: "Набрав",
      delete_group_confirm: "Ви впевнені що хочете видалити групу?",
      edit_group: "Редагувати групу",
      "your points": "Ваші бали",
      "max points chart": "Макс. Балів",
      "With Points": "З балами",
      "Without Points": "Без балів",
      maximum: "Максимум",
      "Here you can select any image as a question.":
        "Тут ви можете вибрати будь-яке зображення як питання.",
      "Choose background image": "Виберіть фонове зображення",
      Result: "Результат",
      "Gallery of backgrounds": "Галерея фонів",
      "hide footer": "Сховати нижній колонтитул",
      "show footer": "Показати нижній колонтитул",
      advanced: "Розширений",
      "not started": "Не розпочато",
      "game over": "Курс пройден",
      "try again btn": "Спробувати ще",
      complete: "Закінчити",
      my_best_score: "Моя найкраща спроба",
      "max-points-in-game": "Максимум",
      "your result": "твiй результат",
      "congratulations zero": "Вiтаємо",
      "congratulations text zero":
        "Ти пройшов до кінця, сподіваємося тобі було цікаво!",
      "congratulations first": "Вiтаємо",
      "congratulations text first":
        "Як круто, що ти працюєш у нашій компанії! Мабуть, ти - улюбленець наших клієнтів!",
      "congratulations second": "Добре!",
      "congratulations text second":
        "Непоганий результат. Але треба ще трохи попрацювати над своїми знаннями. Якщо ти хочеш покращити результат, то радимо пройти ще раз.",
      "congratulations third": "Хм...",
      "congratulations text third":
        'Ні, так не піде. Клікай на кнопку "Спробувати ще", уважно виконай завдання, і зустрінемося знову.',
      groups: "Групи",
      "create group": "Створити групу",
      "delete group": "Видалити групу",
      "deleting group": "Видалення групи",
      "first step": "Крок 1 - Вибір курсу",
      "second step": "Крок 2 - Налаштування",
      "unique name group": "Унікальна назва групи",
      "number employees": "Кількість співробітників",
      "number games": "Кількість курсів",
      "add game": "Додати курс",
      next: "Далі",
      promo: "Промо",
      "Choose language": "Виберіть мову",
      no_promo: "Немає промо",
      promo_text: "Відключити стартовий екран і підсумковий звіт",
      "show demo games": "Показуйте демонстраційні курсу",
      "hide demo games": "Сховати демо-курси",
      back_to_organization: "Повернутися до організації",
      employees: "Співробітники",
      press_to_continue: "Натисніть «Далі», щоб продовжити.",
      offer_simple:
        "Ми зробили наш сервіс простим і доступним для всіх. Пропонуємо ознайомитися з його основними можливостями.",
      platform_desc: "платформа для навчання за допомогою куріы та симуляцій.",
      "name game": "Ім'я",
      help_link: "https://site.quickskills.pro/how-to",
      intro_video_id: "pr4n8tir6c",
      we_happy_to_see_you: "Ми раді бачити Вас нашим користувачем!",
      "If the game is public and published you can embed this game on any website.":
        "Якщо курс є загальнодоступною та опублікованою, ви можете вставити цей курс на будь-який веб-сайт.",
      Public: "Публічний",
      "Not public": "Ні публічний",
      Skill: "Уміння",
      Competencies: "Компетенції",
      "This game develops skills": "Цей курс розвиває уміння",
      game_has_no_goods: "У курсі немає продажів товарів",
      apply: "Застосувати",
      "position created": "Позиція створена",
      welcome: "Ласкаво просимо в",
      "if employee listed as a head":
        "Якщо ви хочете, щоб співробітник на цій посаді значився як керівник",
      emotion: "Емоція",
      domain: "домен",
      "settings org": "Налаштування організації",
      "short name": "Скорочена назва",
      deselect: "Скасувати вибір",
      "users have been edited": "Користувачі відредаговані",
      "enough rights":
        "У вас не вистачає прав для відвідування даного розділу сайту",
      registration: "Реєстрація",
      'registration subtitle': "Ви отримаєте доступ до всіх функцій Quickskills преміум-класу на 14 днів. Кращі в галузі ресурси для розвитку вашого бізнес-навчання. Ніяких зобов'язань, кредитна картка не потрібно.",
      "subdivision created": "Підрозділ створено",
      "Number of users - up to 500, 50 Gb - disk space, backup once per week":
        "Кількість користувачів - до 500, 50 Гб - дискового простору, бекапи 1 раз на тиждень **",
      expiration_date: "Термін придатності",
      "time of passage": "Час проходження",
      "obligatory field": 'Обов"язкове поле',
      "surname limit": "Прізвище не повинне перевищувати 20 символів",
      "editing roles": "Редагування ролей",
      "not edit users": "У вас немає прав на редагування користувачів",
      "user created": "Створено користувача",
      "own game creation and assignation":
        "Створення та призначення своїх курсів",
      "game created": "Курс створенна",
      "must enter its name":
        "Для додавання структурного підрозділу необхідно ввести його назву",
      "6 month": "6 місяців",
      dashboard: "Дешборд",
      "average emotions": "Усереднені емоції клієнта",
      "forget employees": "Не забудьте призначити співробітника на посаду",
      "Rent a server QS - Medium 6 month": "Medium 6 місяців",
      "send email password":
        "Ми відправимо вам лист з посиланням, яке буде дійсне 24 години",
      "have an account": "У вас немає облікового запису?",
      "already have an account": "Вже є аккаунт?",
      "add employees": "Додати співробітника",
      user: "Користувач",
      the_domain_name_is_already_in_use_by_another_account:
        "Домен вже використовується іншим користувачем.",
      "not specified role":
        "Ролі не вказані права і тому її не можна призначити",
      "list org": "Список існуючих організацій",
      "employee without a job": "Співробітник залишиться без посади",
      "not specified": "Не вказано",
      "test position": "Тестова посадa",
      "editing users": "Редагування користувачів",
      "trial game": "Пробне проходження",
      march: "Березень",
      "period passage": "Період проходження",
      "lose rights":
        'Користувачі, яким була призначена дана роль, позбудуться пов"язаних з нею прав',
      "game creation and assignation": "Створення та призначення курсів",
      "delete org": "Ви дійсно хочете видалити організацію",
      "not finished": "Не закiнчена",
      "game cloned": "Курс склонован",
      subdivisions: "Підрозділи",
      open_tasks: "Відкритих завдань",
      "attempts all": "Спроб всього",
      optional: "Опціонально",
      search: "Пошук",
      "this description game":
        "Це опис буде бачити той, кого навчають при запуску курсу",
      "add post": "Додання посади",
      en: "Eng",
      continue: "Для продовження введіть дані облікового запису",
      orgstructure: "Оргструктура",
      wrong: "Неправильно введений логін або пароль",
      "information employee": "Інформація про співробітника",
      "position deleted": "Позиція видалена",
      dismissal: "Звільнення",
      "employee as a manager":
        "Якщо ви хочете, щоб співробітник на цій посаді значився як керiвник",
      training: "Навчання",
      "description structural unit": "Опис структурного підрозділу",
      "send letter": "Зареєструйтесь безкоштовно",
      appointment: "За призначенням",
      "login": "Вхiд",
      "login subtitle": "Використовуйте Quickskills для створення курсів, залучення, навчання та адаптації нових співробітників.",
      "log in": "Увійдіть до свого облікового запису",
      "login failed": "Помилка входу",
      "terms of use": "Умови використання",
      "privacy policy": "Політика конфіденційності",
      "support": "Підтримка",
      "contact": "Зв'язатися з нами",
      "and": "i",
      actions: "Дії",
      "number choices": "Кількість виборів",
      all_games: "Всі курси",
      assigned: "Назначена",
      hint: "Пiдсказки",
      search_by_positions: "Пошук за посадою",
      neutral: "Нейтральний",
      "Rent a server QS - Unlim 1 year": "Unlim 1 рік",
      forward: "Вперед",
      "editing organization": "Редагування організаційної структури",
      close: "Закрити",
      "cancel changes": "Вiдмiнити змiни",
      "first name": 'Iм"я',
      "role description": "Опис ролі",
      january: "Січень",
      "score stage": "Бали за компетенціями",
      score: "Бали",
      amount: "Кількість",
      "governing office": "Керуюча посада",
      err: "Помилка!",
      "current rights": "Поточні права",
      surname: "Прізвище",
      "change language": "Змінити мову системи",
      "access error": "Помилка доступу",
      "exist email": "Такий Email вже існує",
      search_by_org: "Пошук по організаціям",
      estimate_tooltip:
        "Орієнтовна тривалість курсу, без урахування відео, аудіо і т.п.",
      february: "Лютий",
      "Rent a server QS - Medium 1 year": "Оренда сервера QS - Medium 1 рік",
      sad: "Сумний",
      "description limit": "Опис не повинен перевищувати 100 символів",
      "select all": "Обрати всiх",
      appointments: "До призначень",
      "1 year": "1 рік",
      "return in organization": "Повернутися в організацію",
      "delete org main": "Видалити організацію",
      "delete unit main": "Видалити підрозділ",
      "best try": "За призначенням",
      back: "Назад",
      "Rent a server QS - Unlim 6 month": "Unlim 6 місяців",
      please_login: "Будь ласка, увійдіть, щоб продовжити",
      "email unique":
        "Адреса електронної пошти повинна бути унікальна і правильна",
      "creating game": "Створення курсу",
      "description game": "Опис курсу",
      total: "Всього",
      game_has_audio: "У курсі є аудіозаписи",
      game_self_assigned: "За самопризначеної",
      "head of": "Kерівник",
      "list games": "До списку курсів",
      "code importing data":
        "Даний код буде використовуватися при імпорті та експорті даних",
      october: "Жовтень",
      this_domain_name_already_exists: "Такой Domain name уже существует",
      "switch active role": "Можливо, вам доведеться переключити активну роль",
      "clone game": "Клонувати курс",
      "right response": "Правильну відповідь",
      "maximum points": "Максимальні бали",
      further: "Далi",
      "user not be able pass game":
        "Користувач не зможе проходити призначений курс.",
      status: "Статус",
      "game assignments save value":
        "При цьому, видалення не торкнеться тих призначень, які проходяться в даний момент.",
      "for assign game": "Для призначення курсу",
      needs_attention: "Потрібно уваги",
      enter: "Увійти",
      "role description unique":
        "Опис повинен бути максимально лаконічним і змістовним",
      "view roles": "Перегляд ролей",
      "add org": "Додати організацію",
      "subdivision deleted": "Підрозділ видалено",
      "type search click search": "Введіть запит і натисніть Пошук",
      registration_is_completely_free: "Регистрация полностью бесплатна",
      dismiss: "Звільнити",
      "generate post code": "Код буде створений автоматично",
      april: "Квітень",
      "game edited": "Курс відредагована",
      "generate code": "Код посади ми генеруємо самі",
      "step 3 confirm": "Крок 3 - Підтвердження",
      "show dismissed": "Відображати звільнених",
      "for add org modal":
        'Для додавання організації обов"язково введіть її повне, скорочене найменування та адреса',
      "only appointment": "Тільки за призначенням",
      "full name organization": "Введіть повну назву організації",
      "view reports": "Перегляд звітів",
      "description upload image":
        "Для початку завантаження виберіть зображення у форматі jpeg або png. Розмір файлу обмежений 8 мегабайтами",
      "short name limit": "Скорочена назва не повинна перевищувати 25 символів",
      "best offer": "Краща пропозиція",
      "select product": "Обрати товар",
      "customer emotions": "Емоції клієнта в кінці бесіди",
      employees_count: "Співробітники",
      edit: "Редагувати",
      description: "Опис",
      you_continuing_game: "Ви продовжуєте проходження курсу",
      attention: "Увага",
      "confirm password": "Підтвердження пароля",
      "access to settings": "Доступ до налаштувань",
      "domain unique": "Домен повинен бути унікальним та латиницею",
      males: "Чоловiча",
      "play in game": "Проходження курсів",
      unlimited: "Необмежено",
      "user edited": "Користувач відредагований",
      choose: "Вибрати",
      "game not published":
        "Курс не може бути опублікована, тому що в ній немає стартового питання",
      cancel: "Вихiд",
      "for editing role":
        "Для редагування доступних груп прав, необхідно перейти у вікно настройки ролі",
      to: "До",
      'Annual license for installation on the client"s server':
        "Річна ліцензія для встановлення на сервер замовника",
      "text question": "Текст питання",
      november: "Листопад",
      "without stage": "без компетенцій",
      points: "балів",
      "email limit": "не повинен перевищувати 50 символів",
      forgot: 'Не забудьте заповнити обов"язкові поля',
      "deleting not undone": "Видалення працівника не можна скасувати",
      password: "Пароль",
      "Rent a server QS - Sales Team 6 month": "Sales Team 6 місяців",
      units: "Підрозділів",
      step1: "Крок 1 - Вибір курсу",
      address: "Адреса",
      delete: "Видалити",
      ru: "Рус",
      you_will_get_full_access_to_quickskills:
        "Ви отримаєте повний доступ до Quickskills і зможете користуватися усіма можливостями системи:",
      offer: "Обрати",
      minutes: "хвилин",
      "choose your plan": "Оберіть ваш план",
      "result game text": "результати курсу",
      july: "Липень",
      "delete role": "Видалення ролі",
      "training assigned": "Навчання призначене",
      "user not found": "Користувач не знайдений",
      "remove post": "Видалити посаду",
      "max file size": "Розмір файлу більший за",
      "we save changes": "Ми зберіжемо зміни",
      employee: "Співробітник",
      "game deleted": "Курс видалена",
      logout: "Вийти",
      "position without employee": "Посада без співробітника",
      "main page": "На головну",
      "user exists email": "Користувач з таким Email вже існує",
      game_per_assignment: "За призначенням",
      edit_profile: "Редагувати профіль",
      "visibility game": "Видимість курсу для звичайних користувачів",
      hr: "HR",
      "user offer": "Ви запропонували",
      "get trial qs": "Отримати пробний QS",
      date_started: "Дата початку",
      step2: "Крок 2 - Налаштування",
      "choose game": "Оберіть курс",
      edit_game: "Редагувати курс",
      points_for_passing: "Балів для проходження",
      year: "Рiк",
      "deleting game": "Видалення курсу",
      "exist email description":
        "Електронна пошта вже використовується іншим обліковим записом. Ви можете спробувати ввiйти або використовувати сторінку забутого пароля, щоб скинути свій пароль.",
      confirm: "Пiдтвердити",
      "created second game": "Ми створили другий, такий же курс",
      "try again": "Спробуйте завантажити картинку з меншим розміром",
      "first name limit": 'Ім"я не повинно перевищувати 20 символів',
      game_is_published: "Курс опублікована",
      get: "Замовити",
      "middle name": "По батькові",
      interface_language: "Мову",
      "page not found": "Сторінка не знайдена",
      "subdivision edited": "Підрозділ змінено",
      "max points": "Максимум балів в грі",
      "change photo": "Змінити фото",
      "number attempts": "Кількість спроб",
      "open constructor": "Відкрити в конструкторі",
      "Number of users - O/R, 50 Gb - disk space, backup once per week, price for 1 user":
        "Кількість користувачів - за запитом, 50 Гб - дискового простору, бекапи 1 раз в тиждень, за одну людину*",
      "must confirm dismissa":
        "Ви повинні підтвердити звільнення співробітників",
      "pass before": "Пройти до",
      "assign role": "Призначити роль",
      game_has_goods: "У курсі є продаж товарів",
      "role user": "Роль користувача",
      month: "Мiсяць",
      game: "Курс",
      "selected employees": "Вибрані співробітники",
      pass: "Пройти",
      create_games_courses_tests: "Створювати курси и тести",
      gender: "Стать",
      "tel form":
        "Ви можете використовувати тільки цифри. Номер телефону не повинно перевищувати 20 символів",
      "create new role": "Створення нової ролі",
      "deletes game": "Видалити курс",
      "between lists": "Перетягуйте між списками",
      "name role": "Назва ролi",
      settings: "Налаштування",
      "role edited": "Роль відредагована",
      "game not assigned":
        "Курс, доступний для самостійного проходження, не може бути призначена.",
      "attempts left": "Спроб залишилося",
      fired: "Звільнений",
      "forgot password": "Забули пароль?",
      "without organization": "Без організації",
      code: "Код",
      "Own profile editing": "Редагувати свій профіль",
      "affect the statistics of completed attempts":
        "При цьому, видалення не торкнеться статистики завершених спроб проходження",
      unfortunately: "На жаль, ви не набрали необхідну кількість балів",
      "page does not exist": "Судячи з усього такої сторінки не існує",
      "change post": "Редагування посади",
      "need data down":
        "Щоб додати необхідно заповнити дані зазначені в формі внизу",
      congratulations: "Вітаємо! Ви успішно пройшли курс",
      "cloning game": "Ми клонюємо курс",
      "removing post": "Видалення посады",
      position: "Посада",
      domain_name: 'Iм"я домену',
      "list role": "Список існуючих ролей",
      moderator: "Адміністратор",
      specify: "Індивідуально",
      cost: "Вартість, грн",
      "unique name game":
        "Назва повинна бути унікальним і бути не менше 4 символів в довжину",
      "not start": "Не розпочато",
      game_has_video: "У курсі є відеозаписи",
      progress: "прогрес",
      "training user": "Моя освiта",
      answers: "За відповідями",
      answer: "Відповідь",
      "start game": "Почати курс",
      org_name: "Название организации",
      september: "Вересень",
      "Rent a server QS - Sales Team 1 year": "Sales Team 1 рік",
      "delete employees confirm":
        "Ви повинні підтвердити видалення співробітників",
      organization: "Організація",
      'License for installation on the client"s server':
        "Ліцензія для встановлення на сервер замовника",
      created: "створено в",
      assign_user: "Призначати, створювати, управляти і навчати користувачів",
      "statistics-answers": "Статистика відповідей",
      selfeducation: "Cамо-навчання",
      "view organization": "Перегляд організаційної структури",
      positions: "Посади",
      "report finish": "Звіт про проходження курсу ",
      "Number of users - unlimeded": "Кількість користувачів - необмежено",
      "added subdivision": "Додавання підрозділів",
      "choosed games": "Обранi Курси",
      "number of points": "Кількість балів, необхідних для проходження курсу.",
      option: "Опціонально",
      december: "Грудень",
      export: "Експорт в",
      "name struct units": "Назва структурного підрозділу",
      "answer player": "Відповідь обрана користувачем",
      "player response": "Відповідь користувача",
      "save changes": "Зберегти зміни",
      "save & close": "Зберегти",
      game_has_no_audio: "У курсі немає аудіозаписів",
      profile: "Кабінет",
      reset: "Скинути",
      "published games": "Опубліковані курси",
      "available rights": "Доступні права",
      "step 1 - data": "Крок 1 - Загальні дані",
      "re-run": "Пройти заново",
      loading: "Іде завантаження",
      "selected role replaced":
        "Існуюча роль обраних співробітників буде замінена",
      females: "Жiноча",
      "delete happening":
        "У разі видалення організації, так само будуть видалені всі посади, відділи та інші структурні підрозділи, що входять в цю організацію",
      "sign up": "Зареєструватися",
      "post title": "Назва посади",
      passed: "Пройденi",
      may: "Травень",
      "post edited": "Посада відредагована",
      "birth date": "Дата народження",
      search_by_name: "Пошук по ПІБ",
      "admin page":
        "Ви знаходитесь на сторінці адміністратора. Для продовження, виберіть пункт в меню зліва.",
      "points quantity": "Бали зароблено",
      game_was_started: "Курс була розпочата",
      satisfied: "Задоволений",
      "report statistics": "Статистика відповідей",
      "edit game": "Редагування курсу",
      assign: "Призначити",
      "rights own game creation and assignation":
        "Права для створення і призначення своїх курсів",
      "bad end": "це може погано закінчитися, ви же розумієте",
      "create role": "Cтворити роль",
      "game assignments delete": "Призначення курсу також будуть видалені.",
      tel: "Телефон",
      "assign game": "Призначити курс",
      "delete happening subvision":
        "У разі видалення підрозділу, всі посади, відділи та інші структурні підрозділи, які входять в цей підрозділ, будуть винесенні на рівень організації",
      "in constructor": "У конструктор",
      fail: "Проваленa",
      reset_password: "Скинути пароль",
      "for create": "Для створення ролі введіть її назву та короткий опис",
      "not published": "Не опублікована",
      "not completed": "Незакінчені",
      "delete unit": "Ви дійсно хочете видалити підрозділ",
      august: "Серпень",
      divisions: "Підрозділи",
      "daily backups 500": "Щоденні бекапи - 500 грн на місяць",
      reports: "Звіти",
      "name role unique": "Назва повинна бути унікальна",
      "edit subdivision": "Редагування підрозділу",
      all_roles: "Усі ролі",
      division: "Підрозділ",
      "we send letter":
        "Ваше повідомлення було відправлене! Перевірте, будь ласка, пошту",
      evil: "Злий",
      "choose photo": "Вибрати фото",
      "operation undone": "Дану операцію неможливо скасувати",
      ua: "Укр",
      "create game": "Створити курсу",
      search_by_divisions: "Пошук по підрозділах",
      filters: "Фільтри",
      exit: "Вихiд",
      "percentage users": "Відсоток користувачів",
      happy: "Щасливий",
      "confirm delete role": "Ви повинні підтвердити видалення ролі",
      "org edited": "Організація відредагована",
      "choose employee": "Обрати працiвника",
      "play in assigned game": "Проходження призначених курсів",
      emotions: "Емоції",
      "estimated duration": "Орієнтовна тривалість",
      subdivision: "Підрозділ",
      games: "Курси",
      "get points": " Баллов набрано користувачем",
      "standard role": "Це стандартна роль і її не можна редагувати",
      "can apply":
        "Ви можете застосувати будь-яка дія до обраної вами групі співробітників",
      "title of post": "Введіть назву посади",
      "statistics games": "Статистика пройдених Курсів",
      "full name limit": "Повна назва не повинна перевищувати 100 символів",
      see_training_reports: "Будувати звіти по навчанню",
      "found in list": "Знайти у списку...",
      validity: "Термін дії",
      "discard offer": "Відмовитися від пропозиції товару",
      "this not cancle": "Дану дію неможливо скасувати",
      "add org modal": "Додавання організації",
      "position without a department":
        "Посада без підрозділу і буде додана в організацію",
      author: "Автор",
      "basic parameters": "Базові параметри",
      "Number of users - unlimeded, 80 Gb - disk space, backup once per week":
        "Кількість користувачів - необмежено, 80 Гб - дискового простору, бекапи 1 раз на тиждень *",
      "min points": "Мінімум балів для проходження",
      game_is_not_published: "курс не опублікований",
      min: "мин.",
      "self passing": "Самостійне проходження",
      control: "Управління",
      all_organizations: "Усі організації",
      stage: "Етап",
      "password recovery": "Відновлення паролю",
      day: "День",
      "glue paper": "Не клейте папірець з паролем до монітора ...",
      "create account": "Створити обліковий запис",
      "passage score": "Прохідний бал",
      "adress limit": "Адреса не повинна перевищувати 100 символів",
      "step 2 choose emploee": "Крок 2 - Вибір співробітника",
      "enter email": "Введіть адресу електронної пошти облікового запису в",
      name: "Назва",
      "date appointment": "Дата призначення",
      "title upload image": "Завантажити нове зображення",
      "character words": "Слова персонажа",
      "delete game": "Видалення призначення курсу",
      june: "Червень",
      from: "Від",
      "sure delete role": "Ви дійсно хочете видалити роль",
      "search by name game": "Пошук за назвою курсу ...",
      "if game big":
        "Якщо курс великий - це може зайняти деякий час. Ми повідомлю про результат.",
      email: "Aдрес электронной почты",
      role: "Ролi",
      warning: "Увага",
      "upload image": "Завантажити фото",
      game_has_no_video: "У курсі немає відеозаписів",
      "full name": "ПІБ",
      "daily backups 900": "Щоденні бекапи - 900 грн на місяць",
      all_divisions: "Всi підрозділи",
      "gd_create game": "Давай створимо твій перший курс!",
      "gd_on click game": 'Для цього клікни по кнопці "Створити курс".',
      "gd_create-name-game": "Придумай назву для свого курсу",
      "gd_description create name game":
        "Рекомендуємо вибрати назву, яка пов'язана з тематикою курсу.",
      "gd_create-description-game": "Напиши короткий опис свого курсу",
      "gd_description main goal":
        "В описі важливо передати головну мету курсу і що студент отримати в результаті.",
      gd_congrat: "Вітаю, початок покладено!",
      "gd_add info":
        "Тепер давай додамо відсутню інформацію. Для цього кликни на олівець",
      "gd_choose bg": "Вибери фон для свого курсу",
      "gd_recommend bg":
        "Ми рекомендуємо вибрати монохромний фон, який не буде відволікати.",
      "gd_choose character": "Вибери персонажа для свого курсу.",
      gd_question:
        "Саме від вірутального персонажа буде виходити поле з питанням.",
      "gd_choose color": "Вибери колір текстових полів і кнопок в курсі",
      gd_filled: "Всі поля заповнені? Впевнений?... Класно! Йдемо далі",
      "gd_self passing": "Вибери для кого буде призначений курс.",
      "gd_visual display":
        "А тепер перейдемо до візуального відображення курсу",
      "gd_open course": "Для цього Відкрий гру в конструкторі",
      "gd_assign course":
        "Давай я покажу тобі, як призначити курс співробітнику",
      "gd_choose employee":
        "Вибери потрібного співробітника і кликни по галочці",
      "gd_btn assign course": 'Відмінно, а тепер натисни "Призначити курс"',
      "gd_choose course": "Залишилося зовсім небагато... Обери курс",
      "gd_forward step": "Налаштування майже завершена. Тисни вперед",
      "gd_period course":
        "Після установки кількості спроб, вибери період проходження курсу",
      "gd_create group": "Давай створимо твою першу групу!",
      "gd_on click group": 'Для цього клікни по кнопці "Створити групу".',
      "gd_add employees": "Додавання співробітників",
      "gd_on click plus employees":
        'Щоб додати співробітника до списку, клікни на "+".',
      "gd_add courses": "Додавання курсів",
      "gd_on click plus courses": 'Щоб додати курс до списку, клікни на "+".',
      "gd_create-name-group": "Придумай назву для своєї групи",
      "gd_description create name group":
        "Рекомендуємо обрати назву, яка пов'язана з тематикою курсу.",
      "gd_create-description-group": "Напиши короткий опис своєї групи",
      "gd_description main group":
        "В описі важливо передати головну мету групи і що студент отримає в результаті",
      pu_statistics: "У цьому розділі зібрана статистика по співробітниках",
      "pu_statistics department":
        "Тут можна подивитися статистику по конткретному підрозділу",
      "pu_statistics course":
        "Тут можна подивитися статистику по конткретному курсу або по всіх відразу",
      "pu_add employee":
        "Ви завжди можете додати нового співробітника і надати йому доступ до створення курсів",
      "pu_firing employee":
        "Звільняючи співробітника, ви забираєте у нього доступ до курсів і програмі в цілому",
      "pu_specific employee":
        "Ви можете призначити управління курсом конкретному співробітнику",
      "pu_add organization":
        "Ви можете додавати нові організації і поміщати в них співробітників",
      "pu_edit organization":
        "Редагуйте інформацію про організацію в будь-який момент",
      "pu_edit course": "Ви можете редагувати опис к, які ви створили",
      "pu_design settings":
        "В Налаштуваннях конструктора можна додати нові питання, а також змінити візуальну частину",
      period_desc:
        "Це поле задає кількість днів для проходження курсу, після тієї дати коли співробітник доданий в групу",
      attempts_desc:
        'Це поле задає кількість спроб при проходженні курсу, коли співробітник доданий в групу. Якщо встановлено "0", то період безстроковий.',
      "error-name_group": "Ім'я повинно містити не менше 5 символів",
      period: "Період",
      attempts: "Спроби",
      "error-period": "Період повинен бути не менш ніж 1 день",
      "error-attempts": "Повинна бути хоча б 1 спроба",
      select_org: "Оберіть організацію і / або підрозділ",
      quizzes: "Тести",
      "back to list": "Повернутися до списку",
      "create quiz": "Створити тест",
      "delete quiz": "Видалити тест",
      "quiz properties": "Свойства тесту",
      "quiz question type": "Тип питання",
      "single choice": "Единственный выбор",
      "multiple choice": "Множественный выбор",
      "quiz questions": "Питання тесту",
      "edit quiz question": "Редагувати питання тесту",
      "passing score": "Прохідний бал",
      "randomize questions": "Перемішувати питання",
      "don't randomize questions": "Не перемішувати питання",
      "randomize answers": "Перемішувати відповіді",
      "don't randomize answers": "Не перемішувати відповіді",
      "limit Attempts": "Обмежити спроби",
      "do not limit attempts": "Не обмежувати спроби",
      "maximum number of attempts": "Максимальна кількість спроб",
      "show indicator true/false": "Показувати індикатор true/false",
      "do not show true / false indicator":
        "Не показувати індикатор true / false",
      "show points": "Показувати бали",
      "do not show points": "Не показувати бали",
      "allow movement to next / previous question":
        "Дозволити перехід до наступного / попереднього питання",
      "don't allow movement to next / previous question":
        "Не дозволяти перехід до наступного / попереднього питання",
      "quiz is published": "Тест опублікований",
      "quiz is not published": "Тест не опублікований",
      "success message": "Повідомлення про успіх",
      "failure message": "Повідомлення про помилку",
      "create question": "Створити питання",
      weight: "Вага питання",
      "name question": "Назва питання",
      "add answer": "Додати відповідь",
      "del answer": "Видалити відповідь",
      "right answer": "Правильна відопвідь",
    },
  },
  ru: {
    translations: {
      branding: "Брендирование урока",
      branding_enabled: "Если включена эта опция, то на каждом экране урока будет логотип вашей компании",
      delete_group_confirm: "Вы уверены что хотите удалить группу?",
      edit_group: "Редактировать группу",
      quiz_is_not_published: "Тест не опубликован",
      quiz_is_published: "Тест опубликован",
      multiple_anwers: "Вопрос с несколькими вариантами ответа",
      "quiz was successfully saved": "Тест успешно сохранен",
      cards_no_zero: "Минимум, который нужен для карты - 0",
      cards_no_hundert: "Максимальное количество карт - 100",
      cards_dont_connect:
        "Проценты прохождения не сбалансированы, пожалуйста, приведите проценты в соответствие.",
      example: "Пример",
      "change browser title":
        "Похоже, что вы используете браузер, который мы не полностью поддерживаем.",
      "change browser subtitle":
        "Мы рекомендуем попробовать использовать последнюю версию",
      or: "или",
      "your points": "Ваши баллы",
      "max points chart": "Макс. баллов",
      "With Points": "С баллами",
      "Without Points": "Без баллами",
      earned: "Набрал",
      maximum: "Максимум",
      "Here you can select any image as a question.":
        "Здесь вы можете выбрать любое изображение в качестве вопроса.",
      "Choose background image": "Выберите фоновое изображение",
      "Gallery of backgrounds": "Галерея фонов",
      "hide footer": "Скрыть нижний колонтитул",
      "show footer": "Показать нижній колонтитул",
      Result: "Результат",
      advanced: "Расширенный",
      "not started": "Не начато",
      "game over": "Курс завершено",
      "try again btn": "Попробовать ещё",
      complete: "Закончить",
      my_best_score: "Моя лучшая попытка",
      "max-points-in-game": "Максимум",
      "your result": "твoй результат",
      "congratulations zero": "Поздравляем!",
      "congratulations text zero":
        "Ты прошел до конца, надеемся тебе было интересно!",
      "congratulations first": "Поздравляем!",
      "congratulations text first":
        "Молодец! Как круто, что ты работаешь в нашей компании!",
      "congratulations second": "Добре!",
      "congratulations text second":
        "Неплохой результат.Но надо еще немного поработать на своими знаниями. Если ты хочешь стать лучшим, то советуем пройти еще раз.",
      "congratulations third": "Хм...",
      "congratulations text third":
        'Нет, так не пойдет. Кликай на кнопку "Повторить попытку", внимательно пройди еще раз, и встречаемся снова.',
      groups: "Группы",
      group: "Группа",
      "create group": "Создать группу",
      "first step": "Шаг 1 - Выбор курсов",
      "second step": "Шаг 2 - Настройка",
      "unique name group": "Уникальное имя группы",
      "delete group": "Удалить группу",
      "deleting group": "Удаление группы",
      "number employees": "Количество сотрудников",
      company: "Компания",
      "number games": "Количество курсов",
      next: "Далее",
      promo: "Промо",
      "Choose language": "Выберите язык",
      no_promo: "Без промо",
      promo_text: "Отключить стартовый экран и итоговый отчет",
      "show demo games": "Показать демо-курсы",
      "hide demo games": "Скрыть демо-курсы",
      back_to_organization: "Вернуться к организации",
      employees: "Сотрудники",
      intro_video_id: "pr4n8tir6c",
      press_to_continue: "Нажмите «Далее», чтобы продолжить.",
      offer_simple:
        "Мы сделали наш сервис простым и доступным для всех. Предлагаем ознакомиться с его основными возможностями.",
      platform_desc: "платформа для обучения с помощью игр и симуляций.",
      we_happy_to_see_you: "Мы рады видеть Вас нашим пользователем!",
      "name game": "Имя",
      help_link: "https://site.quickskills.pro/how-to",
      "If the game is public and published you can embed this game on any website.":
        "Если курс является общедоступной и опубликованной, вы можете вставить этот курс на любой сайт.",
      Public: "Публичный",
      "Not public": "Не публичный",
      Competencies: "Компетенция",
      Skill: "Навык",
      "This game develops skills": "Этот курс развивает навыки",
      game_has_no_goods: "В курсе нет продаж продуктов",
      apply: "Применить",
      "position created": "Должность создана",
      welcome: "Добро пожаловать в",
      "if employee listed as a head":
        "Если вы хотите, чтобы сотрудник на этой должности числился как руководитель",
      emotion: "Эмоция",
      domain: "домен",
      "settings org": "Настройки организации",
      "short name": "Сокращенное название",
      deselect: "Отменить выбор",
      "users have been edited": "Пользователи отредактированы",
      "enough rights":
        "У вас не хватает прав для посещения данного раздела сайта",
      registration: "Регистрация",
      'registration subtitle': "Вы получите доступ ко всем функциям Quickskills премиум-класса на 14 дней. Лучшие в отрасли ресурсы для развития вашего бизнес-обучения. Никаких обязательств, кредитная карта не требуется.",
      "subdivision created": "Подразделение создано",
      "Number of users - up to 500, 50 Gb - disk space, backup once per week":
        "Количество пользователей - до 500, 50 Гб - дискового пространства, бэкапы 1 раз в неделю**",
      expiration_date: "Дата окончания",
      "time of passage": "Время прохождения",
      "obligatory field": "Обязательное поле",
      "surname limit": "Фамилия не должна превышать 20 символов",
      "editing roles": "Редактирование ролей",
      "not edit users": "У вас нет прав на редактирование пользователей",
      "user created": "Пользователь создан",
      "own game creation and assignation": "Создание и назначение своих курсов",
      "game created": "Курс создан",
      "must enter its name":
        "Для добавления структурного подразделения необходимо ввести его название",
      "6 month": "6 месяцев",
      dashboard: "Дэшборд",
      "average emotions": "Усредненные эмоции клиента",
      "forget employees": "Не забудьте назначить сотрудника на должность",
      "Rent a server QS - Medium 6 month": "Medium 6 месяцев",
      "send email password":
        "Мы отправим вам письмо со ссылкой, которая будет действительна 24 часа",
      "have an account": "У вас нет учетной записи?",
      "already have an account": "Уже есть аккаунт?",
      "add employees": "Добавление сотрудника",
      user: "Пользователь",
      the_domain_name_is_already_in_use_by_another_account:
        "Домен уже используется другой учетной записью.",
      "not specified role":
        "Роли не заданы права и поэтому ее нельзя назначить",
      "list org": "Список существующих организаций",
      "employee without a job": "Сотрудник останется без должности",
      "not specified": "Не указан",
      "test position": "Тестовая должность",
      "editing users": "Редактирование пользователей",
      "trial game": "Пробное прохождение",
      march: "Март",
      "period passage": "Период прохождения",
      "lose rights":
        "Пользователи, которым была назначена данная роль, лишатся связанных с ней прав",
      "game creation and assignation": "Создание и назначение курсов",
      "delete org": "Вы действительно хотите удалить организацию",
      "not finished": "Не закончена",
      "game cloned": "Курс склонирован",
      subdivisions: "Подразделения",
      open_tasks: "Открытых задач",
      "attempts all": "Попыток всего",
      optional: "Опционально",
      search: "Поиск",
      "this description game":
        "Это описание будет видеть обучающийся при запуске курса",
      "add post": "Добавление должности",
      en: "Eng",
      continue: "Для продолжения введите данные учетной записи",
      "customer emotions": "Эмоции клиента в конце беседы",
      orgstructure: "Оргструктура",
      wrong: "Неправильно введеный логин или пароль",
      "information employee": "Информация о сотруднике",
      "position deleted": "Должность удалена",
      dismissal: "Увольнение",
      "employee as a manager":
        "Если вы хотите, чтобы сотрудник на этой должности числился как руководитиль",
      training: "Обучение",
      "description structural unit": "Описание структурного подразделения",
      "send letter": "Зарегистрироваться бесплатно",
      appointment: "По назначениям",
      "login": "Вход",
      "login subtitle": "Используйте Quickskills для создания курсов, привлечения, обучения и адаптации новых сотрудников.",
      "log in": "Войдите в свою учетную запись",
      "login failed": "Ошибка входа",
      "terms of use": "Условия эксплуатации",
      "privacy policy": "Политика конфиденциальности",
      "support": "Поддержка",
      "contact": "Связаться с нами",
      "and": "и",
      actions: "Действия",
      "number choices": "Количество выборов",
      all_games: "Все курсы",
      assigned: "Назначена",
      hint: "Подсказки",
      search_by_positions: "Поиск по должности",
      neutral: "Нейтральный",
      "Rent a server QS - Unlim 1 year": "Unlim 1 год",
      forward: "Вперед",
      "editing organization": "Редактирование организационной структуры",
      close: "Закрыть",
      "cancel changes": "Отменить изменения",
      "first name": "Имя",
      "role description": "Описание роли",
      january: "Январь",
      "score stage": "Баллы по компетенциям",
      score: "Баллы",
      amount: "Количество",
      "governing office": "Руководитель",
      err: "Ошибка",
      "current rights": "Текущие права",
      surname: "Фамилия",
      "change language": "Сменить язык системы",
      "access error": "Ошибка доступа",
      "exist email": "Такой Email уже существует",
      search_by_org: "Поиск по организациям",
      estimate_tooltip:
        "Примерная продолжительность игры, без учета видео, аудио и т.п.",
      february: "Февраль",
      "Rent a server QS - Medium 1 year": "Medium 1 год",
      sad: "Грустный",
      "description limit": "Описание не должно превышать 100 символов",
      "select all": "Выбрать всех",
      appointments: "На главную",
      "1 year": "1 год",
      "return in organization": " Вернуться в организацию",
      "delete org main": "Удалить организацию",
      "delete unit main": "Удалить подразделение",
      "best try": "По назначениям",
      back: "Назад",
      "Rent a server QS - Unlim 6 month": "Unlim 6 месяцев",
      please_login: "Пожалуйста, залогиньтесь для продолжения",
      "email unique": "Адрес электронной почты должен быть уникальным",
      "creating game": "Создание курсов",
      "description game": "Описание курсов",
      total: "Всего",
      game_has_audio: "В курсе есть аудиозаписи",
      game_self_assigned: "По самоназначению",
      "head of": "Руководитель",
      "list games": "К списку курсов",
      "code importing data":
        "Данный код будет использоваться при импорте и экспорте данных",
      october: "Октябрь",
      this_domain_name_already_exists: "Такой Domain name уже существует",
      "switch active role": "Возможно, вам нужно переключить активную роль",
      "clone game": "Клонировать курс",
      you_continuing_game: "Вы продолжаете прохождение курсов",
      "maximum points": "Баллы максимальные",
      further: "Далее",
      "user not be able pass game":
        "Пользователь не сможет проходить назначенный курс.",
      status: "Статус",
      "game assignments save value":
        "При этом, удаление не затронет те назначения, которые проходятся в данный момент.",
      "for assign game": "Для назначения курса",
      needs_attention: "Требуется внимания",
      enter: "Войти",
      "role description unique":
        "Описание должно быть максимально лаконичным и содержательным",
      "view roles": "Просмотр ролей",
      "add org": "Добавить организацию",
      "subdivision deleted": "Подразделение удалено",
      "type search click search": "Введите запрос и нажмите Поиск",
      registration_is_completely_free: "Регистрация полностью бесплатна",
      dismiss: "Уволить",
      "generate post code": "Код будет сгенерирован автоматически",
      april: "Апрель",
      "game edited": "Курс отредактирован",
      "generate code": "Код должности мы сгенерируем сами",
      "step 3 confirm": " Шаг 3 - Подтверждение",
      "show dismissed": "Отображать уволенных",
      "for add org modal":
        "Для добавления организации обязательно введите ее полное, сокращенное название и адрес",
      "only appointment": "Только по назначению",
      "full name organization": "Введите полное название организации",
      "view reports": "Просмотр отчетов",
      "description upload image":
        "Для начала загрузки выберите изображение в формате jpeg или png. Размер файла ограничен 8 мегабайтами",
      "short name limit":
        "Сокращенное название не должно превышать 25 символов",
      "best offer": "Лучшее предложение",
      "select product": "Выберите продукт",
      game_has_video: "В курсе есть видеозаписи",
      employees_count: "Сотрудники",
      edit: "Редактировать",
      description: "Описание",
      "right response": "Правильный ответ",
      attention: "Внимание",
      "confirm password": "Подтверждение пароля",
      "access to settings": "Доступ к настройкам",
      "domain unique": "Домен должен быть уникальным и латинским",
      males: "Мужской",
      "play in game": "Прохождение курсов",
      unlimited: "Неограниченно",
      "user edited": "Пользователь отредактирован",
      choose: "Выбрать",
      "game not published":
        "Курс не может быть опубликован, т.к. в ней нет стартового вопроса",
      cancel: "Отмена",
      "for editing role":
        "Для редактирования доступных групп прав, необходимо перейти в окно настройки роли",
      to: "До",
      'Annual license for installation on the client"s server':
        "Годовая лицензия для установки на сервер заказчика",
      "text question": "Вопрос",
      november: "Ноябрь",
      "without stage": "без компетенций",
      points: "баллы",
      "email limit": "не должен превышать 50 символов",
      forgot: "Не забудьте заполнить обязательные поля",
      "deleting not undone": "Удаление сотрудника невозможно отменить",
      password: "Пароль",
      "Rent a server QS - Sales Team 6 month": "Sales Team 6 год",
      units: "Подразделений",
      step1: "Шаг 1 - Выбор курсов",
      address: "Адрес",
      delete: "Удалить",
      ru: "Рус",
      you_will_get_full_access_to_quickskills:
        "Вы получите полный доступ к Quickskills и сможете пользоваться всеми возможностями системы:",
      offer: "Выбрать",
      minutes: "минут",
      "choose your plan": "Выберете Ваш план",
      "result game text": "результаты курсов",
      july: "Июль",
      "delete role": "Удаление роли",
      "training assigned": "Обучение назначено",
      "user not found": "Пользователь не найден.",
      "remove post": "Удалить должность",
      "max file size": "Размер файла больше, чем",
      "we save changes": "Мы сохранили изменения",
      employee: "Сотрудник",
      "game deleted": "Курс удален",
      logout: "Выйти",
      "position without employee": "Должность без сотрудника",
      "main page": "На главную",
      "user exists email": "Пользователь с таким Email уже существует",
      game_per_assignment: "По назначению",
      edit_profile: "Редактировать профиль",
      "visibility game": "Видимость курсов для обычных пользователей ",
      hr: "HR",
      "user offer": "Вы предложили",
      "get trial qs": "Получить пробную версию QS",
      date_started: "Дата начала",
      step2: "Шаг 2 - Настройка",
      "choose game": "Выберите курс",
      edit_game: "Редактировать курс",
      points_for_passing: "Баллов для прохождения",
      year: "Год",
      "deleting game": "Удаление курса",
      "exist email description":
        " Email уже используется другой учетной записью. Вы можете войти в систему или использовать страницу для сброса пароля",
      confirm: "Подтвердить",
      "created second game": "Мы создали второй, такой же курс",
      "try again": "Попробуйте загрузить картинку с меньшим размером",
      "first name limit": "Имя не должно превышать 20 символов",
      game_is_published: "Курс опубликован",
      get: "Зказать",
      "middle name": "Отчество",
      interface_language: "Язык интерфейса",
      "page not found": "Страница не найдена",
      "subdivision edited": "Подразделение отредактировано",
      "max points": "Максимум баллов в курсе",
      "change photo": "Сменить фотографию",
      "number attempts": "Количество попыток",
      "open constructor": "Открыть в конструкторе",
      "Number of users - O/R, 50 Gb - disk space, backup once per week, price for 1 user":
        "Количество пользователей - по запросу, 50 Гб - дискового пространства, бэкапы 1 раз в неделю за одного человека*",
      "must confirm dismissa": "Вы должны подтвердить увольнение сотрудников",
      "pass before": "  Пройти до",
      "assign role": "Назначить роль",
      game_has_goods: "В курсе есть продажа продуктов",
      "role user": "Роль пользователя",
      month: "Месяц",
      game: "Курс",
      "selected employees": "Выбранные сотрудники",
      pass: "Пройти",
      create_games_courses_tests: "Создавать курсы и тесты",
      gender: "Пол",
      "tel form":
        "Вы можете использовать только цифры. Номер телефона не должно превышать 20 символов",
      "create new role": "Создание новой роли",
      "add game": "Добавить курс",
      "deletes game": "Удалить курс",
      "between lists": "Перетаскивайте между списками",
      "name role": "Название роли",
      settings: "Настройки",
      "role edited": "Роль отредактирована",
      "game not assigned":
        "Курс, доступен для самостоятельного прохождения, не может быть назначена",
      "attempts left": " Попыток осталось",
      fired: "Уволен",
      "forgot password": "Забыли пароль?",
      "without organization": "Без организации",
      code: "Код",
      "Own profile editing": "Редактирование своего профиля",
      "affect the statistics of completed attempts":
        "При этом, удаление не затронет статистику завершенных попыток прохождения.",
      unfortunately:
        "К сожалению, вы не набрали необходимого количество баллов",
      "page does not exist": "Судя по всему такой страницы не существует",
      "change post": "Редактирование должности",
      "need data down":
        "Для добавления необходимо заполнить данные указанные в форме внизу",
      congratulations: "Поздравляем! Вы успешно прошли курс",
      "cloning game": "Мы клонируем курс",
      "removing post": "Удаление должности",
      position: "Должность",
      domain_name: "Имя домена",
      "list role": "Список существующих ролей",
      moderator: "Администратор",
      specify: "Индивидуально",
      cost: "Стоимость, грн",
      "unique name game":
        "Название должно быть уникальным и быть не менее 4 символов в длину",
      "not start": "Не начата",
      progress: "Прогресс",
      "training user": "Мое обучение",
      answers: "По ответам",
      answer: "Ответ",
      "start game": "Начать",
      org_name: "Название организации",
      september: "Сентябрь",
      "Rent a server QS - Sales Team 1 year": "Sales Team 1 год",
      "delete employees confirm": "Вы должны подтвердить удаление сотрудников",
      organization: "Организация",
      'License for installation on the client"s server':
        "Лицензия для установки на сервер заказчика",
      created: "создано в",
      assign_user: "Назначать, создавать, управлять и обучать пользователей",
      "statistics-answers": "Статистика ответов",
      selfeducation: "Само-обучение",
      "view organization": "Просмотр организационной структуры",
      positions: "Должности",
      "report finish": "Рады сообщить вам результаты курса ",
      "Number of users - unlimeded": "Количество пользователей - неограниченно",
      "added subdivision": "Добавление подразделения",
      "choosed games": "Выбранные курсы",
      "number of points":
        "Количество баллов, необходимое для прохождения курсов.",
      option: "Вариант",
      december: "Декабрь",
      export: "Экспорт в",
      "name struct units": "Название структурного подразделения",
      "answer player": "Ответ выбранный пользователем",
      "player response": "Ответ",
      "save changes": "Сохранить изменения",
      "save & close": "Сохранить",
      game_has_no_audio: "В курсе нет аудиозаписей",
      profile: "Кабинет",
      reset: "Сбросить",
      "published games": "Опубликованные курсы",
      "available rights": "Доступные права",
      "step 1 - data": "Шаг 1 - Общие данные",
      "re-run": "Пройти заново",
      loading: "Идет загрузка",
      "selected role replaced":
        "Существующая роль выбранных сотрудников будет заменена",
      females: "Женский",
      "delete happening":
        "В случае удаления организации, так же будут удалены все должности, отделы и иные структурные подразделения входящие в эту организацию",
      "sign up": "Зарегистрироваться",
      "post title": "Название должности",
      passed: "Пройденная",
      may: "Май",
      "post edited": "Должность отредактирована",
      "birth date": "Дата рождения",
      search_by_name: "Поиск по ФИО",
      "admin page":
        "Вы находитесь на странице администратора. Для продолжения, выберите пункт в меню слева.",
      "points quantity": "Баллы заработано",
      game_was_started: "Курс была начат",
      satisfied: "Довольный",
      "report statistics": "Cтатистика ответов",
      "edit game": "Редактирование курса",
      assign: "Назначить",
      "rights own game creation and assignation":
        "Права для создания и назначения своих курсов",
      "bad end": "это может плохо закончиться, Вы же понимаете",
      "create role": "Создать роль",
      "game assignments delete": "Назначения курса также будут удалены.",
      tel: "Телефон",
      "assign game": "Назначить курс",
      "delete happening subvision":
        "В случае удаления подразделения, все должности, отделы и иные структурные подразделения входящие в это подразделение будут вынесены на уровень организации",
      "in constructor": "В конструктор",
      fail: "Проваленная",
      reset_password: "Сброс пароля",
      "for create": "Для создания роли введите ее название и краткое описание",
      "not published": "Не опубликована",
      "not completed": "Не законченные",
      "delete unit": "Вы действитительно хотите удалить подразделение",
      august: "Август",
      divisions: "Подразделения",
      "daily backups 500": "Ежедневные бэкапы - 500 грн в месяц",
      reports: "Отчеты",
      "name role unique": "Название должно быть уникальным",
      "edit subdivision": "Редактирование подразделения",
      all_roles: "Все роли",
      division: "Подразделение",
      "we send letter": "Письмо отправлено! Проверьте, пожалуйста, почту",
      evil: "Злой",
      "choose photo": "Выбрать фото",
      "operation undone": "Данную операцию невозможно отменить",
      ua: "Укр",
      "create game": "Создать курс",
      search_by_divisions: "Поиск по подразделениям",
      filters: "Фильтры",
      exit: "Выход",
      "percentage users": "Процент пользователей",
      happy: "Счастливый",
      "confirm delete role": "Вы должны подтвердить удаление роли",
      "org edited": "Организация отредактирована",
      "choose employee": "Выбрать сотрудника",
      "play in assigned game": "Прохождение назначеных курсов",
      emotions: "Эмоции",
      "estimated duration": "Примерная продолжительность",
      subdivision: "Подразделение",
      games: "Курсы",
      "get points": "Баллы",
      "standard role": "Это стандартная роль и ее нельзя редактировать",
      "can apply":
        "Вы можете применить любое действие к выбранной вами группе сотрудников",
      "title of post": "Введите название должности",
      "statistics games": "Статистика пройденных курсов",
      "full name limit": "Полное название не должно превышать 100 символов",
      see_training_reports: "Строить отчёты по обучению",
      "found in list": "Найти в списке...",
      validity: "Срок действия",
      "discard offer": "Отказаться от предложения продукта",
      "this not cancle": "Данное действие невозможно отменить",
      "add org modal": "Добавление организации",
      "position without a department":
        "Должность без подразделения и будет добавлена в организацию",
      author: "Автор",
      "basic parameters": "Базовые параметры",
      "Number of users - unlimeded, 80 Gb - disk space, backup once per week":
        "Количество пользователей - неограниченно, 80 Гб - дискового пространства, бэкапы 1 раз в неделю*",
      "min points": " Минимум баллов для прохождения",
      game_is_not_published: "Курс не опубликован",
      min: "мин.",
      "self passing": "Самостоятельное прохождение",
      control: "Управление",
      all_organizations: "Все организации",
      stage: "Cтадия",
      "password recovery": "Восстановление пароля",
      day: "День",
      "glue paper": "Не клейте бумажку с паролем к монитору...",
      "create account": "Создать учетную запись",
      "passage score": "Проходной балл",
      "adress limit": "Адрес не должен превышать 100 символов",
      "step 2 choose emploee": "Шаг 2 - Выбор сотрудника",
      "enter email": "Введите адрес электронной почты учетной записи",
      name: "Название",
      "date appointment": "Дата назначения",
      "title upload image": "Загрузка нового изображения",
      "character words": "Слова персонажа",
      "delete game": "Удаление назначения курса",
      june: "Июнь",
      from: "От",
      "sure delete role": "Вы действительно хотите удалить роль ",
      "search by name game": "Поиск по названию курса ...",
      "if game big":
        "Если курс большой — это может занять какое-то время. Мы сообщим о результате.",
      role: "Роли",
      email: "Aдрес электронной почты",
      warning: "Внимание!",
      "upload image": "Загрузить",
      game_has_no_video: "В курсе нет видеозаписей",
      "full name": "ФИО",
      "daily backups 900": "Ежедневные бэкапы - 900 грн в месяц",
      all_divisions: "Все подразделения",
      "gd_create game": "Давай создадим твой первый курс!",
      "gd_on click game": 'Для этого кликни по кнопке "Создать курс".',
      "gd_create-name-game": "Придумай название для своего курса",
      "gd_description create name game":
        "Рекомендуем выбрать название, которое связано с тематикой курса.",
      "gd_create-description-game": "Напиши краткое описание своего курса",
      "gd_description main goal":
        "В описании важно передать главную цель курса и что студент получить в итоге",
      gd_congrat: "Поздравляю, начало положено!",
      "gd_add info":
        "Теперь давай добавим недостающую информацию. Для этого кликни на карандаш",
      "gd_choose bg": "Выбери фон для свого курса",
      "gd_recommend bg":
        "Мы рекомендуем выбрать монохромный фон, который не будет отвлекать.",
      "gd_choose character": "Выбери персонажа для своего курса",
      gd_question:
        "Именно от вирутального персонажа будет исходить поле с вопросом.",
      "gd_choose color": "Выбери цвет текстовых полей и кнопок в курсе",
      gd_filled: "Все поля заполнены? Уверен?... Класс! Идем дальше",
      "gd_self passing": "Выбери для кого будет предназначен курс.",
      "gd_visual display": "А теперь перейдем к визуальному отображению курса",
      "gd_open course": "Для этого открой курс в конструкторе",
      "gd_assign course": "Давай я покажу тебе, как назначить курс сотруднику",
      "gd_choose employee": "Выбери нужного сотрудника и кликни по галочке",
      "gd_btn assign course": 'Отлично, а теперь нажми "Назначить курс".',
      "gd_choose course": "Осталось совсем немного... выбери курс",
      "gd_forward step": "Настройка почти завершена. Жми вперед!",
      "gd_period course":
        "После установки количества попыток, выбери период прохождения курса",
      "gd_create group": "Давай создадим твою первую группу!",
      "gd_on click group": 'Для этого кликни по кнопке "Создать группу".',
      "gd_add employees": "Добавление сотрудников",
      "gd_on click plus employees":
        'Что бы добавить сотрудника в список, нажми на "+".',
      "gd_add courses": "Добавление курсов",
      "gd_on click plus courses":
        'Что бы добавить курс в список, нажми на "+".',
      "gd_create-name-group": "Придумай название для своей группы",
      "gd_description create name group":
        "Рекомендуем выбрать название, которое связано с тематикой курса.",
      "gd_create-description-group": "Напиши краткое описание своей группы",
      "gd_description main group":
        "В описании важно передать главную цель группы и что студент получит в итоге",
      pu_statistics: "В этом разделе собрана статистика по сотрудникам",
      "pu_statistics department":
        "Здесь можно посмотреть статистику по конткретному подразделению",
      "pu_statistics course":
        "Здесь можно посмотреть статистику по конткретному курсу либо по всем сразу",
      "pu_add employee":
        "Вы всегда модете добавить нового сотрудника и предоставить ему доступ к созданию курсов",
      "pu_firing employee":
        "Увольняя сотрудника, вы забираете у него доступ к курсам и программе в целом",
      "pu_specific employee":
        "Вы можете назначить управление курсом конкретному сотруднику",
      "pu_add organization":
        "Вы можете добавлять новые организации и помещать в них сотрудников",
      "pu_edit organization":
        "Редактируйте информацию об организации в любой момент",
      "pu_edit course":
        "Вы можете редактировать описание курсов, которые вы создали",
      "pu_design settings":
        "В настройках конструктора можно добавить новые вопросы, а также изменить визуальную часть",
      period_desc:
        "Это поле задает количество дней для прохождения курса, после той даты когда сотрудник добавлен в группу",
      attempts_desc:
        'Это поле задает количество попыток при прохождении курса, когда сотрудник добавлен в группу. Если установлено "0", то период бессрочный.',
      "error-name_group": "Имя должно содержать не менее 5 символов",
      period: "Период",
      attempts: "Попытки",
      "error-period": "Период должен быть не менее 1 дня",
      "error-attempts": "Должна быть хотя бы 1 попытка",
      select_org: "Выберите организацию и / или подразделение",
      quizzes: "Тесты",
      "back to list": "Вернуться к списку",
      "create quiz": "Создать тест",
      "delete quiz": "Удалить тест",
      "quiz properties": "Свойства теста",
      "quiz question type": "Тип вопроса",
      "single choice": "Единственный выбор",
      "multiple choice": "Множественный выбор",
      "quiz questions": "Вопросы теста",
      "edit quiz question": "Редактировать вопрос теста",
      "passing score": "Проходной балл",
      "randomize questions": "Перемешивать вопросы",
      "don't randomize questions": "Не перемешивать вопросы",
      "randomize answers": "Перемешивать ответы",
      "don't randomize answers": "Не перемешивать ответы",
      "limit Attempts": "Ограничить попытки",
      "do not limit attempts": "Не ограничивать попытки",
      "maximum number of attempts": "Максимальное число попыток",
      "show indicator true/false": "Показывать индикатор true/false",
      "do not show true / false indicator":
        "Не показывать индикатор true / false",
      "show points": "Показывать баллы",
      "do not show points": "Не показывать баллы",
      "allow movement to next / previous question":
        "Разрешить переход к следующему / предыдущему вопросу",
      "don't allow movement to next / previous question":
        "Не разрешать переходить к следующему / предыдущему вопросу",
      "quiz is published": "Тест опубликован",
      "quiz is not published": "Тест не опубликован",
      "success message": "Сообщение об успехе",
      "failure message": "Сообщение, если тест не пройден",
      "create question": "Создать вопрос",
      weight: "Вес вопроса",
      "name question": "Название вопроса",
      "add answer": "Добавить ответ",
      "del answer": "Удалить ответ",
      "right answer": "Правильный ответ",
    },
  },
  en: {
    translations: {
      branding: "Branding the lesson",
      branding_enabled: "If this option is enabled, each lesson screen will have your company logo on it",
      edit_group: "Edit group",
      quiz_is_not_published: "Quiz is not published",
      quiz_is_published: "Quiz is published",
      multiple_anwers: "Multiple choice question",
      "quiz was successfully saved": "Quiz was successfully saved",
      "your points": "Your ponts",
      delete_group_confirm: "Are you sure you want to delete this group?",
      "max points chart": "Max. points",
      "With Points": "With Points",
      "Without Points": "Without Points",
      "change browser title":
        "It looks like you are using a browser we don’t fully support.",
      "change browser subtitle":
        "We recommend to try with the latest version of",
      or: "or",
      earned: "Earned",
      maximum: "Maximum",
      "Here you can select any image as a question.":
        "Here you can select any image as a question.",
      "Gallery of backgrounds": "Gallery of backgrounds",
      "Choose background image": "Choose background image",
      "show footer": "Show footer",
      "hide footer": "Hide footer",
      Result: "Result",
      advanced: "Advanced",
      "game over": "Course finished",
      my_best_score: "My best score",
      "try again btn": "Try Again",
      "max-points-in-game": "Maximum",
      complete: "Complete",
      "your result": "your result",
      "congratulations zero": "Congratulations!",
      "congratulations text zero":
        "You've reached the end, we hope you had fun!",
      "congratulations first": "Congratulations!",
      "congratulations text first": "Awesome! You are doing really well!",
      "congratulations second": "Good!",
      "congratulations text second": "Nice going! Keep up the good work.",
      "congratulations third": "Hmm",
      "congratulations text third":
        "Too bad, keep working to make your results better.",
      groups: "Groups",
      "create group": "Create group",
      "first step": "Step 1 - Select course",
      "second step": "Step 2 - Customize",
      "delete group": "Delete group",
      "deleting group": "Deleting group",
      "number employees": "The number of employees",
      "number games": "Number of courses",
      "add game": "Add course",
      company: "Company",
      back_to_organization: "Back to Organization",
      next: "Next",
      "Choose language": "Choose language",
      promo: "Promo",
      no_promo: "No Promo",
      promo_text: "Disable start screen and disable summary report",
      "show demo games": "Show demo courses",
      "hide demo games": "Hide demo courses",
      intro_video_id: "stssvkyxi9",
      employees: "Employees",
      press_to_continue: "Press next to continue ",
      offer_simple:
        "We have made our platform simple and affordable for everyone. We offer you to familiarize yourself with its main features.",
      platform_desc: "platform for learning with courses and simulations.",
      we_happy_to_see_you: "We are glad to see you as our user!",
      "If the game is public and published you can embed this game on any website.":
        "If the course is public and published you can embed this course on any website.",
      Public: "Public",
      help_link: "https://site.quickskills.pro/how-to-review",
      "Not public": "Not public",
      Skill: "Skill",
      Competencies: "Competencies",
      "This game develops skills": "This course develops skills",
      game_has_no_goods: "Course has no sale of goods",
      apply: "Apply",
      "position created": "Position is created",
      welcome: "Welcome to",
      "if employee listed as a head":
        "If you want the employee in this position to be listed as a head",
      emotion: "Еmotion",
      domain: "domain",
      "settings org": "Organization settings",
      "short name": "Abbreviation",
      deselect: "Deselect",
      "selected role replaced":
        "The existing role of the selected staff will be replaced",
      "add post": "Add a position",
      registration: "Registration",
      'registration subtitle': "You will get access to to premium all features of Quickskills for 14 days. Industry-leading resources to build your business learning. No strings attached, no credit card required.",
      "subdivision created": "Subdivision is created",
      game_per_assignment: "Course is per assignment",
      "Number of users - up to 500, 50 Gb - disk space, backup once per week":
        "Number of users - up to 500, 50 Gb - disk space, backup once per week**",
      expiration_date: "Expiration Date",
      "time of passage": "Time of passage",
      "obligatory field": "Оbligatory field",
      "surname limit": "Last name must not exceed 20 characters",
      "editing roles": "Editing roles",
      "not edit users": "You do not have permissions to edit users",
      "get trial qs": "Get trial QS",
      "user created": "User is created",
      "own game creation and assignation":
        "Creating and assigning your own courses",
      "short name limit": "The abbreviated name must not exceed 25 characters",
      "must enter its name":
        "To add a structural unit, you must enter its name",
      "6 month": "6 month",
      dashboard: "Dashboard",
      "average emotions": "Average customer emotions",
      "forget employees":
        "Do not forget to appoint an employee to the position",
      "Rent a server QS - Medium 6 month": "Medium 6 month",
      "enter email": "Enter the email address of the account in",
      "Rent a server QS - Unlim 1 year": "Unlim 1 year",
      warning: "Warning!",
      "not started": "Not started",
      "have an account": "You do not have an account?",
      "already have an account": "Already have an account?",
      "add employees": "Add employee",
      user: "User",
      the_domain_name_is_already_in_use_by_another_account:
        "The domain name is already in use by another account.",
      "not specified role":
        "Roles do not specify the right and therefore can not be assigned",
      "list org": "List of existing organizations",
      "employee without a job": "The employee will be left without a job",
      "not specified": "Not specified",
      "test position": "Test position",
      "editing users": "Editing Users",
      "trial game": "Trial passage",
      march: "March",
      "period passage": "Period of passage",
      "lose rights":
        "Users who have been assigned this role will lose their rights associated with it",
      optional: "Optional",
      "game creation and assignation": "Creating and assigning courses",
      "delete org": "Are you sure you want to delete the organization",
      "not finished": "Not finished",
      "game cloned": "Course is bent",
      subdivisions: "Subdivisions",
      'License for installation on the client"s server':
        'License for installation on the client"s server',
      "attempts all": "Attempts at all",
      "users have been edited": "Users have been edited",
      "sign up": "Sign up",
      "enough rights":
        "You do not have enough rights to visit this section of the site",
      "statistics-answers": "Response statistics",
      en: "Eng",
      continue: "To continue, enter your account information",
      orgstructure: "Оrgstructure",
      wrong: "Incorrect login or password",
      "information employee": "Information about the employee",
      "position deleted": "Position is deleted",
      dismissal: "Dismissal",
      "employee as a manager":
        "If you want the employee in this position to be listed as a manager",
      "description structural unit": "Description of the structural unit",
      "view organization": "View the organizational structure",
      control: "Control",
      "login": "Log In",
      "login subtitle": "Use Quickskills for building courses, engaging, training and onboarding new employees.",
      "log in": "Sign in to your account",
      "login failed": "Login failed",
      "terms of use": "Terms of Use",
      "privacy policy": "Privacy Policy",
      "support": "Support",
      "contact": "Contact",
      "and": "and",
      actions: "Actions",
      "number choices": "Number choices",
      all_games: "All Courses",
      assigned: "Аssigned",
      "governing office": "Governing office",
      neutral: "Neutral",
      "send email password":
        "We will send you an email with a link that will be valid 24 hours",
      forward: "Forward",
      "editing organization": "Editing the organizational structure",
      close: "Close",
      "cancel changes": "Cancel changes",
      "first name": "First name",
      "role description": "Role description",
      january: "January",
      "score stage": "Score by stage",
      score: "Score",
      amount: "Аmount",
      err: "Error",
      "current rights": "Current rights",
      "change language": "Change the system language",
      "access error": "Access Error",
      "exist email": "Email already exists",
      search_by_org: "Search by organization",
      estimate_tooltip:
        "Estimated duration of the course, excluding video, audio, etc.",
      february: "February",
      "Rent a server QS - Medium 1 year": "Medium 1 year",
      "select product": "Select a product",
      "description limit": "The description can not exceed 100 characters",
      "select all": "Select all",
      appointments: "To appointments",
      "1 year": "1 year",
      "return in organization": "Return to the organization",
      "delete org main": "Delete organization",
      "delete unit main": "Delete unit",
      password: "Password",
      you_will_get_full_access_to_quickskills:
        "You will get full access to Quickskills and will be able to use all the features of the system:",
      "Rent a server QS - Unlim 6 month": "Unlim 6 month",
      please_login: "Please login to continue",
      "email unique": "The email address must be unique and correct",
      "creating game": "Creating course",
      "description must be":
        "The description should be as concise and informative as possible",
      "description game": "Description course",
      total: "Total",
      game_has_audio: "Course has audio",
      game_self_assigned: "Course is self assigned",
      "head of": "Head of",
      "list games": "To the list of courses",
      "code importing data":
        "This code will be used when importing and exporting data",
      october: "October",
      this_domain_name_already_exists: "This Domain name already exists",
      "not start": "Not started",
      "clone game": "Clone a course",
      "right response": "Right response",
      "maximum points": "Maximum points",
      further: "Further",
      "user not be able pass game":
        "The user will not be able to complete the assigned course.",
      status: "Status",
      "game assignments save value":
        "In this case, the deletion does not affect the values that are being passed at the moment.",
      "for assign game": "To designate course",
      needs_attention: "Needs Attention",
      enter: "Enter",
      "role description unique":
        "The description should be as concise and informative as possible",
      "view roles": "View Roles",
      "add org": "Add organization",
      "subdivision deleted": "Subdivision is deleted",
      "type search click search": "Type your search and click Search",
      registration_is_completely_free: "Registration is completely free",
      dismiss: "Dismiss",
      "generate post code": "The code will be generated automatically",
      april: "April",
      "game edited": "Course edited",
      "step 3 confirm": "Step 3 - Confirmation",
      "show dismissed": "Show dismissed",
      "for add org modal":
        "To add an organization, enter its full, abbreviated name and address",
      "only appointment": "Only by appointment",
      "full name organization": "Enter the full name of the organization",
      "save changes": "Save changes",
      "save & close": "Save",
      "description upload image":
        "To start downloading, select the image in jpeg or png format. The file size is limited to 8 megabytes",
      may: "May",
      open_in_constructor: "Open in designer",
      "best offer": "Best offer",
      sad: "Sad",
      "customer emotions": "Customer emotions at the end of the conversation",
      hr: "HR",
      edit: "Edit",
      description: "Description",
      game_has_video: "Course has videos",
      attention: "Attention",
      "confirm password": "Confirm password",
      "access to settings": "Access settings",
      admin: "Administrator",
      "domain unique": "The domain must be unique and latin",
      males: "Male",
      confirm: "Confirm",
      "start game": "Start",
      administrator: "Administrator",
      choose: "Choose",
      "game not published":
        "The course can not be published, because There is no starting question in it",
      cancel: "Cancel",
      "for editing role":
        "To edit the available rights groups, you must go to the role settings window",
      to: "To",
      'Annual license for installation on the client"s server':
        'Annual license for installation on the client"s server',
      employee: "Employee",
      november: "November",
      "without stage": "without stage",
      points: "points",
      "email limit": "must not exceed 50 characters",
      forgot: "Do not forget fill in the required fields",
      "deleting not undone": "Deleting an employee can not be undone",
      "best try": "By assignment",
      "Rent a server QS - Sales Team 6 month": "Sales Team 6 month",
      "name game": "Name",
      see_training_reports: "Build training reports",
      address: "Address",
      "create new role": "Creating a new role",
      ru: "Рус",
      back: "Back",
      offer: "Choose",
      minutes: "minutes",
      "choose your plan": "Choose your plan",
      "result game text": "results course",
      july: "July",
      "delete role": "Deleting role",
      "training assigned": "Training assigned",
      "this description game":
        "This description will be seen by the player at the start of the course",
      "remove post": "Remove post",
      "max file size": "The file size is larger than",
      "we save changes": "We saved changes",
      "middle name limit": "Middle name",
      "game deleted": "Course deleted",
      logout: "Logout",
      "position without employee": "Position without employee",
      "main page": "To the main page",
      "user exists email": "A user with this email already exists",
      "can apply":
        "You can apply any action to the selected group of employees",
      edit_profile: "Edit profile",
      search_by_positions: "Search by positions",
      day: "Day",
      "user offer": "You offered",
      "if game big":
        "If the course is big, it can take some time. We will report on the result.",
      date_started: "Started",
      step2: "Step 2 - Customize",
      "choose game": "Choose a course",
      points_for_passing: "Pointes for passing",
      year: "Year",
      "deleting game": "Deleting course",
      "exist email description":
        "Email already in use by another account. You can use log in or use the forgot password page to reset your password.",
      satisfied: "Satisfied",
      "try again": "Try uploading a smaller image",
      "first name limit": "First name can not exceed 20 characters",
      game_is_published: "Course is published",
      get: "Order",
      "middle name": "Middle name",
      interface_language: "Language",
      "page not found": "Page not found",
      "role edited": "Role edited",
      "only use 20 numbers":
        "You can only use numbers. Phone number must not exceed 20 characters",
      "change photo": "Change photo",
      "number attempts": "Numbers of attempts",
      "open constructor": "Open in designer",
      "Number of users - O/R, 50 Gb - disk space, backup once per week, price for 1 user":
        "Number of users - O/R, 50 Gb - disk space, backup once per week, price for 1 user*",
      "must confirm dismissa": "You must confirm the dismissal of employees",
      "game created": "Course is created",
      "basic parameters": "Basic parameters",
      "assign role": "Assign role",
      game_has_goods: "Course has sale of goods",
      "role user": "Role of user",
      "edit game": "Editing course",
      month: "Month",
      game: "Course",
      "selected employees": "Selected employees",
      pass: "Pass",
      create_games_courses_tests: "Create courses, tests",
      gender: "Gender",
      "tel form":
        "You can only use numbers. Phone number must not exceed 20 characters",
      delete: "Delete",
      units: "Units",
      "deletes game": "Delete course",
      "between lists": "Drag between lists",
      "name role": "Role name",
      settings: "Settings",
      "subdivision edited": "Subdivision edited",
      "game not assigned":
        "The course, available for independent passage, can not be assigned",
      "attempts left": "Attempts left",
      fired: "Fired",
      "forgot password": "Forgot password?",
      "user edited": "User edited",
      "birth date": "Date of birth",
      code: "Code",
      "Own profile editing": "Edit your profile",
      "affect the statistics of completed attempts":
        "At the same time, the deletion does not affect the statistics of completed attempts to pass.",
      unfortunately:
        "Unfortunately, you did not score the required number of points",
      "page does not exist": "Apparently this page does not exist",
      "found in list": "Find in the list...",
      "need data down":
        "To add, you need to fill in the information in the form below",
      cost: "Cost, uah",
      congratulations:
        "Congratulations! You have successfully completed the course",
      "cloning game": "We are cloning the course",
      "removing post": "Removing post",
      position: "Position",
      domain_name: "Domain name",
      "list role": "List of existing roles",
      moderator: "Administrator",
      specify: "Specify",
      fail: "Failed",
      "unique name game":
        "The name must be unique and must be at least 4 characters in length",
      "switch active role": "You may need to switch the active role",
      "text question": "Text of the question",
      "choose photo": "Choose photo",
      progress: "Progress",
      "training user": "My training",
      answers: "By answers",
      answer: "Answer",
      "generate code": "The post code we generate ourselves",
      org_name: "Organization name",
      september: "September",
      "Rent a server QS - Sales Team 1 year": "Sales Team 1 year",
      "delete employees confirm": "You must confirm the removal of employees",
      organization: "Organization",
      open_tasks: "Open Tasks",
      created: "created by",
      assign_user: "Assign, create, manage and instruct users",
      "visibility game": "Visibility of the course for ordinary users",
      selfeducation: "Self-education",
      "send letter": "Register for FREE",
      positions: "Positions",
      "report finish": "Report on the passage of the course ",
      "Number of users - unlimeded": "Number of users - unlimeded",
      surname: "Last name",
      "choosed games": "Selected courses",
      "number of points":
        "The number of points required to complete the course.",
      option: "Option",
      validity: "Validity",
      export: "Export in",
      "name struct units": "Name of the structural unit",
      search: "Search...",
      "answer player": "Answer selected by the player",
      "player response": "Player response",
      "view reports": "View reports",
      game_has_no_audio: "Course has no audio",
      profile: "Profile",
      reset: "Reset",
      "published games": "Published courses",
      "available rights": "Available rights",
      "step 1 - data": "Step 1 - General Information",
      "re-run": "Re-run",
      loading: "Loading",
      females: "Female",
      "delete happening":
        "In case of removal of the organization, all posts, departments and other structural units members to this organization",
      "search by name game": "Search by name of the course...",
      "post title": "Post Title",
      passed: "Passed",
      edit_game: "Edit course",
      "post edited": "Post edited",
      "without organization": "Without organization",
      search_by_name: "Search by full name",
      "admin page":
        'You are on the administrator"s page. To continue, select the item in the menu on the left.',
      "points quantity": "Points earned",
      game_was_started: "Course was started",
      "report statistics": "Report statistics",
      "max points": "Maximum points in the course",
      "added subdivision": "Adding a unit",
      december: "December",
      "rights own game creation and assignation":
        "The rights to create and assign your courses",
      "bad end": "it can end badly, you understand",
      "create role": "Create role",
      "game assignments delete": "The course assignments will also be deleted.",
      tel: "Phone",
      "assign game": "Assign course",
      "delete happening subvision":
        "In case of removal of the unit, all posts, departments and other structural units included in this unit will be placed at the level of the organization",
      "in constructor": "In constructor",
      reset_password: "Reset Password",
      "for create": "To create a role, enter its name and a short description",
      "created second game": "We created a second, same course",
      emotions: "Emotions",
      "not completed": "Unfinished",
      "delete unit": "Do you really want to delete the unit",
      august: "August",
      divisions: "Divisions",
      "daily backups 500": "Daily backups - 500 UAH per month",
      reports: "Reports",
      "name role unique": "The name must be unique",
      "edit subdivision": "Edit subdivision",
      all_roles: "All roles",
      division: "Division",
      "we send letter": "Please check your email to complete registration.",
      evil: "Evil",
      june: "June",
      "operation undone": "This operation cannot be undone",
      ua: "Укр",
      "create game": "Create course",
      search_by_divisions: "Search by divisions",
      you_continuing_game: "You are continuing the course",
      filters: "Filters",
      exit: "Exit",
      "percentage users": "Percentage of users",
      happy: "Happy",
      "confirm delete role": "You must confirm the deletion of role",
      "org edited": "Organization edited",
      "choose employee": "Choose employee",
      "play in assigned game": "Play of designated courses",
      "not published": "Not published",
      "estimated duration": "Estimated duration",
      subdivision: "Subdivision",
      games: "Courses",
      "get points": "Points scored by the user",
      "standard role": "This is a standard role and can not be edited",
      employees_count: "Employees",
      "title of post": "Enter title of the post",
      "statistics games": "Statistics of passed courses",
      "full name limit": "Full name must not exceed 100 characters",
      step1: "Step 1 - Select course",
      "play in game": "Passage of courses",
      "user not found": "User not found",
      "discard offer": "Discard the offer of goods",
      unlimited: "Unlimited",
      "this not cancle": "This action can not be cancel",
      "add org modal": "Adding an organization",
      "position without a department":
        "Position without a department and will be added to the organization",
      author: "Аuthor",
      "pass before": "Pass to",
      "Number of users - unlimeded, 80 Gb - disk space, backup once per week":
        "Number of users - unlimeded, 80 Gb - disk space, backup once per week*",
      "min points": "Minimum points for passing",
      game_is_not_published: "Course is not published",
      min: "min.",
      "self passing": "Self-passing",
      appointment: "By appointment",
      all_organizations: "All organizations",
      stage: "Stage",
      "password recovery": "Password recovery",
      training: "Training",
      "glue paper":
        "Do not glue the paper with the password to the monitor ...",
      "create account": "Create an account",
      "passage score": "Passage score",
      "adress limit": "The address can not exceed 100 characters",
      "step 2 choose emploee": "Step 2 - Choosing an Employee",
      assign: "Assign",
      name: "Name",
      "date appointment": "Date of appointment",
      "title upload image": "Upload new image",
      "character words": "Character words",
      "delete game": "Deleting course destination",
      all_divisions: "All divisions",
      from: "From",
      "change post": "Editing a post",
      hint: "Hints",
      email: "Email",
      role: "Roles",
      "sure delete role": "Are you sure you want to delete the role",
      "upload image": "Upload image",
      game_has_no_video: "Course has no videos",
      "full name": "Full name",
      "daily backups 900": "Daily backups - 900 UAH per month",
      "pre-guid description":
        "In order to make the work in the program as useful as possible, we propose to undergo a short training.",
      "btn start guide": "Get trained",
      skip: "Skip",
      "gd_create game": "Let's create your first course!",
      "gd_on click game": 'To do this, click on the "Create Course" button.',
      "gd_create-name-game": "Come up with a name for your course",
      "gd_description create name game":
        "We recommend choosing a name that is related to the theme of the game.",
      "gd_create-description-game": "Write a short description of your course",
      "gd_description main goal":
        "In the description, it is important to convey the main goal of the course and what the player will get in the end",
      gd_congrat: "Congratulations, a start has been made!",
      "gd_add info":
        "Now let's add the missing information. To do this, click on the pencil",
      "gd_choose bg": "Choose a background for your course",
      "gd_recommend bg":
        "We recommend choosing a monochrome background that won't be distracting.",
      "gd_choose character": "Choose a character for your course",
      gd_question:
        "It is from the virtual character that the field with the question will come.",
      "gd_choose color":
        "Choose the color of text boxes and buttons in your course",
      gd_filled: "Are all fields filled in? Are you sure? ... Cool! Move on",
      "gd_self passing": "Choose who the course will be for.",
      "gd_visual display":
        "Now let's move on to the visual display of the course",
      "gd_open course": "To do this, open the course in the Designer",
      "gd_assign course":
        "Let me show you how to assign a course to an employee",
      "gd_choose employee":
        "Choose the right employee and click on the checkmark",
      "gd_btn assign course": 'Great, now click "Assign Course"',
      "gd_choose course": "Very little left ... choose a course",
      "gd_forward step": "The setup is almost complete. Push forward",
      "gd_period course":
        "After setting the number of attempts, select the period of the course",
      "gd_create group": "Let's create your first group!",
      "gd_on click group": 'To do this, click on the "Create Group" button.',
      "gd_add employees": "Adding employees",
      "gd_on click plus employees":
        'To add an employee to the list, click on "+".',
      "gd_add courses": "Adding courses",
      "gd_on click plus courses": 'To add a course to the list, click on "+".',
      "gd_create-name-group": "Come up with a name for your group",
      "gd_description create name group":
        "We recommend choosing a title that is related to the subject of the course.",
      "gd_create-description-group": "Write a short description of your group",
      "gd_description main group":
        "In the description, it is important to convey the main goal of the group and what the student will receive as a result",
      pu_statistics: "This section contains all statistics on employees",
      "pu_statistics department":
        "Here you can see statistics for a specific department",
      "pu_statistics course":
        "Here you can see statistics for a specific course or for all at once",
      "pu_add employee":
        "You can always add a new employee and give him access to create courses",
      "pu_firing employee":
        "By firing an employee, you take away his access to courses and the program as a whole",
      "pu_specific employee":
        "You can assign course management to a specific employee",
      "pu_add organization":
        "You can add new organizations and assign employees to them",
      "pu_edit organization":
        "Edit information about the organization at any time",
      "pu_edit course":
        "You can edit the description of the courses you have created",
      "pu_design settings":
        "In the designer settings, you can add new questions, as well as change the visual part",
      "button next": "Continue",
      "button ok": "Ok",
      cards_no_zero: "The minimum that a card needs is 0",
      cards_no_hundert: "The maximum that a card needs is 100",
      cards_dont_connect: "Not every card connect to each other. See Example.",
      example: "Example",
      period_desc:
        "This field specifies the number of days to complete the course, after the date when the employee is added to the group",
      attempts_desc:
        'This field specifies the number of attempts to complete the course when the employee is added to the group. If set to "0", then the period is unlimited.',
      "error-name_group": "Name has to be at least 5 characters",
      period: "Period",
      attempts: "Attempts",
      "error-period": "Period should be at least 1 day",
      "error-attempts": "Should be at least 1 attempt",
      select_org: "Please select a organization and or a division",
      quizzes: "Quizzes",
      "back to list": "Back to list",
      "create quiz": "Create quiz",
      "delete quiz": "Delete quiz",
      "quiz properties": "Quiz properties",
      "quiz question type": "Question type",
      "single choice": "Single choice",
      "multiple choice": "Multiple choice",
      "quiz questions": "Quiz questions",
      "edit quiz question": "Edit quiz question",
      "passing score": "Passing score",
      "randomize questions": "Randomize questions",
      "don't randomize questions": "Don't randomize questions",
      "randomize answers": "Randomize answers",
      "don't randomize answers": "Don't randomize answers",
      "limit Attempts": "Limit Attempts",
      "do not limit attempts": "Do not limit attempts",
      "maximum number of attempts": "Maximum number of attempts",
      "show indicator true/false": "Show indicator true/false",
      "do not show true / false indicator":
        "Do not show true / false indicator",
      "show points": "Show points",
      "do not show points": "Do not show points",
      "allow movement to next / previous question":
        "Allow movement to next / previous question",
      "don't allow movement to next / previous question":
        "Don't allow movement to next / previous question",
      "quiz is published": "Quiz is published",
      "quiz is not published": "Quiz is not published",
      "success message": "Success message",
      "failure message": "Failure message",
      "create question": "Create question",
      weight: "Weight",
      "name question": "Name question",
      "add answer": "Add answer",
      "del answer": "Delete answer",
      "right answer": "Right answer",
    },
  },
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: resources,
    fallbackLng: "en",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      // escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      useSuspense: false,
      wait: true,
    },
  });

export default i18next;
