import * as api from '../api'
import { fromJS, List } from 'immutable'

import { normalizeGame } from '../normalizers/'

export const SENDING_SELECTED_ANSWER_ERROR = 'gameplay/SENDING_SELECTED_ANSWER_ERROR'
export const STARTING_TEST_RUN_ERROR = 'gameplay/STARTING_TEST_RUN_ERROR'
// export const STARTING_GAME_ERROR = 'gameplay/STARTING_GAME_ERROR'
export const GETTING_GAME_WARNING = 'gameplay/GETTING_GAME_WARNING'
export const GETTING_GAME_ERROR = 'gameplay/GETTING_GAME_ERROR'

const GETTING_GAME = 'gameplay/GETTING_GAME'
const GETTING_GAME_OK = 'gameplay/GETTING_GAME_OK'
const FETCHING_GAME_ERROR = 'gameplay/FETCHING_GAME_ERROR'
const STARTING_GAME = 'gameplay/STARTING_GAME'
const STARTING_GAME_OK = 'gameplay/STARTING_GAME_OK'
const STARTING_GAME_ERROR = 'gameplay/STARTING_GAME_ERROR'
const STARTING_TEST_RUN = 'gameplay/STARTING_TEST_RUN'
const STARTING_TEST_RUN_OK = 'gameplay/STARTING_TEST_RUN_OK'
const SELECT_ANSWER = 'gameplay/SELECT_ANSWER'
const SENDING_SELECTED_ANSWER = 'gameplay/SENDING_SELECTED_ANSWER'
const SENDING_SELECTED_ANSWER_OK = 'gameplay/SENDING_SELECTED_ANSWER_OK'
const RESTART_GAME = 'gameplay/RESTART_GAME'
const CLEAN_UP = 'gameplay/CLEAN_UP'
const GETTING_GAME_DATA = 'gameplay/GETTING_GAME_DATA'
const GETTING_GAME_DATA_OK = 'gameplay/GETTING_GAME_DATA_OK'
const GETTING_GAME_DATA_ERROR = 'gameplay/GETTING_GAME_DATA_ERROR'
const PREV_QUESTION = 'gameplay/PREV_QUESTION'

const initState = fromJS({
  game: {},
  gameData: {},
  questions: {},
  answers: {},
  gameSession: {},
  stages: {},
  stageQuestions: {},
  videos: {},
  audio: {},
  offers: {},
  backgrounds: {},
  characters: {},
  selectedAnswers: [],
  isGameStarted: false,
  isGameEnded: false,
  currentQuestion: '0',
  currentEmotion: '3',
  isAssigned: false,
  isLoading: false,
  lastResult: {},
  playing: false,
  errorMessage: '',
  answeredQuestions: [] 
})

export function cleanUp() {
  return { type: CLEAN_UP }
}

export function restartGame() {
  return (dispatch, getState) => {
    const startQuestion = getState().getIn(['gameplay', 'questions'])
      .find(question => question.get('is_start'))
      .get('id')
      .toString()

    dispatch({ type: RESTART_GAME, startQuestion })
  }
}

export function startTestRun() {
  return (dispatch, getState) => {
    dispatch({ type: STARTING_TEST_RUN })

    const startQuestion = getState().getIn(['gameplay', 'currentQuestion'])

    if (!startQuestion) {
      dispatch({
        type: STARTING_TEST_RUN_ERROR,
        message: 'Не найден стартовый вопрос',
        startQuestion
      })

    } else {
      dispatch({ type: STARTING_TEST_RUN_OK })
    }
  }
}

export function startGame({gameAssignmentId, gameId} = {}) {
  return (dispatch, getState) => {
    dispatch({ type: STARTING_GAME })

    return api.openGameSession({gameAssignmentId, gameId})
      .then(({ data: gameSession }) => {
        const startQuestion = gameSession.current_question_id.toString()
        const answers = new List(gameSession.answers.map(answer => answer.id))

        dispatch({
          type: STARTING_GAME_OK,
          gameSession,
          answers,
          startQuestion
        })
      },
        err => dispatch({
          type: STARTING_GAME_ERROR,
          message: err.response.data
        })
      )
  }
}

export function sendSelectedAnswer(id) {
  return (dispatch, getState) => {
    dispatch({ type: SENDING_SELECTED_ANSWER })

    const gameSessionId = getState().getIn(['gameplay', 'gameSession', 'key'])

    const payload = {
      answer: id,
      session: gameSessionId
    }

    return api.sendSelectedAnswer(payload)
      .then(
        res => dispatch({
          type: SENDING_SELECTED_ANSWER_OK,
          lastResult: res.data
        }),
        err => dispatch({
          type: SENDING_SELECTED_ANSWER_ERROR,
          message: err.message
        })
      )
  }
}

export function sendSelectedMultipleAnswer(ids) {
  return (dispatch, getState) => {
    dispatch({ type: SENDING_SELECTED_ANSWER })

    const gameSessionId = getState().getIn(['gameplay', 'gameSession', 'key'])

    const payload = {
      answer: ids,
      session: gameSessionId
    }

    return api.sendSelectedMultipleAnswer(payload)
      .then(
        res => dispatch({
          type: SENDING_SELECTED_ANSWER_OK,
          lastResult: res.data
        }),
        err => dispatch({
          type: SENDING_SELECTED_ANSWER_ERROR,
          message: err.message
        })
      )
  }
}

export function selectAnswer(answerIdList) {
  console.log('answerIdList: ', answerIdList)

  return (dispatch, getState) => {
    const answers = getState().getIn(['gameplay', 'answers'])

    var answerId = null

    const nextQuestion = answers.find(answerSearch => {
      const answerFound = answerIdList.find(a => {
        return a === answerSearch.get('id')
      })

      if(answerFound){
        answerId = answerSearch.get('id')
        return true
      }

      return false
    })

    const nextQuestionId = nextQuestion ? nextQuestion.get('connected') : null
    const currentEmotion = nextQuestion ? nextQuestion.get('emotion') : null

    const isGameEnded = (nextQuestionId === null)

    dispatch({
      type: SELECT_ANSWER,
      currentQuestion: nextQuestionId ? nextQuestionId.toString() : '0',
      answerId,
      currentEmotion,

      isGameEnded
    })
  }
}

export function getGame({
    id,
    is_assignment=false,
    isQuiz=false
  } = {}
) {
  return dispatch => {
    dispatch({
      type: GETTING_GAME
    })

    var getGameProxy = api.getGame

    if(is_assignment && !isQuiz){
      getGameProxy = api.getGameFromGameAssignment
    }
    else if(is_assignment && isQuiz){
      getGameProxy = api.getQuizAssignment
    }
    else if(isQuiz){
      getGameProxy = api.getQuiz
    }

    console.log('getGameProxy: ', getGameProxy)

    return getGameProxy(id)
      .then(res => {
        return {
          normalized: normalizeGame(res.data),
          integration: res.data.integration,
          background: res.data.background,
          character: res.data.character,
        }
      })
      .then(data => {
        if (!data.normalized.questions) {
          return dispatch({
            type: GETTING_GAME_ERROR,
            message: 'В игре нет вопросов. Игру нельзя пройти.'
          })
        }

        const startQuestion = fromJS(data.normalized.questions)
          .find(question => question.get('is_start'))

        if (!startQuestion) {
          dispatch({
            type: GETTING_GAME_WARNING,
            message: 'В игре нет стартового вопроса. Без него игра не может быть начата.'
          })
        }

        dispatch({
          type: GETTING_GAME_OK,
          ...data.normalized,
          game: data.normalized.game[Object.keys(data.normalized.game)[0]],
          integration: data.integration,
          background: data.background,
          character: data.character,
          startQuestion: startQuestion
          ? startQuestion.get('id').toString()
          : 0
        })
      },
        err => dispatch({
          type: FETCHING_GAME_ERROR,
          errorMessage: 'Error message'
        })
      )
  }
}


export function getGameData(id) {
  return (dispatch, getState) => {
    dispatch({ type: GETTING_GAME_DATA })

    return api.getGameData(id)
      .then(
        res => {
          console.log('RES', res.data)
          return dispatch({
            type: GETTING_GAME_DATA_OK,
            gameData: res.data
          })
        },
        err => dispatch({
          type: GETTING_GAME_DATA_ERROR,
          message: err.message
        })
      )
  }
}

export function prevQuestion(prev) {
  return (dispatch) => {
    dispatch({ type: PREV_QUESTION, currentQuestion: prev})
  }
}

export default function gameplay(state = initState, action = null) {
  switch (action.type) {
    case GETTING_GAME_ERROR:
    case GETTING_GAME_DATA_ERROR:
      return state.set('isLoading', false)

    case STARTING_GAME:
    case GETTING_GAME:
    case GETTING_GAME_DATA:
      return state.set('isLoading', true)

    case STARTING_TEST_RUN_OK:
      return state.set('isGameStarted', true)

    case STARTING_GAME_OK:
      return state
        .set('isGameStarted', true)
        .update('gameSession', gameSession => gameSession.merge(action.gameSession))
        .set('selectedAnswers', action.answers)
        .set('currentQuestion', action.startQuestion)

    case STARTING_GAME_ERROR:
      return state.set('errorMessage', action.message)

    case CLEAN_UP:
      return initState

    case RESTART_GAME:
      return state
        .set('isGameStarted', false)
        .update('gameSession', gameSession => gameSession.clear())
        .update('selectedAnswers', selectedAnswers => selectedAnswers.clear())
        .update('lastResult', lastResult => lastResult.clear())
        .set('isGameEnded', false)
        .set('currentQuestion', action.startQuestion)
        .update('answeredQuestions', answeredQuestions => answeredQuestions.clear())
        .update('answeredQuestions', answeredQuestions => answeredQuestions.push(action.startQuestion))

    case PREV_QUESTION:
      return state
        .set('currentQuestion', action.currentQuestion)
        .update('answeredQuestions', answeredQuestions => answeredQuestions.remove(- 1))

    case SELECT_ANSWER:
      return state
        .set('currentQuestion', action.currentQuestion)
        .set('currentEmotion', action.currentEmotion)
        .update('selectedAnswers', selectedAnswers => selectedAnswers.push(action.answerId))
        .update('answeredQuestions', answeredQuestions => answeredQuestions.push(action.currentQuestion))
        .set('isGameEnded', action.isGameEnded)
        .set('stageQuestions', action.stageQuestions)

    case GETTING_GAME_OK:
      return state
        .update('questions', questions => questions.merge(action.questions))
        .update('answers', answers => answers.merge(action.answers))
        .update('offers', offers => offers.merge(action.offers))
        .update('videos', videos => videos.merge(action.videos))
        .update('audio', audio => audio.merge(action.audio))
        .update('characters', characters => characters.merge(action.characters))
        .update('backgrounds', backgrounds => backgrounds.merge(action.backgrounds))
        .update('game', game => game.merge(action.game))
        .update('stages', stages => stages.merge(action.stages))
        .set('currentQuestion', action.startQuestion)
        .set('game_background', action.background)
        .set('game_character', action.character)
        .set('isLoading', false)
        .set('integration', action.integration)
        .set('playing', true)
        .update('answeredQuestions', answeredQuestions => answeredQuestions.push(action.startQuestion))


    case GETTING_GAME_DATA_OK:
      return state
        .update('gameData', gameData => gameData.merge(action.gameData))
        .set('isLoading', false)

    case SENDING_SELECTED_ANSWER_OK:
      return state
        .update('lastResult', lastResult => lastResult.merge(action.lastResult))

    case FETCHING_GAME_ERROR:
      return state
        .set('errorMessage', action.errorMessage)
    default:
      return state
  }
}
