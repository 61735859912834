import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import { IntroGuid } from './IntroGuid';

export default function (ComposedComponent) {
    class IntroVideo extends Component {
        state = {
            showModal: false,
            modalPage: 1,
            showFirstGuideStep: false,
        }

        loadModal() {
            this.setState(prevState => ({
                showModal: !prevState.showModal
            }))
        }

        componentDidMount() {
            this.timer = setTimeout(() => this.loadModal(), 3000)
        }

        componentWillUnmount() {
            clearTimeout(this.timer);
        }

        nextPage = () => {
            this.setState(prevState => ({ modalPage: prevState.modalPage + 1 }))
        }

        toggleModal = () => {
            this.setState({
                showModal: !this.state.showModal,
            })
        }

        renderModal = (showModal, modalPage, showIntro, dispatch, t) => {
            return (
                <IntroGuid
                    showModal={!localStorage.getItem('alreadyVisited') && localStorage.getItem('isAdmin') === 'true'}
                    toggleModal={this.toggleModal}
                    dispatch={dispatch}
                />
            )
        }

        render() {
            const {
                showModal,
                modalPage,
            } = this.state

            const {
                isAdmin,
                dispatch,
                t
            } = this.props

            const firstVisit = localStorage.getItem('alreadyVisited') === null;
            const showIntro = firstVisit && isAdmin;

            return (
                <div>
                    {
                      this.renderModal(
                        showModal,
                        modalPage,
                        showIntro,
                        dispatch,
                        t,
                        this.toggleGuideFlags
                      )
                    }
                    <ComposedComponent {...this.props} toggleGuideFlags={this.toggleGuideFlags} guideFlags={this.state.guideFlags} />
                </div>
            );
        }
    }

    return withTranslation('translations')(IntroVideo)
}
