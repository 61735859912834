import React from "react";
import { Helmet } from "react-helmet";

export default function HelmetComponent({scripts}) {
  const inAdminRole = localStorage.getItem('inAdminRole') === 'true'

  const adminScripts = scripts && scripts.filter(
    script => {
      return script.get('admin') && inAdminRole
    }
  ).map(
    script => <script>{script.get('text')}</script>
  )

  const nonAdminScripts = scripts && scripts.filter(
    script => {
      return !script.get('admin')
    }
  ).map(
    script => <script>{script.get('text')}</script>
  )

  return (
      <Helmet>
        { adminScripts }
        { nonAdminScripts }
      </Helmet>
  );
}
