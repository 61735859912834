import React from "react";
import { Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import BasePage from "../layout/BasePage";
import Main from "../../Main/containers";
import PageLoader from "../common/PageLoader";

import { baseRoutes, routes } from "./routes";
import AppRoute from "../components/AppRoute";

const listofPages = [
  "register",
  "login",
  "confirm",
  "recover",
  "password_reset",
  "email",
  "set_token",
];

let start_path = "/user/assignments";

if (localStorage.getItem("inAdminRole")) {
  start_path = "/administrator/dashboard";
}

const Routes = () => {
  const location = useLocation();
  const currentKey = location?.pathname?.split("/")[1];
  const currentToken =
    currentKey === "set_token" && location?.pathname?.split("/")[2];

  if (listofPages.includes(currentKey)) {
    return (
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route
              path="/set_token/:token"
              render={(props) => {
                localStorage.setItem("token", currentToken);
                
                console.log('currentToken', currentToken)

                const next = new URLSearchParams(window.location.search).get('next')
                
                console.log('NEXT: =========== ', next)

                if(next){
                    window.location.href = next
                }


                return <Redirect to="/" />;
              }}
            />
            {baseRoutes.map((route) => (
              <AppRoute
                key={route.path}
                exact={route.isExact}
                path={route.path}
                component={route.component}
                isPrivate={route.isPrivate}
              />
            ))}
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      <Main>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Redirect exact from="/" to={start_path} />
            {routes.map((route) => (
              <AppRoute
                key={route.path}
                exact={route.isExact}
                path={route.path}
                component={route.component}
                isPrivate={route.isPrivate}
                isAdmin={route.isAdmin}
              />
            ))}
          </Switch>
        </Suspense>
      </Main>
    );
  }
};

export default Routes;
