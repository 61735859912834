import * as api from '../api'

import { fromJS } from 'immutable'

const GETTING_REPORT_DATA = 'reports/answersReport/GETTING_REPORT_DATA'
const GETTING_REPORT_DATA_OK = 'reports/answersReport/GETTING_REPORT_DATA_OK'

const initialState = fromJS({
    statistics: [],
    isLoading: false
})

export function getAnswersStatistics(id) {
    return dispatch => {
        dispatch({ type: GETTING_REPORT_DATA })

        return api.getAnswersStatistics(id)
            .then(({ data }) => dispatch({
                type: GETTING_REPORT_DATA_OK,
                statistics: data
            }))
    }
}

export default function answersReport(state = initialState, action = null) {
    switch (action.type) {
    case GETTING_REPORT_DATA:
        return state
            .set('isLoading', true)

    case GETTING_REPORT_DATA_OK:
        return state
            .update('statistics', statistics => statistics.clear().merge(action.statistics))
            .set('isLoading', false)

    default:
        return state
            .update('statistics',  statistics => statistics.clear())
            .set('isLoading', false)
    }
}
