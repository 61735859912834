import { normalize, schema } from 'normalizr'

const permission = new schema.Entity('permissions')
const division = new schema.Entity('divisions')
const position = new schema.Entity('positions', { division })

const role = new schema.Entity('roles', {
    permission_groups: [ permission ]
})

const employee = new schema.Entity('employees', {
    user_position: [ position ],
    role
})

const game = new schema.Entity('games')
const assignment = new schema.Entity('assignments')

export function normalizeAssignments(response) {
    return normalize(response, [ assignment ]).entities
}

export function normalizeAssignment(response) {
    return normalize(response, assignment).entities
}
