import { lazy } from "react";

const Register = lazy(() => import("../pages/Register"));
const Login = lazy(() => import("../pages/Login"));
const Confirm = lazy(() => import("../pages/Confirm"));
const Recover = lazy(() => import("../pages/Recover"));
const Email = lazy(() => import("../pages/Email"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Browser = lazy(() => import("../../Errors/Browser"));

const Billing = lazy(() => import("../../Main/containers/Billing"));
const UserAssignments = lazy(() =>
  import("../../Training/containers/UserGameAssignments")
);
const SelfEducation = lazy(() =>
  import("../../Training/containers/SelfEducation")
);
const UserProfile = lazy(() => import("../../Users/containers/User"));
const Gameplay = lazy(() => import("../../Gameplay/containers/"));
const GamesReport = lazy(() => import("../../Reports/containers/GamesReport"));
const AnswersGamesReport = lazy(() =>
  import("../../Reports/components/GamesWrapper")
);
const AnswersReport = lazy(() =>
  import("../../Reports/containers/AnswersReport")
);
const Dashboard = lazy(() => import("../../Dashboard/containers"));
const Position = lazy(() => import("../../OrgStructure/containers/Position"));
const Users = lazy(() => import("../../Users/components"));
const User = lazy(() => import("../../Users/containers/User"));
const OrgStructure = lazy(() => import("../../OrgStructure/containers/"));
const Settings = lazy(() => import("../../Settings/containers/Settings"));
const Organization = lazy(() =>
  import("../../OrgStructure/containers/Organization")
);
const Roles = lazy(() => import("../../Roles/containers/"));
const Role = lazy(() => import("../../Roles/containers/Role"));
const Games = lazy(() => import("../../Training/containers/Games"));
const Game = lazy(() => import("../../Training/containers/Game"));
const Group = lazy(() => import("../../Groups/containers/Group"));
const GroupEdit = lazy(() => import("../../Groups/components/GroupEdit"));

const Quizzes = lazy(() => import("../../Training/Quiz/containers/Quizzes"));
const UpdateQuiz = lazy(() =>
  import("../../Training/Quiz/containers/QuizEdit")
);
const CreateQuiz = lazy(() =>
  import("../../Training/Quiz/containers/CreateQuiz")
);
const CreateQuestion = lazy(() =>
  import("../../Training/Quiz/containers/CreateQuestion")
);
const UpdateQuestion = lazy(() =>
  import("../../Training/Quiz/containers/QuestionEdit")
);

export const baseRoutes = [
  {
    path: "/browser",
    component: Browser,
    isPrivate: false,
  },
  {
    path: "/register",
    component: Register,
    isPrivate: false,
  },
  {
    path: "/login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "/confirm/:key",
    component: Confirm,
    isPrivate: false,
  },
  {
    path: "/recover",
    component: Recover,
    isPrivate: false,
  },
  {
    path: "/password_reset/:key",
    component: Confirm,
    isPrivate: false,
  },
  {
    path: "/email",
    component: Email,
    isPrivate: false,
  },
];

export const routes = [
  {
    path: "/administrator/billing",
    component: Billing,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/user/assignments",
    component: UserAssignments,
    isPrivate: true,
  },
  {
    path: "/user/self-education",
    component: SelfEducation,
    isPrivate: true,
  },
  {
    path: "/user/profile",
    component: UserProfile,
    isPrivate: true,
  },
  {
    path: "/user/profile/:userId",
    component: UserProfile,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/public/play/:gameId",
    component: Gameplay,
    isPrivate: false,
  },
  {
    isExact: true,
    path: "/gameplay/:gameAssignmentId",
    component: Gameplay,
    isPrivate: true,
  },
  {
    path: "/gameplay/dry/:gameId",
    component: Gameplay,
    isPrivate: true,
  },
  {
    path: "/gameplay/self/:gameId",
    component: Gameplay,
    isPrivate: true,
  },
  {
    path: "/quiz/dry/:gameId",
    component: Gameplay,
    isPrivate: true,
  },
  {
    path: "/quiz/:gameAssignmentId",
    component: Gameplay,
    isPrivate: true,
  },
  {
    path: "/integration/play/:gameAssignmentId",
    component: Gameplay,
    isPrivate: true,
  },
  {
    path: "/administrator/dashboard",
    component: Dashboard,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/reports/games",
    component: GamesReport,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/reports/answers",
    component: AnswersGamesReport,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/reports/answers/:gameId",
    component: AnswersReport,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/positions/:posId",
    component: Position,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/users",
    component: Users,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/users/:userId",
    component: User,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/organizations",
    component: OrgStructure,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/organizations/:orgId",
    component: Organization,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/roles",
    component: Roles,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/roles/:roleId",
    component: Role,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/games",
    component: Games,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/games/:gameId",
    component: Game,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/games/create",
    component: Games,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/groups",
    component: Group,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/groups/:id",
    component: GroupEdit,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/groups/:id/view",
    component: GroupEdit,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/quizzes",
    component: Quizzes,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/quizzes/create",
    component: CreateQuiz,
    isPrivate: true,
    isAdmin: true,
  },
  {
    isExact: true,
    path: "/administrator/quizzes/:id",
    component: UpdateQuiz,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/quizzes/:id/questions",
    component: UpdateQuiz,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/quizzes/:id/createquestion",
    component: CreateQuestion,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/quizzes/:quizId/question/:id",
    component: UpdateQuestion,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/administrator/settings",
    component: Settings,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/*",
    component: NotFound,
    isPrivate: true,
  },
];
