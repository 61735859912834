import axios from 'axios'

/*
*   replace baseUrl on '' for prod
*/

export const getBaseUrl = () => {
  return process.env.REACT_APP_BASE_URL
}

export const baseUrl = getBaseUrl()

export const getBaseApiUrl = () => {
    return `${baseUrl}/api`
}

export const baseApiUrl = getBaseApiUrl()

const getHeaders = (headers) => {
    const token = localStorage.getItem('token')

    if(!headers){
      headers = {
          'Content-Type': 'application/json'
      }
    }

    if (token) {
      headers.Authorization = `JWT ${token}`
    }

    return headers
}

const getUrl = (url, raw=false) => {
  if(raw){
    return url;
  }

  return `${baseApiUrl}${url}`
}

export const methods = {
    'upload': function(url, data, method) {
      return axios({
          url: getUrl(url),
          method: method,
          responseType: 'json',
          headers: getHeaders({
              'Content-Type': 'multipart/form-data'
          }),
          data
      })
    },

    'download': function(url) {
      return axios({
          url: getUrl(url),
          method: 'get',
          responseType: 'blob',
          headers: getHeaders(),
      })
    },

    'post': function (url, data, raw=false) {
        return axios({
            url: getUrl(url, raw),
            method: 'post',
            responseType: 'json',
            headers: getHeaders(),
            data
        })
    },
    'put': function (url, data, raw=false) {
        return axios({
            url: getUrl(url),
            method: 'put',
            responseType: 'json',
            headers: getHeaders(),
            data
        })
    },
    'patch': function (url, data, raw=false) {
        return axios({
            url: getUrl(url),
            method: 'patch',
            responseType: 'json',
            headers: getHeaders(),
            data
        })
    },
    'delete': function (url, data = []) {
        return axios({
            url: getUrl(url),
            method: 'delete',
            responseType: 'json',
            headers: getHeaders(),
            data
        })
    },
    'get': function (url, raw=false) {
        return axios({
            url: getUrl(url, raw),
            method: 'get',
            responseType: 'json',
            headers: getHeaders()
        })
    }
}
