let user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : "";

let token = localStorage.getItem("token") || ''

export const initialState = {
  user: "" || user,
  token: "" || token,
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  console.log("LOG ---", action);
  switch (action.type) {
    case "REQUEST_REGISTRATION":
      return {
        ...initialState,
        loading: true,
      };

    case "REGISTRATION_SUCCESS":
      return {
        ...initialState,
        loading: false,
      };

    case "REGISTRATION_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case "REQUEST_REGISTRATIOM_CONFIRM":
      return {
        ...initialState,
        loading: true,
      };
      
    case "REGISTRATION_CONFIRM_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };

    case "REGISTRATION_CONFIRM_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };

    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };

    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: "",
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case "REQUEST_RECOVER_PASSWORD":
      return {
        ...initialState,
        loading: true,
      };

    case "RECOVER_PASSWORD_SUCCESS":
      return {
        ...initialState,
        loading: false,
      };

    case "RECOVER_PASSWORD_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case "REQUEST_RECOVER_CONFIRM":
      return {
        ...initialState,
        loading: true,
      };
      
    case "RECOVER_CONFIRM_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };

    case "RECOVER_CONFIRM_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case "RESET_MESSAGE_ERROR":
      return {
        ...initialState,
        errorMessage: null
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
