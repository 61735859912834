import React, { useEffect } from "react";
import { Menu, Space, Avatar, Dropdown, Radio, Typography } from "antd";
import {
  UserOutlined,
  CaretDownFilled,
  LogoutOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { logout, useAuthDispatch } from "../../App/context";

const { Text } = Typography;

const GlobalNavigation = (props) => {
  const { user, logOut, switchRole, inAdminRole, isAdmin } = props;
  const dispatch = useAuthDispatch()

  const { t } = useTranslation();
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (inAdminRole) {
      setValue("admin");
    } else {
      setValue("user");
    }
  }, [inAdminRole]);

  function onChange(e) {
    setValue(e.target.value);
    switchRole();
  }

  const menu = (
    <Menu>
      {isAdmin && (
        <>
          <Menu.Item key="0">
            <Space direction="vertical">
              <Text strong>
                Hello, {user.get("last_name")} {user.get("first_name")}
              </Text>
              <Text strong>Role:</Text>
              <Space direction="vertical">
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value="user">Learner</Radio>
                  <br />
                  <Radio value="admin">Administrator</Radio>
                </Radio.Group>
              </Space>
            </Space>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="1">
            <Space direction="vertical">
              <Link to="/administrator/billing/">
                <Space style={{ verticalAlign: "middle" }}>
                  <DollarOutlined style={{ fontSize: "18px", color: "#000" }} />
                  <Text>{t("Plan and Billing")}</Text>
                </Space>
              </Link>
            </Space>
          </Menu.Item>
          <Menu.Item key="2">
            <Space style={{ verticalAlign: "middle" }}>
              <Link to={`/user/profile/${user.get("id")}`}>
                <Space>
                  <UserOutlined style={{ fontSize: "18px", color: "#000" }} />
                  <Text>{t("edit_profile")}</Text>
                </Space>
              </Link>
            </Space>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item key="3">
        <Space onClick={() => logout(dispatch)}>
          <LogoutOutlined style={{ fontSize: "18px" }} />
          <Text>Logout</Text>
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
      <Space style={{ cursor: "pointer" }}>
        <Avatar
          src={user.get("simple_profile_pic")}
          shape="square"
          size="large"
          icon={<UserOutlined />}
        />
        <CaretDownFilled className="global-menu-dropdown-icon" />
      </Space>
    </Dropdown>
  );
};

export default GlobalNavigation;
