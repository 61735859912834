import * as api from "../api";
import { fromJS } from "immutable";

import { normalizeAssignments } from "../normalizers/normalizeGameAssignments";

export const GETTING_GAME_ASSIGNMENTS_OK =
  "training/assignments/GETTING_GAME_ASSIGNMENTS_OK";
export const CREATING_GAME_ASSIGNMENTS_OK =
  "training/assignments/CREATING_GAME_ASSIGNMENTS_OK";
export const CREATING_GAME_ASSIGNMENTS_ERROR =
  "training/assignments/CREATING_GAME_ASSIGNMENTS_ERROR";
export const CREATING_GAME_ASSIGNMENTS_ERR =
  "training/assignments/CREATING_GAME_ASSIGNMENTS_ERR";

const CREATING_GAME_ASSIGNMENTS =
  "training/assignments/CREATING_GAME_ASSIGNMENTS";
const GETTING_GAME_ASSIGNMENTS =
  "training/assignments/GETTING_GAME_ASSIGNMENTS";
const DELETING_GAME_ASSIGNMENT =
  "training/assignments/DELETING_GAME_ASSIGNMENT";
const DELETING_GAME_ASSIGNMENT_OK =
  "training/assignments/DELETING_GAME_ASSIGNMENT_OK";

const initialState = fromJS({
  assignments: {},
  page: 1,
  nextPage: null,
  prevPage: null,
  perPage: 0,
  total: 0,
  totalPages: 0,
  isLoading: false,
});

export function deleteAssignment(id) {
  return (dispatch) => {
    dispatch({ type: DELETING_GAME_ASSIGNMENT });

    return api
      .deleteAssignment(id)
      .then(() => dispatch({ type: DELETING_GAME_ASSIGNMENT_OK, id }));
  };
}

export function getGameAssignments({
  page = 1,
  user_id,
  available,
  game,
  date_from,
  date_to,
} = {}) {
  return (dispatch) => {
    dispatch({ type: GETTING_GAME_ASSIGNMENTS });

    return api
      .getGameAssignments({ user_id: user_id, page: page })
      .then((res) => {
        const normalized = normalizeAssignments(res.data.results);

        dispatch({
          type: GETTING_GAME_ASSIGNMENTS_OK,
          assignments: normalized.assignments,
          page: res.data.page,
          perPage: res.data.per_page,
          total: res.data.total,
          totalPages: res.data.total_pages,
        });
      });
  };
}

export function createAssignments(period, attempts) {
  return (dispatch, getState) => {
    dispatch({ type: CREATING_GAME_ASSIGNMENTS });

    const games = getState()
      .getIn(["training", "games", "selected"])
      .toArray()
      .map((user) => user.get("id"));

    const users = getState()
      .getIn(["users", "selected"])
      .toArray()
      .map((user) => user.get("id"));

    const domain = getState().getIn(["viewer", "domain"]);

    const payload = Object.assign(
      {},
      {
        start_date: period.from.toISOString(),
        end_date: period.to.toISOString(),
      },
      { attempts_total: attempts },
      { domain },
      { users },
      { games }
    );

    return api.createAssignments(payload).then(
      (res) => {
        if (res.data?.unassigned.length === 0) {
          dispatch({ type: CREATING_GAME_ASSIGNMENTS_OK, ...res.data });
        } else {
          dispatch({
            type: CREATING_GAME_ASSIGNMENTS_ERR,
          });
        }
      },
      (err) =>
        dispatch({
          type: CREATING_GAME_ASSIGNMENTS_ERROR,
          message: err.message,
        })
    );
  };
}

export default function assignments(state = initialState, action = null) {
  switch (action.type) {
    case CREATING_GAME_ASSIGNMENTS:
    case GETTING_GAME_ASSIGNMENTS:
    case DELETING_GAME_ASSIGNMENT:
      return state.set("isLoading", true);

    case DELETING_GAME_ASSIGNMENT_OK:
      return state
        .update("assignments", (assignments) => assignments.delete(action.id))
        .update("assignments", (assignments) =>
          assignments.merge(action.assignments)
        )
        .set("isLoading", false);
    case CREATING_GAME_ASSIGNMENTS_ERROR:
    case GETTING_GAME_ASSIGNMENTS_OK:
      return state
        .update("assignments", (assignments) =>
          assignments.clear().merge(action.assignments)
        )
        .set("isLoading", false)
        .set("nextPage", action.nextPage)
        .set("prevPage", action.prevPage)
        .set("perPage", action.perPage)
        .set("total", action.total)
        .set("page", action.page)
        .set("totalPages", action.totalPages);
    case CREATING_GAME_ASSIGNMENTS_OK:
      return state.set("isLoading", false);

    default:
      return state;
  }
}
