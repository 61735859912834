import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'glamor';


import {  withTranslation } from 'react-i18next'


class NotifierComponent extends React.Component {

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.notification !== nextProps.notification && !nextProps.notification.isEmpty()) {
            this.addNotification(nextProps.notification)
            this.props.markShow()
        }
    }  
    
    closeAfter7 = (notification, t) => {
        let message = t(`${notification.get('message')}`)
        let title = t(`${notification.get('title')}`)
        return toast[notification.get('type')](
            <div>
                <p style={{ marginBottom: '0' }}>{message}</p>
                <p style={{ marginBottom: '0' }}>{title}</p>
            </div>, 
            { 
                bodyClassName: css({
                    fontSize: '16px',
                    paddingLeft: '10px'
                }),
                autoClose: 7000 
            }
        );
    }


    addNotification = (notification) => {
        const { t } = this.props

        this.closeAfter7(notification, t)
    }

    render(){
        return (
            <div>
                <ToastContainer autoClose={8000} />
            </div>
        );
    }
}

export default withTranslation ('translations')(NotifierComponent)

