import React, { useLayoutEffect } from "react";
import { Layout } from "antd";
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { useAuthDispatch, resetMessageError } from '../../context'

import Header from "./Header";
import Footer from "./Footer";

const { Content } = Layout

const Base = (props) => {
  const { t } = useTranslation();
  let location = useLocation();
  const dispatch = useAuthDispatch();

  useLayoutEffect(() => {
    resetMessageError(dispatch);
  });

  function renderHeaderTitle(location) {
    switch (location.pathname) {
      case "/register":
        return {
          title: t("registration"),
          routeTitle: t("login"),
          question: t("already have an account"),
          route: "login",
        };

      case "/login":
        return {
          title: t("login"),
          routeTitle: t("sign up"),
          question: t("have an account"),
          route: "register",
        };

      case "/recover":
        return {
          title: t("password recovery"),
          routeTitle: t("sign up"),
          question: t("have an account"),
          route: "register",
        };

      case "/email":
        return {
          title: "Completion of registration",
          routeTitle: t("login"),
          question: "",
          route: "login",
        };

      default:
        return t("login");
    }
  }
  return (
    <Layout className="base-page_modified-ant-layout">
      <Header params={renderHeaderTitle(location)} />
      <Content>{props.children}</Content>
      <Footer />
    </Layout>
  );
}; 

export default Base;