import * as api from "../api";
import { fromJS } from "immutable";

import { history } from "../../history";

import { checkPermission } from "../../helpers";
import { normalizeEmployee } from "../../Users/normalizers/";

export const LOADING_ME_OK = "viewer/LOADING_ME_OK";
export const REGISTRATION_ERROR = "viewer/REGISTRATION_ERROR";
export const REGISTRATION_OK = "viewer/REGISTRATION_OK";
export const RESETING_PASSWORD_ERROR = "viewer/RESETING_PASSWORD_ERROR";
export const LOG_IN = "viewer/LOG_IN";
export const LOG_IN_OK = "viewer/LOG_IN_OK";
export const LOG_IN_ERROR = "viewer/LOG_IN_ERROR";

const LOADING_ME = "viewer/LOADING_ME";
const CHANGING_ROLE = "viewer/CHANGING_ROLE";
const CHANGINGING_DIRECTORY = "viewer/CHANGINGING_DIRECTORY";
const CHANGINGING_DIRECTORY_OK = "viewer/CHANGINGING_DIRECTORY_OK";
const LOG_OUT = "viewer/LOG_OUT";
const REGISTRATION = "viewer/REGISTRATION";
const RESETING_PASSWORD = "viewer/RESETING_PASSWORD";
const RESETING_PASSWORD_OK = "viewer/RESETING_PASSWORD_OK";
const RESETING_PASSWORD_NO_SUCH_USER = "viewer/RESETING_PASSWORD_NO_SUCH_USER";
const UPDATING_CURRENT_USER = "viewer/UPDATING_CURRENT_USER";
const GET_SETTINGS_ENTRANCE = "settings/GET_SETTINGS_ENTRANCE";
const GET_SETTINGS_ENTRANCE_OK = "settings/GET_SETTINGS_ENTRANCE_OK";
const DELETED_HINTS = "viewer/DELETED_HINTS";
const DELETED_HINTS_OK = "viewer/DELETED_HINTS_OK";
const CHECK_KEY = "CHECK_KEY";
const SEND_BUGS = "SEND_BUGS";
const LOG_IN_GAME = "viewer/LOG_IN_GAME";
const LOG_IN_GAME_OK = "viewer/LOG_IN_GAME_OK";
const LOG_IN_GAME_ERROR = "viewer/LOG_IN_GAME_ERROR";

const BILLING_CHARGE = "BILLING_CHARGE";
export const BILLING_CHARGE_OK = "BILLING_CHARGE_OK";
const BILLING_CHARGE_ERROR = "BILLING_CHARGE_ERROR";

const domain = window.location.href.split("/")[2].split(".")[0];

export const initialState = fromJS({
  currentUser: {},
  isAuthenticated: localStorage.getItem("token") ? true : false,
  isRegistered: false,
  success: false,
  error_type: null,
  error_message: null,
  inAdminRole: false,
  isAdmin: false,
  isLoading: false,
  isLoadingModal: false,
  showModal: false,
  isError: false,
  errorMessage: "",
  existEmail: false,
  isSuccessReg: false,
  redirectUrl: "",
  setToken: "",
  domain: domain === "undefined" ? "localhost" : domain,
});

export function billingCharge(currentPlan, billingData, billingPeriod) {
  return (dispatch) => {
    dispatch({ type: BILLING_CHARGE });
    return api.billingCharge(currentPlan, billingData, billingPeriod).then(
      (res) => {
        if (res.data.success) {
          dispatch({
            type: BILLING_CHARGE_OK,
            payload: res.data.payload,
          });
          return res;
        }
        dispatch({ type: BILLING_CHARGE_ERROR });
        return res;
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function logInGame(url, credentials) {
  return (dispatch) => {
    dispatch({ type: LOG_IN_GAME });

    return api.external_authenticate(url, credentials).then(
      (res) => {
        localStorage.setItem("token", res.data.token);

        let entities = normalizeEmployee(res.data.user);
        let inAdminRole = false;
        let currentUser = res.data.user;

        return dispatch({
          type: LOG_IN_GAME_OK,
          ...entities,
          payload: res.data.payload,
          currentUser: currentUser,
        });
      },
      (err) => {
        return dispatch({ type: LOG_IN_GAME_ERROR });
      }
    );
  };
}

export function reset(formValues) {
  return (dispatch) => {
    dispatch({ type: RESETING_PASSWORD });

    return api
      .resetPassword(formValues)
      .then((response) => {
        if (response.status === 204) {
          dispatch({ type: RESETING_PASSWORD_NO_SUCH_USER });
        } else {
          dispatch({ type: RESETING_PASSWORD_OK });
        }
      })
      .catch((error) => dispatch({ type: RESETING_PASSWORD_ERROR }));
  };
}

export function logOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("canEditGames");
  localStorage.removeItem("currentUserId");
  localStorage.removeItem("inAdminRole");
  localStorage.removeItem("isAdmin");

  return { type: LOG_OUT };
}

export function logIn(credentials, keyUrl) {
  return (dispatch) => {
    dispatch({ type: LOG_IN });

    return api
      .authenticate(credentials)
      .then((response) => {
        if (response.data.token && response.data.redirect_url) {
          if (response.data.set_token) {
            localStorage.setItem("token", response.data.token);
          }

          window.location.assign(response.data.redirect_url);
        }

        return dispatch({
          type: LOG_IN_OK,
          payload: response.data.payload,
          redirectUrl: response.data.redirect_url,
          setToken: response.data.set_token,
        });
      })
      .catch((error) => {
        return dispatch({
          type: LOG_IN_ERROR,
          errorMessage: error.response.data.message,
        });
      });
  };
}

export function logInConfirm(password, key) {
  return (dispatch) => {
    dispatch({ type: LOG_IN });

    return api.sendConfInfo(password, key).then(
      (res) => {
        localStorage.setItem("token", res.data.token);
        history.push("/user/assignments");
        dispatch({
          type: LOG_IN_OK,
          payload: res.data.payload,
        });
      },
      (err) => {
        if (err.response.status === 400) {
          return dispatch({
            type: LOG_IN_ERROR,
            errorMessage: "Вы уже были зарегистрированы!",
          });
        } else {
          return dispatch({
            type: LOG_IN_ERROR,
            errorMessage:
              "К сожалению была прервана связь с интрнетом, попробуйте через 5 минут.",
          });
        }
      }
    );
  };
}
export function logInUserConfirm(password, key) {
  return (dispatch) => {
    dispatch({ type: LOG_IN });

    return api.passwordReset(password, key).then(
      (res) => {
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: LOG_IN_OK,
          payload: res.data.payload,
        });
        history.push("/user/assignments");
      },
      (err) => {
        return dispatch({ type: LOG_IN_ERROR });
      }
    );
  };
}

export function sendBugs(bugs, email) {
  return (dispatch) => {
    dispatch({ type: SEND_BUGS });

    return api.sendBugs(bugs, email);
    // .then(res => {
    //        return dispatch({ type: SEND_BUGS_OK })
    //     },
    //     err => {
    //         return dispatch({ type: SEND_BUGS_ERROR })
    //     }
    // )
  };
}

export function updateCurrentUser(newUser) {
  return {
    type: UPDATING_CURRENT_USER,
    newUser,
  };
}
export function checkKey(key) {
  return (dispatch) => {
    dispatch({ type: CHECK_KEY });

    return api.checkKey(key).then((res) => console.log(res));
  };
}
export function registerUser(formValues) {
  return (dispatch) => {
    dispatch({ type: REGISTRATION });

    return api
      .registrate(formValues)
      .then((res) => {
        return dispatch({
          type: REGISTRATION_OK,
          error_message: null,
          error_type: null,
          isRegistered: res.data.success,
        });
      })
      .then(({ type }) => {
        return {
          isRegistered: type === REGISTRATION_OK,
        };
      })
      .catch((err) => {
        dispatch({
          type: REGISTRATION_ERROR,
          error_message: err.response.data.message,
          error_type: err.response.data.type,
          success: err.response.data.success,
        });
      });
  };
}

export function changeRole() {
  return (dispatch, getState) => {
    let trigger = getState().getIn(["viewer", "inAdminRole"]);

    sessionStorage.setItem("inAdminRole", !trigger);

    return dispatch({ type: CHANGING_ROLE });
  };
}

export function deleteHints() {
  window.localStorage.removeItem("showHint");
  return (dispatch) => {
    dispatch({ type: DELETED_HINTS });

    return api.offHints({ show_hints: false }).then(() =>
      dispatch({
        type: DELETED_HINTS_OK,
      })
    );
  };
}

export function changeDirect(id, data) {
  return (dispatch) => {
    dispatch({ type: CHANGINGING_DIRECTORY });

    return api.changeDirectory(id, data).then(({ data }) =>
      dispatch({
        type: CHANGINGING_DIRECTORY_OK,
        isModer: data.in_moder_mode,
      })
    );
  };
}

export function getSettings() {
  return (dispatch) => {
    dispatch({ type: GET_SETTINGS_ENTRANCE });

    return api.getSettings().then(({ data }) =>
      dispatch({
        type: GET_SETTINGS_ENTRANCE_OK,
        lenguage: data.language,
      })
    );
  };
}

export function getLoggedInUser() {
  return (dispatch) => {
    dispatch({ type: LOADING_ME });

    const checkRightsOnGame = (adminPermissions) => {
      const canEditOwn = checkPermission(adminPermissions, {
        isAdmin: true,
        mask: "own",
        codeName: "games",
      });

      const canEditAll = checkPermission(adminPermissions, {
        isAdmin: true,
        mask: "write",
        codeName: "games",
      });

      if (canEditAll) {
        localStorage.setItem("canEditGames", "all");
      } else if (canEditOwn) {
        localStorage.setItem("canEditGames", "own");
      }
    };

    return api
      .getLoggedInUser()
      .then((res) => normalizeEmployee(res.data))
      .then((entities) => {
        let isAdmin = fromJS(entities.employees).first().get("is_admin");

        const inAdminRole = localStorage.getItem("inAdminRole") === "true";
        const currentUserId = fromJS(entities.employees)
          .first()
          .get("id")
          .toString();

        localStorage.setItem("currentUserId", currentUserId);
        localStorage.setItem("isAdmin", isAdmin);

        dispatch({
          type: LOADING_ME_OK,
          ...entities,
          currentUser: entities.employees[currentUserId],
          inAdminRole,
          isAdmin,
        });
      });
  };
}

export default function viewer(state = initialState, action) {
  switch (action.type) {
    case LOADING_ME:
    case LOG_IN:
    case REGISTRATION:
      return state
        .set("isLoading", false)
        .set("isError", false)
        .set("userFound", true);

    case LOG_IN_GAME:
      return state.set("isLoading", true);

    case LOG_IN_GAME_OK:
      return state
        .set("isLoading", false)
        .set("isError", false)
        .set("showModal", false)
        .set("errorMessage", "")
        .set("isAuthenticated", true)
        .update("currentUser", (currentUser) =>
          currentUser.merge(action.currentUser)
        );

    case LOG_IN_GAME_ERROR:
      return state
        .set("isError", true)
        .set("isLoading", false)
        .set("errorMessage", action.errorMessage);

    case REGISTRATION_OK:
      return state
        .set("success", true)
        .set("isRegistered", action.isRegistered);
    case RESETING_PASSWORD_OK:
      return state
        .set("gotResponse", true)
        .set("isRegistered", true)
        .set("isLoadingModal", false)
        .set("isError", false)
        .set("userFound", true);

    case LOG_IN_ERROR:
      return state
        .set("isError", true)
        .set("errorMessage", action.errorMessage);
    case REGISTRATION_ERROR:
      return state
        .set("success", false)
        .set("error_message", action.error_message)
        .set("error_type", action.error_type);
    case RESETING_PASSWORD_ERROR:
      return state
        .set("gotResponse", true)
        .set("isLoadingModal", false)
        .set("isError", true)
        .set("userFound", true);
    case RESETING_PASSWORD_NO_SUCH_USER:
      return state
        .set("gotResponse", true)
        .set("isLoadingModal", false)
        .set("isRegistered", false)
        .set("userFound", false);
    case RESETING_PASSWORD:
      return state
        .set("isLoadingModal", true)
        .set("gotResponse", false)
        .set("userFound", false);

    case LOG_OUT:
      return state
        .set("isAuthenticated", false)
        .update("currentUser", (currentUser) => currentUser.clear())
        .set("inAdminRole", false);

    case CHANGING_ROLE:
      return state.set("inAdminRole", !state.get("inAdminRole"));

    case LOADING_ME_OK:
      return state
        .set("isLoading", false)
        .set("isError", false)
        .set("inAdminRole", action.inAdminRole)
        .set("isAdmin", action.isAdmin)
        .update("currentUser", (currentUser) =>
          currentUser.merge(action.currentUser)
        );

    case LOG_IN_OK:
      return state
        .set("isAuthenticated", true)
        .set("isLoading", false)
        .set("isError", false)
        .set("redirectUrl", action.redirectUrl)
        .set("setToken", action.setToken);

    case UPDATING_CURRENT_USER:
      return state.update("currentUser", (currentUser) =>
        currentUser.merge(action.newUser)
      );
    case GET_SETTINGS_ENTRANCE:
    case GET_SETTINGS_ENTRANCE_OK:
      return state.set("lenguage", action.lenguage);
    case DELETED_HINTS:
    default:
      return state;
  }
}
